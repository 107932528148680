import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Textarea,
  Select,
  Checkbox,
  FormControl,
  FormLabel,
  Box,
  useToast,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import toast from "react-hot-toast";

let host = "http://localhost:3001/api";
if (document.location.href.includes("localhost")) {
  host = "http://localhost:3001/api";
} else {
  host = "api";
}

const ReportAnIssueModal = ({ open, setOpen, userDetails, theme }) => {
  const [issueType, setIssueType] = useState("");
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const toast = useToast();

  const resetForm = () => {
    setIssueType("");
    setTitle("");
    setDetail("");
    setIsUrgent(false);
    setIsSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const temp = {
      issueType,
      title,
      details: detail,
      isUrgent,
      isResolved: false,
      userID: userDetails?._id,
      userEmail: userDetails?.email,
    };
    setIsSubmitting(true);

    try {
      const res = await axios.post(`${host}/issue/reportIssue`, temp);
      console.log("res", res);
    } catch (error) {
      toast.error(error.response?.data?.message || "Internal Server Error");
      setIsSubmitting(false);
    }
    setTimeout(() => {
      resetForm();
      setIsSuccess(true);
      setIsSubmitting(false);
    }, 1500);
  };

  const handleClose = () => {
    resetForm();
    setIsSuccess(false);
    setOpen(false);
  };

  const isFormInvalid = !issueType || !title || !detail;

  return (
    <Modal isOpen={open} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="30px"
        position="relative"
        bg={theme === "light" ? "#fff" : "#23213B"}
        sx={{
          padding: "30px",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            width: "55%",
            borderBottom:
              theme === "light" ? "3px solid #6231F7" : "3px solid #fff",
            left: "50%",
            transform: "translateX(-50%)",
          },
        }}
      >
        {!isSuccess && (
          <>
            <ModalHeader
              sx={{
                padding: "0px",
                marginBottom: "10px",
                fontSize: "24px",
                fontWeight: 500,
                color: theme === "light" ? "#060C16" : "#fff",
              }}
            >
              Report a Problem
            </ModalHeader>
            <Box
              sx={{ width: "100%", marginBottom: "10px" }}
              borderBottom="2px solid rgba(239, 239, 239, 1)"
            ></Box>

            <ModalBody
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              gap="15px"
              sx={{ padding: "0px" }}
            >
              <FormControl isRequired>
                <FormLabel
                  sx={{ color: theme === "light" ? "#060C16" : "#fff" }}
                >
                  Issue Type
                </FormLabel>
                <Select
                  placeholder="Select issue type"
                  value={issueType}
                  onChange={(e) => setIssueType(e.target.value)}
                  borderRadius="10px"
                  bg={theme === "light" ? "#F6F7F8" : "#000627"}
                  _placeholder={{
                    color: theme === "light" ? "#060C16" : "#fff",
                  }}
                  color={theme === "light" ? "#060C16" : "#fff"}
                  borderColor="transparent"
                  _hover={{
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                  }}
                  _focus={{
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                  }}
                  sx={{
                    option: {
                      backgroundColor:
                        theme === "light" ? "#F6F7F8" : "#000627",
                      color: theme === "light" ? "#060C16" : "#fff",
                      ":hover": {
                        backgroundColor:
                          theme === "light" ? "#E2E8F0" : "#1A202C",
                      },
                    },
                  }}
                >
                  <option
                    value="analyticalIssue"
                    style={{
                      color: theme === "light" ? "#060C16" : "#fff",
                    }}
                  >
                    Analytical Issue
                  </option>
                  <option value="executiveIssue">Executive Issue</option>
                  <option value="operationalIssue">Operational Issue</option>
                  <option value="tacticalIssue">Tactical Issue</option>
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel
                  sx={{ color: theme === "light" ? "#060C16" : "#fff" }}
                >
                  Title
                </FormLabel>
                <Input
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  bg={theme === "light" ? "#F6F7F8" : "#000627"}
                  _placeholder={{
                    color: theme === "light" ? "#060C16" : "#fff",
                  }}
                  color={theme === "light" ? "#060C16" : "#fff"}
                  borderColor="transparent"
                  borderRadius="10px"
                  _hover={{
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                  }}
                  _focus={{
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel
                  sx={{ color: theme === "light" ? "#060C16" : "#fff" }}
                >
                  Detail
                </FormLabel>
                <Textarea
                  placeholder="Enter details"
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                  // bg="#f1ffeb"
                  borderRadius="10px"
                  rows={4}
                  bg={theme === "light" ? "#F6F7F8" : "#000627"}
                  _placeholder={{
                    color: theme === "light" ? "#060C16" : "#fff",
                  }}
                  color={theme === "light" ? "#060C16" : "#fff"}
                  borderColor="transparent"
                  _hover={{
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                  }}
                  _focus={{
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                  }}
                />
              </FormControl>

              <FormControl>
                <Checkbox
                  isChecked={isUrgent}
                  onChange={(e) => setIsUrgent(e.target.checked)}
                  sx={{ color: theme === "light" ? "#060C16" : "#fff" }}
                >
                  Is this issue urgent?
                </Checkbox>
              </FormControl>

              <Button
                type="submit"
                isLoading={isSubmitting}
                isDisabled={isFormInvalid}
                borderRadius="30px"
                width="150px"
                alignSelf="center"
                sx={{
                  height: "50px",
                  background: theme === "light" ? "#F6F7F8" : "#6231F7",
                  color: theme === "light" ? "#6231F7" : "#fff",
                  _hover: {
                    color: theme === "light" ? "#5825f5" : "",
                    transform: "scale(1.05)",
                  },
                  _disabled: {
                    background: theme === "light" ? "#F6F7F8" : "#B5A6F7",
                    color: theme === "light" ? "#bab6b6" : "#D0D0D0",
                    cursor: "not-allowed",
                    transform: "none",
                  },
                }}
              >
                Submit
              </Button>
            </ModalBody>
          </>
        )}

        {isSuccess && (
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            <Box
              display="grid"
              placeItems="center"
              bg="rgba(209, 255, 183, 1)"
              p="15px"
              borderRadius="50%"
            >
              <svg
                style={{ position: "relative", left: "2px", top: "-2px" }}
                width="42"
                height="41"
                viewBox="0 0 42 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0278 13.5459L13.4272 32.1466L4.97229 23.6917"
                  stroke="#4D8733"
                  strokeWidth="4.50926"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
            <Text
              fontSize="20px"
              fontWeight="500"
              mt="10px"
              color={theme === "light" ? "#060C16" : "#fff"}
            >
              Success!
            </Text>
            <Text
              textAlign="center"
              mt="10px"
              color={theme === "light" ? "#060C16" : "#fff"}
            >
              Your report has been submitted successfully!
            </Text>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ReportAnIssueModal;
