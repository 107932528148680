import { Typography, Box } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";

const AnimateTextLeftToRight = () => {
  return (
    <Box>
      <Marquee style={{ overflowY: "hidden" }} speed={60} direction="right">
        <Typography
          className="autoAnimateText"
          sx={{
            fontSize: {
              xs: "30px",
              sm: "50px",
              md: "100px",
              lg: "200px",
            },
            fontWeight: 500,
            whiteSpace: "nowrap",
          }}
        >
          REVIEWSREVIEWS
        </Typography>
      </Marquee>
    </Box>
  );
};

export default AnimateTextLeftToRight;
