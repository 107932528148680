import React, { useState, useContext } from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import LinkTable from "./LinkTable";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import { Context } from "../../../../../Frontend/src/context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "85%", lg: "741px" },
  borderRadius: "47px",
  boxShadow: 24,
  p: "48px",
  background: "rgba(35, 33, 59, 0.60)",
  backdropFilter: "blur(11.699999809265137px)",
};

const NoteModal = ({ openNoteModal, handleCancel }) => {
  const { theme, setTheme, openDashboardModal, setOpenDashboardModal } =
    useContext(Context);

  const handleOpenDashboardModal = () => {
    setOpenDashboardModal(true);
    handleCancel();
  };
  const handleCancelDashboardModal = () => setOpenDashboardModal(false);

  return (
    <Box>
      <Modal
        open={openNoteModal}
        onClose={handleCancel}
        aria-labelledby="delete-confirmation-modal"
      >
        <Box className="muiModalBox" sx={style}>
          <Button
            variant="contained"
            startIcon={<PrivacyTipIcon />}
            sx={{
              color: "#fff",
              background: "#1A1A33",
              borderRadius: "58px",
              textTransform: "none",
              padding: "25px 35px",
              fontSize: "26px",
              fontWeight: 500,
              lineHeight: "22px",
              position: "absolute",
              top: "-60px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              "&:hover": {
                background: "#1A1A33",
              },
            }}
          >
            Note
          </Button>
          <Typography
            variant="h6"
            sx={{
              color: "#fff",
              fontSize: { xs: "16px", md: "24px" },
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Dashboard Contains Demo Content and
          </Typography>
          <Typography
            sx={{
              mb: 2,
              color: "#fff",
              fontSize: { xs: "16px", md: "24px" },
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            changes will not be saved
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: { xs: "20px", md: "70px" },
              mt: 4,
            }}
          >
            <Button
              onClick={handleCancel}
              variant="contained"
              sx={{
                color: "#fff",
                fontWeight: 500,
                borderRadius: "100px",
                background: "rgba(0, 6, 39, 0.84)",
                fontSize: { xs: "16px", md: "18px" },
                padding: { xs: "0px 25px", md: "0px 50px" },
                height: { xs: "50px", md: "62px" },
                transition:
                  "background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease",
                "&:hover": {
                  color: "#fff",
                  background: "rgba(0, 6, 39, 0.84)",
                  boxShadow: 2,
                  transform: "scale(1.05)",
                },
              }}
            >
              Close Demo
            </Button>
            <Button
              onClick={handleOpenDashboardModal}
              variant="contained"
              sx={{
                color: "#060C16",
                fontWeight: 500,
                borderRadius: "100px",
                background: "#fff",
                fontSize: { xs: "16px", md: "18px" },
                padding: { xs: "0px 25px", md: "0px 50px" },
                height: { xs: "50px", md: "62px" },
                transition:
                  "background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease",
                "&:hover": {
                  color: "#060C16",
                  background: "#fff",
                  boxShadow: 2,
                  transform: "scale(1.05)",
                },
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Dashboard modal */}
      <LinkTable
        theme={theme}
        setTheme={setTheme}
        handleCancel={handleCancelDashboardModal}
        openDashboardModal={openDashboardModal}
      />
    </Box>
  );
};

export default NoteModal;
