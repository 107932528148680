import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

const ScrollAnimation = () => {
  const scrollVariants = {
    animate: {
      x: ["0%", "-100%"],
      transition: {
        ease: "linear",
        duration: 10,
        repeat: Infinity,
      },
    },
  };

  return (
    <>
      <Box
        id="reviews"
        sx={{
          width: "100%",
          mt: "50px",
        }}
      >
        <motion.div
          variants={scrollVariants}
          animate="animate"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "150px",
            whiteSpace: "nowrap",
          }}
        >
          {/* Add as many items as you want to scroll */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography
              sx={{
                fontSize: { xs: "100px", sm: "150px", lg: "210px" },
                fontWeight: 700,
                lineHeight: "396.194px",
                color: "#383D44",
              }}
            >
              4.8
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "50px", lg: "72px" },
                lineHeight: "396.194px",
                fontWeight: 500,
                color: "#383D44",
              }}
            >
              Ratings
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography
              sx={{
                fontSize: { xs: "100px", sm: "150px", lg: "210px" },
                fontWeight: 700,
                lineHeight: "396.194px",
                color: "#383D44",
              }}
            >
              25k
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "50px", lg: "72px" },
                lineHeight: "396.194px",
                fontWeight: 500,
                color: "#383D44",
              }}
            >
              Total Users
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography
              sx={{
                fontSize: { xs: "100px", sm: "150px", lg: "210px" },
                fontWeight: 700,
                lineHeight: "396.194px",
                color: "#383D44",
              }}
            >
              4.8
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "50px", lg: "72px" },
                lineHeight: "396.194px",
                fontWeight: 500,
                color: "#383D44",
              }}
            >
              Ratings
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography
              sx={{
                fontSize: { xs: "100px", sm: "150px", lg: "210px" },
                fontWeight: 700,
                lineHeight: "396.194px",
                color: "#383D44",
              }}
            >
              25k
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "50px", lg: "72px" },
                lineHeight: "396.194px",
                fontWeight: 500,
                color: "#383D44",
              }}
            >
              Total Users
            </Typography>
          </Box>
          {/* Repeat the items to ensure smooth scrolling */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography
              sx={{
                fontSize: { xs: "100px", sm: "150px", lg: "210px" },
                fontWeight: 700,
                lineHeight: "396.194px",
                color: "#383D44",
              }}
            >
              4.8
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "50px", lg: "72px" },
                lineHeight: "396.194px",
                fontWeight: 500,
                color: "#383D44",
              }}
            >
              Ratings
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography
              sx={{
                fontSize: { xs: "100px", sm: "150px", lg: "210px" },
                fontWeight: 700,
                lineHeight: "396.194px",
                color: "#383D44",
              }}
            >
              25k
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "50px", lg: "72px" },
                lineHeight: "396.194px",
                fontWeight: 500,
                color: "#383D44",
              }}
            >
              Total Users
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography
              sx={{
                fontSize: { xs: "100px", sm: "150px", lg: "210px" },
                fontWeight: 700,
                lineHeight: "396.194px",
                color: "#383D44",
              }}
            >
              4.8
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "50px", lg: "72px" },
                lineHeight: "396.194px",
                fontWeight: 500,
                color: "#383D44",
              }}
            >
              Ratings
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography
              sx={{
                fontSize: { xs: "100px", sm: "150px", lg: "210px" },
                fontWeight: 700,
                lineHeight: "396.194px",
                color: "#383D44",
              }}
            >
              25k
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "50px", lg: "72px" },
                lineHeight: "396.194px",
                fontWeight: 500,
                color: "#383D44",
              }}
            >
              Total Users
            </Typography>
          </Box>
        </motion.div>
      </Box>
    </>
  );
};

export default ScrollAnimation;
