import React, { useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Toolbar,
  Link,
  AppBar,
  IconButton,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Context } from "../../context";
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md";

const NavigationBar = () => {
  const { theme, setTheme } = useContext(Context);
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 999,
        background: "#fff",
        width: "100%",
      }}
    >
      <AppBar
        // position="absolute"
        sx={{
          background: "transparent",
          boxShadow: "none",
          p: "5px 0px",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <NavLink to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src="/logoIcon.svg" alt="" />
              <Typography
                sx={{
                  color: theme === "light" ? "#060C16" : "#fff",
                  fontWeight: 500,
                  fontSize: { xs: "16px", sm: "24px", lg: "27px" },
                }}
              >
                ShortURL
              </Typography>
            </Box>
          </NavLink>
          <Box>
            <IconButton
              sx={{
                marginRight: { xs: 2, sm: 4, lg: 4 },
                background:
                  theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
                color: theme === "light" ? "#6231F7" : "#fff",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.11)",
                },
              }}
              onClick={toggleTheme}
            >
              {theme === "light" ? (
                <MdOutlineDarkMode size={18} />
              ) : (
                <MdOutlineLightMode size={18} />
              )}
            </IconButton>
            <Link
              component={NavLink}
              to="/"
              color="inherit"
              underline="none"
              sx={{
                marginRight: { xs: 2, sm: 4, lg: 4 },
                fontWeight: 500,
                color: theme === "light" ? "#060C16" : "#AEA9BA",
                fontSize: {
                  xs: "14px",
                  sm: "20px",
                  lg: "20px",
                },
                transition: "color 0.3s ease",
                "&:hover": { color: theme === "light" ? "#6231F7" : "#AEA9BA" },
              }}
            >
              HOME
            </Link>
            {/* <Link
              href="//www.tnsit.in/"
              target="_blank"
              color="inherit"
              underline="none"
              sx={{
                marginRight: { xs: 2, sm: 4, lg: 4 },
                fontWeight: 500,
                color: "#fff",
                fontSize: { xs: "14px", sm: "20px", lg: "20px" },
                transition: "color 0.3s ease",
                "&:hover": { color: "#AEA9BA" },
              }}
            >
              DOMAIN
            </Link> */}
            <Link
              component={NavLink}
              to="/client/login"
              color="inherit"
              underline="none"
              sx={{
                marginRight: { xs: 2, sm: 4, lg: 4 },
                fontWeight: 500,
                color:
                  location.pathname === "/client/login"
                    ? theme === "light"
                      ? "#6231F7"
                      : "#fff"
                    : theme === "light"
                    ? "#6A778B"
                    : "#AEA9BA",
                fontSize: { xs: "14px", sm: "20px", lg: "20px" },
                transition: "color 0.3s ease",
                "&:hover": { color: theme === "light" ? "#6231F7" : "#AEA9BA" },
              }}
            >
              LOGIN
            </Link>
            <Link
              component={NavLink}
              to="/client/register"
              color="inherit"
              underline="none"
              sx={{
                fontWeight: 500,
                color:
                  location.pathname === "/client/register"
                    ? theme === "light"
                      ? "#6231F7"
                      : "#fff"
                    : theme === "light"
                    ? "#6A778B"
                    : "#AEA9BA",

                // textDecoration:
                //   location.pathname === "/client/register"
                //     ? "underline"
                //     : "none",
                fontSize: { xs: "14px", sm: "20px", lg: "20px" },
                transition: "color 0.3s ease",
                "&:hover": { color: "#AEA9BA" },
              }}
            >
              REGISTER
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavigationBar;
