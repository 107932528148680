// import { Box, Button, Input, Text, useDisclosure } from '@chakra-ui/react'
// import { useContext, useEffect, useRef } from 'react';
// import { NavLink } from 'react-router-dom'
// import { Context } from '../../context';
// import Cookies from 'js-cookie';
// import s from "./nav.module.css"
// import { useLocation } from 'react-router-dom'
// import {
//     Drawer,
//     DrawerBody,
//     DrawerFooter,
//     DrawerHeader,
//     DrawerOverlay,
//     DrawerContent,
//     DrawerCloseButton,
// } from '@chakra-ui/react'
// import { HamburgerIcon } from '@chakra-ui/icons';

// const NavBar = () => {
//     let validPaths = ["/"]
//     const location = useLocation();
//     console.log();
//     const { isOpen, onOpen, onClose } = useDisclosure()
//     const btnRef = useRef()
//     const { setUserDetails, userDetails, isAdminLogin, setisAdminLogin,setShortLimit,
//         setData,
//         setFilteredData,
//         setSelected,
//         setSalesPersonOptions,
//         setCourseOptions,
//         setCustomerNameOptions,
//         setCustomerEmailOptions,
//         setEditing,
//         setNewDataAdded,
//         setDomainValue,
//         setclickDetails,
//         setMapData,
//         setAllUsersData,
//         setAllUsersFilteredData,
//         setGoogleSheetDeployLink,
//         setDataLimit,
//         setPage,
//         adminUserDetails,
//         setAdminUserDetails

//     } = useContext(Context);
//     let contact;
//     useEffect(() => {
//         contact = document.getElementById("contact");
//     })
//     return (
//         <Box w={"100%"} minH={"100px"} backgroundColor={"rgba(22,34,57,0.95)"} p={"0 30px"} display={"flex"} alignItems={"center"}>
//             <Box w={"50%"} h={"100%"} display={"flex"}>
//                 <NavLink to={"/"} style={{ display: "flex" }}>
//                     <Text color={"#bad900"} fontSize={"50px"} fontWeight={"bold"} letterSpacing={"3px"}>CBX</Text>
//                     <Text color={"white"} fontSize={"50px"} fontWeight={"bold"} letterSpacing={"3px"}>IT</Text>
//                 </NavLink>
//             </Box>
//             <Button pos={"absolute"} right={"20px"} display={["flex", "flex", "flex", "none"]} ref={btnRef} bg={"transparent"} onClick={onOpen}>
//                 <HamburgerIcon color={"white"} w={"30px"} h={"30px"} />
//             </Button>
//             <Drawer
//                 isOpen={isOpen}
//                 placement='left'
//                 onClose={onClose}
//                 finalFocusRef={btnRef}
//             >
//                 <DrawerOverlay />
//                 <DrawerContent>
//                     <DrawerCloseButton />
//                     <DrawerBody bg={"rgba(22,34,57,0.95)"} p={"20px"}>
//                         <NavLink onClick={() => onClose()} className={s.navHoverStyle} to={"/"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: userDetails._id || isAdminLogin ? "none" : "flex" }}>HOME</NavLink>
//                         <NavLink onClick={() => onClose()} className={s.navHoverStyle} to={"//www.tnsit.in/"} target='_blank' style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: userDetails._id || isAdminLogin ? "none" : "flex" }}>DOMAIN</NavLink>
//                         <NavLink onClick={() => onClose()} className={s.navHoverStyle} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: !(validPaths.includes(location.pathname)) ? "none" : userDetails._id || isAdminLogin ? "none" : "flex" }}>CONTACT</NavLink>
//                         <NavLink onClick={() => onClose()} className={s.navHoverStyle} to={"/client/login"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: userDetails._id || isAdminLogin ? "none" : "flex" }}>LOGIN</NavLink>
//                         <NavLink onClick={() => onClose()} className={s.navHoverStyle} to={"/client/register"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: userDetails._id || isAdminLogin ? "none" : "flex" }}>REGISTER</NavLink>
//                     </DrawerBody>
//                 </DrawerContent>
//             </Drawer>
//             <Box w={"50%"} h={"60px"} display={["none", "none", "none", "flex"]} justifyContent={"flex-end"}>
//                 <Box height={"100%"} display={"flex"} gap={"20px"} alignItems={"center"} >
//                     <NavLink className={s.navHoverStyle} to={"/"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: (userDetails._id || adminUserDetails._id) || isAdminLogin ? "none" : "flex" }}>HOME</NavLink>
//                     <NavLink className={s.navHoverStyle} to={"//www.tnsit.in/"} target='_blank' style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: (userDetails._id || adminUserDetails._id) || isAdminLogin ? "none" : "flex" }}>DOMAIN</NavLink>
//                     <NavLink className={s.navHoverStyle} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: !(validPaths.includes(location.pathname)) ? "none" : (userDetails._id || adminUserDetails._id) || isAdminLogin ? "none" : "flex" }} onClick={() => {
//                         contact.scrollIntoView({
//                             behavior: "smooth"
//                         })
//                     }}>CONTACT</NavLink>
//                     <NavLink className={s.navHoverStyle} to={"/client/login"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: (userDetails._id || adminUserDetails._id) || isAdminLogin ? "none" : "flex" }}>LOGIN</NavLink>
//                     <NavLink className={s.navHoverStyle} to={"/client/register"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: (userDetails._id || adminUserDetails._id) || isAdminLogin ? "none" : "flex" }}>REGISTER</NavLink>
//                     {isAdminLogin && <NavLink className={s.navHoverStyle} to={"client/reports"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: (userDetails._id || adminUserDetails._id) ? "flex" : "none" }}>REPORTS</NavLink>}
//                     <NavLink className={s.navHoverStyle} to={"/client/links"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: (userDetails._id) ? "flex" : "none" }}>LINKS</NavLink>
//                     {isAdminLogin && <NavLink className={s.navHoverStyle} to={"client/adminPage"} style={{ color: "white", fontWeight: "bold", fontSize: "20px", display: (userDetails._id || adminUserDetails._id) ? "flex" : "none" }}>USERS</NavLink>}

//                     <Button display={!userDetails._id ? "none" : "flex"} onClick={() => {
//                         setUserDetails({})
//                         setData([])
//                         setFilteredData([])
//                         setSelected([])
//                         setSalesPersonOptions([])
//                         setCourseOptions([])
//                         setCustomerNameOptions([])
//                         setCustomerEmailOptions([])
//                         setEditing(false)
//                         setNewDataAdded(false)
//                         setDomainValue("")
//                         setclickDetails([])
//                         setMapData([])
//                         setShortLimit(0)
//                         setisAdminLogin(false)
//                         setAllUsersData([])
//                         setAllUsersFilteredData([])
//                         setGoogleSheetDeployLink("")
//                         setDataLimit(10)
//                         setPage(1)
//                         Cookies.set('user', JSON.stringify({}));
//                     }}>
//                         Logout
//                     </Button>
//                     <Button display={!adminUserDetails._id ? "none" : "flex"} onClick={() => {
//                         setAdminUserDetails(false)
//                         Cookies.set('admin', JSON.stringify({}));
//                     }}>
//                         Logout
//                     </Button>

//                 </Box>
//             </Box>
//         </Box>
//     )
// }

// export default NavBar

// Updated navbar ------------------ -----------------

import { Box, Button, Text, useDisclosure, IconButton } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Context } from "../../context";
import Cookies from "js-cookie";
import s from "./nav.module.css";
import { useLocation, Link } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  Tooltip,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

import { MdLogout } from "react-icons/md";
import { FaLinkSlash } from "react-icons/fa6";
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md";
import { RiMenu3Fill } from "react-icons/ri";
import { LuUsers } from "react-icons/lu";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineOndemandVideo, MdOutlineReport } from "react-icons/md";
import ReportAnIssueModal from "./ReportAnIssueModal";

const NavBar = () => {
  let validPaths = ["/"];
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showTutorial, setShowTutorial] = useState(false);
  const [openReportAnIssue, setOpenReportAnIssue] = useState(false);
  const btnRef = useRef();
  const {
    setUserDetails,
    userDetails,
    isAdminLogin,
    setisAdminLogin,
    setShortLimit,
    setData,
    setFilteredData,
    setSelected,
    setSalesPersonOptions,
    setCourseOptions,
    setCustomerNameOptions,
    setCustomerEmailOptions,
    setEditing,
    setNewDataAdded,
    setDomainValue,
    setclickDetails,
    setMapData,
    setAllUsersData,
    setAllUsersFilteredData,
    setGoogleSheetDeployLink,
    setDataLimit,
    setPage,
    adminUserDetails,
    setAdminUserDetails,
    setTheme,
    theme,
  } = useContext(Context);

  useEffect(() => {
    // document.body.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  let contact;
  useEffect(() => {
    contact = document.getElementById("contact");
  });

  const handleRedirectToHome = () => {
    navigate("/");
  };
  const modalStyle = {
    width: "90%",
    height: "90%",
    boxShadow: "lg",
    padding: "1rem",
    background: "white",
    borderRadius: "18px",
    outline: "none",
  };
  return (
    <Box
      style={{
        width: "100%",
        position: "sticky",
        top: 0,
        zIndex: 999,
        background:
          theme === "light"
            ? `url("/lightBg.svg") no-repeat center center`
            : `url("/loginBg.svg") no-repeat center center`,
        backgroundSize: "cover",
      }}
    >
      <Box
        style={{
          padding: "16px 0px",
          display: "flex",
          alignItems: "center",
          width: "95%",
          margin: "auto",
        }}
      >
        <Box
          w={"50%"}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
        >
          <img
            onClick={handleRedirectToHome}
            src="/logoIcon.svg"
            alt=""
            style={{ width: "26px", height: "26px" }}
          />
          <Text
            onClick={handleRedirectToHome}
            color={theme === "light" ? "#060C16" : "#fff"}
            fontSize={"20px"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
          >
            ShortURL
          </Text>
        </Box>

        <Box
          pos={"absolute"}
          right={"20px"}
          display={["flex", "flex", "flex", "none"]}
          sx={{ alignItems: "center", gap: "20px" }}
        >
          <IconButton
            isRound={true}
            style={{
              background:
                theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
              color: theme === "light" ? "#6231F7" : "#fff",
            }}
            aria-label="Search database"
            icon={
              theme === "light" ? (
                <MdOutlineDarkMode size={19} />
              ) : (
                <MdOutlineLightMode size={19} />
              )
            }
            onClick={toggleTheme}
          />
          <Tooltip label="Report an issue">
            <IconButton
              onClick={() => setOpenReportAnIssue(true)}
              isRound={true}
              style={{
                background:
                  theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
                color: theme === "light" ? "#6231F7" : "#fff",
              }}
              icon={<MdOutlineReport size={19} />}
            />
          </Tooltip>

          <Tooltip hasArrow label="Instruction video">
            <IconButton
              onClick={() => {
                setShowTutorial(true);
              }}
              isRound={true}
              style={{
                background:
                  theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
                color: theme === "light" ? "#6231F7" : "#fff",
              }}
              aria-label="Video"
              icon={<MdOutlineOndemandVideo size={19} />}
            />
          </Tooltip>

          <Flex alignItems="center">
            <Menu>
              <MenuButton
                as={IconButton}
                onClick={onOpen}
                isRound={true}
                // pos={"absolute"}
                // right={"20px"}
                // display={["flex", "flex", "flex", "none"]}
                ref={btnRef}
                style={{
                  background:
                    theme === "light"
                      ? "rgba(167, 172, 190, 0.22)"
                      : "rgba(255, 255, 255, 0.20)",
                  color: theme === "light" ? "#060C16" : "#fff",
                }}
                aria-label="Menu"
                icon={<RiMenu3Fill size={19} />}
              />
              <MenuList>
                {isAdminLogin && (
                  <Link
                    to="/client/reports"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <MenuItem
                      display={
                        userDetails._id || adminUserDetails._id
                          ? "flex"
                          : "none"
                      }
                    >
                      <MdOutlineDashboard style={{ marginRight: "10px" }} />
                      Reports
                    </MenuItem>
                  </Link>
                )}

                <Link
                  to="/client/links"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <MenuItem display={!userDetails._id ? "none" : "flex"}>
                    <FaLinkSlash style={{ marginRight: "10px" }} />
                    Links
                  </MenuItem>
                </Link>
                {isAdminLogin && (
                  <Link
                    to="/client/adminPage"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <MenuItem
                      display={
                        userDetails._id || adminUserDetails._id
                          ? "flex"
                          : "none"
                      }
                    >
                      <LuUsers style={{ marginRight: "10px" }} />
                      Users
                    </MenuItem>
                  </Link>
                )}
                <MenuItem
                  display={!userDetails._id ? "none" : "flex"}
                  onClick={() => {
                    setUserDetails({});
                    setData([]);
                    setFilteredData([]);
                    setSelected([]);
                    setSalesPersonOptions([]);
                    setCourseOptions([]);
                    setCustomerNameOptions([]);
                    setCustomerEmailOptions([]);
                    setEditing(false);
                    setNewDataAdded(false);
                    setDomainValue("");
                    setclickDetails([]);
                    setMapData([]);
                    setShortLimit(0);
                    setisAdminLogin(false);
                    setAllUsersData([]);
                    setAllUsersFilteredData([]);
                    setGoogleSheetDeployLink("");
                    setDataLimit(10);
                    setPage(1);
                    Cookies.set("user", JSON.stringify({}));
                  }}
                >
                  <MdLogout style={{ marginRight: "10px" }} />
                  Logout
                </MenuItem>
                <MenuItem
                  display={!adminUserDetails._id ? "none" : ""}
                  onClick={() => {
                    setAdminUserDetails(false);
                    Cookies.set("admin", JSON.stringify({}));
                  }}
                >
                  <MdLogout style={{ marginRight: "10px" }} />
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>

        {/* Small screen drawer */}
        {/* <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody bg={"rgba(22,34,57,0.95)"} p={"20px"}>
              <NavLink
                onClick={() => onClose()}
                className={s.navHoverStyle}
                to={"/"}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  display: userDetails._id || isAdminLogin ? "none" : "flex",
                }}
              >
                HOME
              </NavLink>
              <NavLink
                onClick={() => onClose()}
                className={s.navHoverStyle}
                to={"//www.tnsit.in/"}
                target="_blank"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  display: userDetails._id || isAdminLogin ? "none" : "flex",
                }}
              >
                DOMAIN
              </NavLink>
              <NavLink
                onClick={() => onClose()}
                className={s.navHoverStyle}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  display: !validPaths.includes(location.pathname)
                    ? "none"
                    : userDetails._id || isAdminLogin
                    ? "none"
                    : "flex",
                }}
              >
                CONTACT
              </NavLink>
              <NavLink
                onClick={() => onClose()}
                className={s.navHoverStyle}
                to={"/client/login"}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  display: userDetails._id || isAdminLogin ? "none" : "flex",
                }}
              >
                LOGIN
              </NavLink>
              <NavLink
                onClick={() => onClose()}
                className={s.navHoverStyle}
                to={"/client/register"}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  display: userDetails._id || isAdminLogin ? "none" : "flex",
                }}
              >
                REGISTER
              </NavLink>
            </DrawerBody>
          </DrawerContent>
        </Drawer> */}

        <Box
          w={"50%"}
          display={["none", "none", "none", "flex"]}
          justifyContent={"flex-end"}
        >
          <Box
            display={"flex"}
            gap={!isAdminLogin ? "20px" : "15px"}
            alignItems={"center"}
          >
            <IconButton
              // size={"sm"}
              isRound={true}
              style={{
                // marginRight: "10px",
                background:
                  theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
                color: theme === "light" ? "#6231F7" : "#fff",
              }}
              aria-label="Search database"
              icon={
                theme === "light" ? (
                  <MdOutlineDarkMode size={19} />
                ) : (
                  <MdOutlineLightMode size={19} />
                )
              }
              onClick={toggleTheme}
            />
            <Tooltip label="Report an issue">
              <IconButton
                onClick={() => setOpenReportAnIssue(true)}
                isRound={true}
                style={{
                  background:
                    theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
                  color: theme === "light" ? "#6231F7" : "#fff",
                }}
                icon={<MdOutlineReport size={19} />}
              />
            </Tooltip>
            <Tooltip hasArrow label="Instruction video">
              <IconButton
                onClick={() => {
                  setShowTutorial(true);
                }}
                isRound={true}
                style={{
                  background:
                    theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
                  color: theme === "light" ? "#6231F7" : "#fff",
                }}
                aria-label="Video"
                icon={<MdOutlineOndemandVideo size={19} />}
              />
            </Tooltip>
            <NavLink
              className={s.navHoverStyle}
              to={"/"}
              style={{
                color: theme === "light" ? "#060C16" : "#fff",
                fontWeight: 500,
                fontSize: "16px",
                display:
                  userDetails._id || adminUserDetails._id || isAdminLogin
                    ? "none"
                    : "flex",
              }}
            >
              Home
            </NavLink>
            <NavLink
              className={s.navHoverStyle}
              to={"//www.tnsit.in/"}
              target="_blank"
              style={{
                color: theme === "light" ? "#060C16" : "#fff",
                fontWeight: 500,
                fontSize: "16px",
                display:
                  userDetails._id || adminUserDetails._id || isAdminLogin
                    ? "none"
                    : "flex",
              }}
            >
              Domain
            </NavLink>
            <NavLink
              className={s.navHoverStyle}
              style={{
                color: theme === "light" ? "#060C16" : "#fff",
                fontWeight: 500,
                fontSize: "16px",
                display: !validPaths.includes(location.pathname)
                  ? "none"
                  : userDetails._id || adminUserDetails._id || isAdminLogin
                  ? "none"
                  : "flex",
              }}
              onClick={() => {
                contact.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              Contact
            </NavLink>
            <NavLink
              className={s.navHoverStyle}
              to={"/client/login"}
              style={{
                color: theme === "light" ? "#060C16" : "#fff",
                fontWeight: 500,
                fontSize: "16px",
                display:
                  userDetails._id || adminUserDetails._id || isAdminLogin
                    ? "none"
                    : "flex",
              }}
            >
              Login
            </NavLink>
            <NavLink
              className={s.navHoverStyle}
              to={"/client/register"}
              style={{
                color: theme === "light" ? "#060C16" : "#fff",
                fontWeight: 500,
                fontSize: "16px",
                display:
                  userDetails._id || adminUserDetails._id || isAdminLogin
                    ? "none"
                    : "flex",
              }}
            >
              Register
            </NavLink>
            {isAdminLogin && (
              <Link
                to="/client/reports"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button
                  variant="solid"
                  sx={{
                    borderRadius: "534px",
                    minWidth: "100px",
                    background:
                      location.pathname === "/client/reports"
                        ? theme === "light"
                          ? "#F0EDF9"
                          : "#2A1F3C"
                        : theme === "light"
                        ? "none"
                        : "none",
                    color:
                      location.pathname === "/client/reports"
                        ? theme === "light"
                          ? "#6231F7"
                          : "#fff"
                        : theme === "light"
                        ? "#6A778B"
                        : "#AEA9BA",
                    fontWeight:
                      location.pathname === "/client/reports"
                        ? theme === "light"
                          ? 600
                          : 600
                        : theme === "light"
                        ? 500
                        : 500,
                    fontSize: "16px",
                    display:
                      userDetails._id || adminUserDetails._id ? "flex" : "none",
                    _hover: {
                      background:
                        location.pathname === "/client/reports"
                          ? theme === "light"
                            ? "#F0EDF9"
                            : "#2A1F3C"
                          : theme === "light"
                          ? "none"
                          : "none",
                      color:
                        location.pathname === "/client/reports"
                          ? theme === "light"
                            ? "#6231F7"
                            : "#fff"
                          : theme === "light"
                          ? "#6231F7"
                          : "#AEA9BA",
                      border:
                        location.pathname === "/client/reports"
                          ? theme === "light"
                            ? "1px solid transparent"
                            : "1px solid transparent"
                          : theme === "light"
                          ? "1px solid #D6D1E3"
                          : "1px solid #D6D1E3",
                    },
                  }}
                >
                  Reports
                </Button>
              </Link>
              // <NavLink
              //   className={s.navHoverStyle}
              //   to={"client/reports"}
              //   style={{
              //     color:
              //       location.pathname === "/client/reports"
              //         ? theme === "light"
              //           ? "#060C16"
              //           : "#fff"
              //         : theme === "light"
              //         ? "#6A778B"
              //         : "#AEA9BA",
              //     fontWeight:
              //       location.pathname === "/client/reports"
              //         ? theme === "light"
              //           ? 600
              //           : 600
              //         : theme === "light"
              //         ? 500
              //         : 500,
              //     fontSize: "16px",
              //     display:
              //       userDetails._id || adminUserDetails._id ? "flex" : "none",
              //   }}
              // >
              //   Reports
              // </NavLink>
            )}

            {isAdminLogin && (
              <Link
                to="/client/adminPage"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button
                  variant="solid"
                  sx={{
                    borderRadius: "534px",
                    minWidth: "80px",
                    background:
                      location.pathname === "/client/adminPage"
                        ? theme === "light"
                          ? "#F0EDF9"
                          : "#2A1F3C"
                        : theme === "light"
                        ? "none"
                        : "none",
                    color:
                      location.pathname === "/client/adminPage"
                        ? theme === "light"
                          ? "#6231F7"
                          : "#fff"
                        : theme === "light"
                        ? "#6A778B"
                        : "#AEA9BA",
                    fontWeight:
                      location.pathname === "/client/adminPage"
                        ? theme === "light"
                          ? 600
                          : 600
                        : theme === "light"
                        ? 500
                        : 500,
                    fontSize: "16px",
                    display:
                      userDetails._id || adminUserDetails._id ? "flex" : "none",
                    _hover: {
                      background:
                        location.pathname === "/client/adminPage"
                          ? theme === "light"
                            ? "#F0EDF9"
                            : "#2A1F3C"
                          : theme === "light"
                          ? "none"
                          : "none",
                      color:
                        location.pathname === "/client/adminPage"
                          ? theme === "light"
                            ? "#6231F7"
                            : "#fff"
                          : theme === "light"
                          ? "#6231F7"
                          : "#AEA9BA",
                      border:
                        location.pathname === "/client/adminPage"
                          ? theme === "light"
                            ? "none"
                            : "none"
                          : theme === "light"
                          ? "1px solid #D6D1E3"
                          : "1px solid #D6D1E3",
                    },
                  }}
                >
                  Users
                </Button>
              </Link>
              // <NavLink
              //   className={s.navHoverStyle}
              //   to={"client/adminPage"}
              //   style={{
              //     color:
              //       location.pathname === "/client/adminPage"
              //         ? theme === "light"
              //           ? "#060C16"
              //           : "#fff"
              //         : theme === "light"
              //         ? "#6A778B"
              //         : "#AEA9BA",
              //     fontWeight:
              //       location.pathname === "/client/adminPage"
              //         ? theme === "light"
              //           ? 600
              //           : 600
              //         : theme === "light"
              //         ? 500
              //         : 500,
              //     fontSize: "16px",
              //     display:
              //       userDetails._id || adminUserDetails._id ? "flex" : "none",
              //   }}
              // >
              //   Users
              // </NavLink>
            )}

            {/* <NavLink to={"/client/links"}>
              <IconButton
                className={s.navHoverStyle}
                isRound={true}
                style={{
                  background:
                    location.pathname === "/client/links"
                      ? theme === "light"
                        ? "rgba(167, 172, 190, 0.22)"
                        : "rgba(255, 255, 255, 0.20)"
                      : theme === "light"
                      ? "rgba(255, 255, 255, 0.08"
                      : "rgba(255, 255, 255, 0.08",
                  color:
                    location.pathname === "/client/links"
                      ? theme === "light"
                        ? "#060C16"
                        : "#fff"
                      : theme === "light"
                      ? "#6A778B"
                      : "#AEA9BA",
                  display: !userDetails._id ? "none" : "flex",
                }}
                aria-label="Search database"
                icon={<FaLinkSlash size={19} />}
              />
            </NavLink> */}
            {/* <NavLink
              to={"client/links"} */}

            <Link
              to="/client/links"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                variant="solid"
                sx={{
                  borderRadius: "534px",
                  minWidth: "80px",
                  background:
                    location.pathname === "/client/links"
                      ? theme === "light"
                        ? "#F0EDF9"
                        : "#2A1F3C"
                      : theme === "light"
                      ? "none"
                      : "none",
                  color:
                    location.pathname === "/client/links"
                      ? theme === "light"
                        ? "#6231F7"
                        : "#fff"
                      : theme === "light"
                      ? "#6A778B"
                      : "#AEA9BA",
                  fontWeight:
                    location.pathname === "/client/links"
                      ? theme === "light"
                        ? 600
                        : 600
                      : theme === "light"
                      ? 500
                      : 500,
                  fontSize: "16px",
                  display: !userDetails._id ? "none" : "flex",
                  _hover: {
                    background:
                      location.pathname === "/client/links"
                        ? theme === "light"
                          ? "#F0EDF9"
                          : "#2A1F3C"
                        : theme === "light"
                        ? "none"
                        : "none",
                    color:
                      location.pathname === "/client/links"
                        ? theme === "light"
                          ? "#6231F7"
                          : "#fff"
                        : theme === "light"
                        ? "#6231F7"
                        : "#AEA9BA",
                    border:
                      location.pathname === "/client/links"
                        ? theme === "light"
                          ? "none"
                          : "none"
                        : theme === "light"
                        ? "1px solid #D6D1E3"
                        : "1px solid #D6D1E3",
                  },
                }}
              >
                Links
              </Button>
            </Link>
            {/* </NavLink> */}

            <IconButton
              isRound={true}
              sx={{
                background: "rgba(255, 255, 255, 0.08)",
                color: theme === "light" ? "#060C16" : "#fff",
                display: !userDetails._id ? "none" : "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              _hover={{
                border:
                  theme === "light" ? "1px solid #8B71D8" : "1px solid #fff",
              }}
              onClick={() => {
                setUserDetails({});
                setData([]);
                setFilteredData([]);
                setSelected([]);
                setSalesPersonOptions([]);
                setCourseOptions([]);
                setCustomerNameOptions([]);
                setCustomerEmailOptions([]);
                setEditing(false);
                setNewDataAdded(false);
                setDomainValue("");
                setclickDetails([]);
                setMapData([]);
                setShortLimit(0);
                setisAdminLogin(false);
                setAllUsersData([]);
                setAllUsersFilteredData([]);
                setGoogleSheetDeployLink("");
                setDataLimit(10);
                setPage(1);
                Cookies.set("user", JSON.stringify({}));
                navigate("/client/login");
              }}
              aria-label="Logout"
              icon={<MdLogout size={19} />}
            />

            <IconButton
              // size={"sm"}
              isRound={true}
              sx={{
                // borderRadius: "9px",
                background: "rgba(255, 255, 255, 0.08)",
                color: theme === "light" ? "#060C16" : "#fff",
                display: !adminUserDetails._id ? "none" : "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              _hover={{
                border:
                  theme === "light" ? "1px solid #8B71D8" : "1px solid #fff",
              }}
              onClick={() => {
                setAdminUserDetails(false);
                Cookies.set("admin", JSON.stringify({}));
                navigate("/client/login");
              }}
              aria-label="Logout"
              icon={<MdLogout size={19} />}
            />
          </Box>
        </Box>
      </Box>
      {/* Instruction video */}
      <Modal
        isOpen={showTutorial}
        onClose={() => {
          setShowTutorial(false);
        }}
        // size="full"
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent sx={modalStyle}>
          <ModalBody p={0}>
            <Box
              as="iframe"
              src="https://drive.google.com/file/d/1bEhgOEo5Rnw5cxBpDq5gDAOtx2JSYH6O/preview"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              sx={{
                width: "100%",
                height: "100%",
                maxHeight: "90vh",
                maxWidth: "90vw",
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Rrport an issue */}
      <ReportAnIssueModal
        theme={theme}
        userDetails={userDetails}
        open={openReportAnIssue}
        setOpen={setOpenReportAnIssue}
      />
    </Box>
  );
};

export default NavBar;
