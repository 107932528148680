import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { Context } from "../../context";
import toast from "react-hot-toast";
import { MdDeleteOutline } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import { BsSortNumericDown } from "react-icons/bs";
import { IoFilter } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";

const columns = [
  {
    field: "shortURL",
    headerName: "Short URL",
    flex: 1,
    headerClassName: "muiGridHeader",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    headerClassName: "muiGridHeader",
  },
  {
    field: "clicks",
    headerName: "Clicks",
    flex: 1,
    headerClassName: "muiGridHeader",
  },
  {
    field: "dateCreated",
    headerName: "Date Created",
    flex: 1,
    headerClassName: "muiGridHeader",
  },
  {
    field: "lastUsed",
    headerName: "Last Used",
    flex: 1,
    headerClassName: "muiGridHeader",
  },
];

const CustomToolbar = () => {
  return (
    <GridToolbarContainer sx={{ bgcolor: "rgb(0, 100, 0)" }}>
      <GridToolbarDensitySelector
        sx={{
          bgcolor: "white",
          color: "green",
          "&:hover": { bgcolor: "white", color: "green" },
        }}
      />
      <GridToolbarColumnsButton
        sx={{
          bgcolor: "white",
          color: "green",
          "&:hover": { bgcolor: "white", color: "green" },
        }}
      />
      <GridToolbarFilterButton
        sx={{
          bgcolor: "white",
          color: "green",
          "&:hover": { bgcolor: "white", color: "green" },
        }}
      />
    </GridToolbarContainer>
  );
};

const modalContentStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "492px",
  backgroundColor: "white",
  borderRadius: "37px",
  boxShadow: 24,
  padding: "30px 25px",
  textAlign: "center",
};

function Reports() {
  const { allLinkRows, setAllLinkRows, axios, apiLink, theme } =
    useContext(Context);
  const [selectionModel, setSelectionModel] = useState([]);
  const [sort, setSort] = useState("DES-CLICKS");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [filterDate, setfilterDate] = useState(0);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1); // Adjust for 0-indexed pages
    setPageSize(params.pageSize);
  };

  const dataRows = useMemo(() => {
    return rows.map((item) => {
      return {
        _id: item._id,
        shortURL: item.shortURL,
        email: item.email,
        dateCreated: new Date(item.dateCreated).toDateString(),
        clicks: item.clicks,
        lastUsed: item.lastUsed
          ? new Date(item.lastUsed).toDateString()
          : "Not Found",
      };
    });
  }, [rows]);

  useEffect(() => {
    const offset = (currentPage - 1) * pageSize;
    axios
      .get(
        `${apiLink}shorten/AllData/pagination?offset=${offset}&limit=${pageSize}&filter=${filterDate}&sort=${sort}&search=${search}`
      )
      .then((res) => {
        console.log(res);
        setRows(res.data.data);
        setAllLinkRows(res.data.data);
        setTotalRows(res.data.totalRows);
      });
  }, [currentPage, pageSize, filterDate, sort, search]);

  const handleDelete = () => {
    let tempSelected = [...selectionModel];
    let temp = rows
      .filter((item) => tempSelected.includes(item._id))
      .map((item) => ({
        _id: item._id,
        userID: item.userID,
        shortURL: item.shortURL,
      }));

    axios.post(`${apiLink}shorten/AllData/bulkDelete`, {
      ids: temp,
    });

    setDeleteModalOpen(false);
    setRows(rows.filter((item) => !selectionModel.includes(item._id)));
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        // height: "100vh",
        background:
          theme === "light"
            ? `url("/lightBg.svg") no-repeat center center`
            : `url("/loginBg.svg") no-repeat center center`,
        backgroundSize: "cover",
      }}
    >
      <Box
        width={"95%"}
        margin={"auto"}
        display={"flex"}
        flexDirection={"column"}
        gap={"25px"}
        paddingTop={"10px"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
          gap={"10px"}
          justifyContent={"space-between"}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <TextField
              fullWidth
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IoIosSearch
                      color={theme === "light" ? "#060C16" : "#fff"}
                    />
                  </InputAdornment>
                ),
              }}
              size={"small"}
              placeholder="Search"
              sx={{
                // boxShadow: 1,
                backgroundColor: theme === "light" ? "#fff" : "#23213B",
                borderRadius: "14px",
                input: { color: theme === "light" ? "#060C16" : "#fff" },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                  borderRadius: "14px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "14px",
                    border: "1px solid transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                    borderRadius: "14px",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                    borderRadius: "14px",
                  },
                },
              }}
            />
            <Box>
              <Tooltip title="Reset" arrow>
                <IconButton
                  onClick={() => setSearch("")}
                  sx={{
                    background: theme === "light" ? "#fff" : "gray.700",
                    color: theme === "light" ? "#6231f7" : "white",
                    border:
                      theme === "light"
                        ? "1px solid #fff"
                        : "1px solid #6231F7",
                    "&:hover": {
                      background: theme === "light" ? "#6231f7" : "white",
                      color: theme === "light" ? "#fff" : "#6231F7",
                      border:
                        theme === "light"
                          ? "1px solid #6231f7"
                          : "1px solid #fff",
                    },
                  }}
                >
                  <GrPowerReset size={18} />{" "}
                </IconButton>
              </Tooltip>
            </Box>
            <Button
              disabled={selectionModel.length === 0}
              startIcon={<MdDeleteOutline />}
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "58px",
                bgcolor: theme === "light" ? "#6231F7" : "#fff",
                p: "8px 30px",
                color: theme === "light" ? "#fff" : "#060C16",
                "&:hover": {
                  bgcolor: theme === "light" ? "#4d18ed" : "#6231F7",
                  color: theme === "light" ? "#fff" : "#fff",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#E7EAF0",
                  color: "#A5A9C1",
                },
              }}
              onClick={() => {
                if (selectionModel.length == 0)
                  return toast.error("Please Select Data to Delete !");
                setDeleteModalOpen(true);
              }}
            >
              Delete
            </Button>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <BsSortNumericDown
                color={theme === "light" ? "#060C16" : "#fff"}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#060C16",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                }}
              >
                Sort
              </Typography>
            </Box>

            <Select
              size="small"
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              sx={{
                borderColor: "#6231F7",
                color: theme === "light" ? "#060C16" : "#fff",
                borderRadius: "14px",
                fontWeight: 550,
                fontSize: "14px",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme === "light" ? "#6231F7" : "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme === "light" ? "#6231F7" : "#fff",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6231F7",
                },
                "& .MuiSelect-icon": {
                  color: theme === "light" ? "#060C16" : "#fff",
                },
              }}
            >
              <MenuItem value={"DES-CLICKS"}>Clicks (Descending)</MenuItem>
              <MenuItem value={"ASC-CLICKS"}>Clicks (Ascending)</MenuItem>
              <MenuItem value={"DES-LAST"}>Last Used (Newer)</MenuItem>
              <MenuItem value={"ASC-LAST"}>Last Used (Older)</MenuItem>
              <MenuItem value={"DES-CREATED"}>Created (Newer)</MenuItem>
              <MenuItem value={"ASC-CREATED"}>Created (Older)</MenuItem>
            </Select>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                ml: "20px",
              }}
            >
              <IoFilter color={theme === "light" ? "#060C16" : "#fff"} />
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#060C16",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                }}
              >
                Last Used
              </Typography>
            </Box>

            <Select
              size="small"
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterDate}
              onChange={(e) => setfilterDate(e.target.value)}
              sx={{
                borderColor: "#6231F7",
                color: theme === "light" ? "#060C16" : "#fff",
                borderRadius: "14px",
                fontWeight: 550,
                fontSize: "14px",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme === "light" ? "#6231F7" : "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme === "light" ? "#6231F7" : "#fff",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6231F7",
                },
                "& .MuiSelect-icon": {
                  color: theme === "light" ? "#060C16" : "#fff",
                },
              }}
            >
              <MenuItem value={0}>Today</MenuItem>
              <MenuItem value={1}>Before 1 Day</MenuItem>
              <MenuItem value={7}>Before 1 Week</MenuItem>
              <MenuItem value={14}>Before 2 Weeks</MenuItem>
              <MenuItem value={30}>Before 1 Month</MenuItem>
              <MenuItem value={90}>Before 3 Months</MenuItem>
              <MenuItem value={30 * 4}>Before 4 Months</MenuItem>
              <MenuItem value={30 * 6}>Before 6 Months</MenuItem>
              <MenuItem value={"NOT"}>Not Used</MenuItem>
            </Select>
          </Box>
        </Box>

        <DataGrid
          sx={{
            width: "100%",
            maxHeight: "100vh",
            border: "none",
            mb: "25px",
            padding: "0px 20px",
            borderRadius: "20px",
            overflow: "hidden",
            position: "relative",
            color: theme === "light" ? "#44546F" : "#fff",
            background:
              theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)",

            "& .MuiDataGrid-columnHeaders": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            // Pagination
            "& .MuiTablePagination-toolbar": {
              color: theme === "light" ? "#060C16" : "#fff",
            },
            "& .MuiButtonBase-root": {
              color: theme === "light" ? "#060C16" : "#fff",
            },

            "& .MuiSelect-icon": {
              color: theme === "light" ? "#060C16" : "#fff",
            },
            "& .MuiButtonBase-root.Mui-disabled": {
              color: theme === "light" ? "#A9A9A9" : "#7E7E7E",
            },
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              width: "50%",
              borderBottom:
                theme === "light" ? "2px solid #5733F7" : "2px solid #fff",
              left: "50%",
              transform: "translateX(-50%)",
            },
            // Custom Checkbox Styling
            "& .MuiDataGrid-checkboxInput": {
              color: theme === "light" ? "#44546F" : "#fff", // Checkbox color
              fontWeight: 600,
              "&.Mui-checked": {
                color: theme === "light" ? "#5733F7" : "#fff", // Color when checked
              },
              "& .MuiSvgIcon-root": {
                fontSize: "20px", // Adjust checkbox size
              },
              "&.Mui-disabled": {
                color: theme === "light" ? "#A9A9A9" : "#7E7E7E", // Disabled checkbox color
              },
            },
          }}
          className={
            theme === "light" ? "custom-light-grid" : "custom-dark-grid"
          }
          rows={dataRows}
          columns={columns}
          paginationMode="server"
          rowsPerPageOptions={[10, 25, 50, 100]}
          checkboxSelection
          getRowId={(row) => row._id}
          disableSelectionOnClick
          rowCount={totalRows}
          page={currentPage - 1}
          onPaginationModelChange={handlePageChange}
          selectionModel={selectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </Box>

      {/*  Delete confirmation modal*/}
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box style={modalContentStyle} className="delete-modal-container">
          {/* Icon */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/DeleteConfirm.svg"
              alt="Delete Confirmation Icon"
              className="delete-icon"
            />
          </Box>

          {/* Title */}
          <Box
            sx={{
              borderBottom: "2px solid #6231F71A",
              width: "90%",
              m: "auto",
              p: "20px 0px",
            }}
          >
            <Typography
              id="delete-confirmation-title"
              variant="h6"
              align="center"
              color="#060c16"
              sx={{ fontSize: "20px", fontWeight: 500, lineHeight: "27px" }}
            >
              Are you sure you want to delete these links? Users who created the
              links will be notified by email.
            </Typography>
            {/* <Typography
              id="delete-confirmation-title"
              variant="h6"
              align="center"
              sx={{ fontSize: "22px", fontWeight: 500, lineHeight: "27px" }}
            >
              Users who created the links will be notified by email.
            </Typography> */}
          </Box>

          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "25px",
              mt: "25px",
            }}
          >
            <Button
              onClick={() => setDeleteModalOpen(false)}
              variant="outlined"
              sx={{
                color: "#060C16",
                border: "1px solid #6231F7",
                borderRadius: "58px",
                textTransform: "none",
                padding: "9px 35px",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "22px",
                "&:hover": {
                  border: "1px solid #6231F7",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              sx={{
                color: "#fff",
                background: "#6231F7",
                borderRadius: "58px",
                textTransform: "none",
                padding: "10px 30px",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "22px",
              }}
            >
              Yes! Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Reports;
