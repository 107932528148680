import React from "react";
import { Modal, Button, Typography, Box } from "@mui/material";
import "./InteractiveDemo.css";

const DeleteConfirmationModal = ({
  isModalVisible,
  handleCancel,
  handleDelete,
  itemId,
}) => {
  const modalContentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "492px",
    backgroundColor: "white",
    borderRadius: "37px",
    boxShadow: 24,
    padding: "30px 25px",
    textAlign: "center",
  };

  return (
    <Modal
      open={isModalVisible}
      onClose={handleCancel}
      aria-labelledby="delete-confirmation-title"
      aria-describedby="delete-confirmation-description"
    >
      <Box style={modalContentStyle} className="delete-modal-container">
        {/* Icon */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/DeleteConfirm.svg"
            alt="Delete Confirmation Icon"
            className="delete-icon"
          />
        </Box>

        {/* Title */}
        <Box
          sx={{
            borderBottom: "2px solid #6231F71A",
            width: "90%",
            m: "auto",
            p: "20px 0px",
          }}
        >
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            align="center"
            sx={{ fontSize: "22px", fontWeight: 500, lineHeight: "27px" }}
          >
            Are you sure that you want to
          </Typography>
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            align="center"
            sx={{ fontSize: "22px", fontWeight: 500, lineHeight: "27px" }}
          >
            delete this rows?
          </Typography>
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "25px",
            mt: "25px",
          }}
        >
          <Button
            onClick={handleCancel}
            variant="outlined"
            sx={{
              color: "#060C16",
              border: "1px solid #6231F7",
              borderRadius: "58px",
              textTransform: "none",
              padding: "9px 35px",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "22px",
              "&:hover": {
                border: "1px solid #6231F7",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(itemId)}
            variant="contained"
            sx={{
              color: "#fff",
              background: "#6231F7",
              borderRadius: "58px",
              textTransform: "none",
              padding: "10px 30px",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "22px",
            }}
          >
            Yes! Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
