import React, { useEffect, useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import HeroSection from "./HeroSection/HeroSection";
import WhyAndWhereSection from "./WhyAndWhereSection/WhyAndWhereSection";
import TestimonialSection from "./TestimonialSection/TestimonialSection";
import FaqSection from "./FaqSection/FaqSection";
import Footer from "./FooterSection/Footer";
import FeaturesSection from "./FeaturesSection/FeaturesSection";
import InteractiveDemo from "./DemoSection.jsx/InteractiveDemo";
import { FaAngleUp } from "react-icons/fa6";
import Test from "./HeroSection/Test";

const Home = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <HeroSection />
      {/* <Test /> */}
      <WhyAndWhereSection />
      <InteractiveDemo />
      <FeaturesSection />
      <TestimonialSection />
      <FaqSection />
      <Footer />

      {showScrollButton && (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 100,
          }}
        >
          <Tooltip title="Scroll To Top" placement="top" arrow>
            <IconButton
              onClick={handleScrollToTop}
              sx={{
                color: "#060C16",
                background: "#AEA9BA",
                boxShadow: 1,
                "&:hover": {
                  color: "#000000",
                  background: "#fff",
                  boxShadow: 2,
                },
              }}
            >
              <FaAngleUp />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default Home;
