import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { motion } from "framer-motion";
import { users } from "../../../Constant";

const TestimonialCarousel = () => {
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // Current page for pagination
  const usersPerPage = 4; // Number of users to show at a time

  // Calculate the total number of pages based on user data length
  const totalPages = Math.ceil(users.length / usersPerPage);

  // Get the current set of users to display based on the page
  const currentUsers = users.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  // Handle hover to change the selected review
  const handleHover = (index) => {
    setSelectedUserIndex(index);
  };

  // Handle clicking on the next arrow
  const nextReview = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages - 1 ? 0 : prevPage + 1
    );
  };

  // Handle clicking on the previous arrow
  const previousReview = () => {
    setCurrentPage((prevPage) =>
      prevPage === 0 ? totalPages - 1 : prevPage - 1
    );
  };

  return (
    <div className="testimonial-container">
      {/* Left side user list */}
      <div className="user-list">
        {currentUsers.map((user, index) => (
          <motion.div
            className={`user-box ${
              currentPage * usersPerPage + index === selectedUserIndex
                ? "active"
                : ""
            }`}
            key={index}
            whileHover={{ scale: 1.05 }}
            onHoverStart={() => handleHover(currentPage * usersPerPage + index)} // Retain hover logic to update review
          >
            <img src={user.image} alt={user.name} className="user-image" />
            <div className="user-details">
              <h4>{user.name}</h4>
              <p>{user.location}</p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Right side review */}
      <motion.div
        className="review-box"
        key={selectedUserIndex}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.5 }}
      >
        <div className="review-icon-div">
          <img src="./reviewIcon.svg" alt="Icon" />
        </div>

        <h3 className="review-heading">{users[selectedUserIndex].review}</h3>
        <p className="review-text">{users[selectedUserIndex].details}</p>
        <div className="rating">
          {"★".repeat(users[selectedUserIndex].rating)}{" "}
          {"☆".repeat(5 - users[selectedUserIndex].rating)}
        </div>
      </motion.div>

      {/* Left and Right navigation */}
      <div className="navigation">
        <IconButton
          className="arrow-buttton"
          disabled={currentPage === 0}
          size="small"
          onClick={previousReview}
          sx={{
            border:
              currentPage === 0
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "1px solid #fff",
            color: "#fff",
            "&:hover": {
              border: "1px solid #525d73",
              background: "#525d73",
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 38 38"
            fill="none"
          >
            <path
              d="M18.9414 11.3662L11.2427 19.0649L18.9414 26.7637"
              stroke="white"
              stroke-opacity={currentPage === 0 && "0.38"}
              stroke-width="2.17753"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </IconButton>

        <IconButton
          className="arrow-buttton"
          disabled={currentPage === totalPages - 1}
          size="small"
          onClick={nextReview}
          sx={{
            p: "4px",
            border:
              currentPage === totalPages - 1
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "1px solid #fff",
            color: "#fff",
            "&:hover": {
              border: "1px solid #525d73",
              background: "#525d73",
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 38 38"
            fill="none"
          >
            <path
              d="M19.0586 11.3662L26.7573 19.0649L19.0586 26.7637"
              stroke="white"
              stroke-opacity={currentPage === totalPages - 1 && "0.38"}
              stroke-width="2.17753"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </IconButton>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
