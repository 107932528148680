import {
  Box,
  Button,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { uid } from "uid";
import { Navigate } from "react-router-dom";
import { Context } from "../../context";
import TableHeader from "./TableHeader";
import TableRows from "./TableRows";
import { ChakraProvider } from "@chakra-ui/react";
import BasicUsage from "./Modal";
import { Input } from "@chakra-ui/react";
import Filters from "./Filters";
import { ChevronLeftIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons";
import SkeletonLoading from "./SkeletonLoading";
import AddNewData from "../AddNewData/AddNewData";
import { IoMdAdd } from "react-icons/io";
import { MdLanguage } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa6";
import { IoSearchOutline, IoClose } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { FaSave } from "react-icons/fa";
import DateRangeSlider from "./DatePaper";
import { CiEdit } from "react-icons/ci";
import { GrPowerReset } from "react-icons/gr";
import toast from "react-hot-toast";
import { BsInfoCircle } from "react-icons/bs";

export default function TablePage() {
  const {
    loading,
    data,
    setData,
    userDetails,
    selected,
    setSelected,
    filteredData,
    setFilteredData,
    editing,
    setEditing,
    // toast,
    domainValue,
    setDomainValue,
    clickDetails,
    shortLimit,
    apiLink,
    dataLimit,
    setDataLimit,
    page,
    setPage,
    axios,
    setPaginativeData,
    rangeSliderReset,
    paginativeData,
    setLoading,
    adminUserDetails,
    setRangeSliderReset,
    theme,
  } = useContext(Context);
  // const [check, setCheck] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [domain, setDomain] = useState(domainValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // new
  const [searchLongURL, setSearchLongURL] = useState("");
  const [searchAlias, setSearchAlias] = useState("");
  const [searchShortURL, setsearchShortURL] = useState("");
  const [searchRemarks, setSearchRemarks] = useState("");

  const [sliderVal, setSliderVal] = useState([0, new Date().getTime() / 1000]);
  const [sliderValues, setSliderValues] = useState([
    new Date("2022.01.01").getTime() / 1000,
    new Date().getTime() / 1000,
  ]);

  // search
  function isUnderDate(values, checkDate) {
    const startDate = new Date(values[0] * 1000);
    const endDate = new Date(values[1] * 1000.1);
    if (checkDate >= startDate && checkDate <= endDate) return true;
    return false;
  }
  const [searchInput, setSearchInput] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilterText, setSelectedFilterText] = useState("All");

  const handleFilterChange = (values) => {
    setSelectedFilters(values);
    // console.log("value", values);
    // setSelectedFilterText(values.length === 0 ? "Filter Options" : "All");

    if (values.length === 0) {
      setSelectedFilterText("All");
    } else if (values.length === 1) {
      setSelectedFilterText(values[0]);
    } else if (values.length > 1 && values.length < 4) {
      setSelectedFilterText(`${values[0]}...`);
    } else if (values.length === 4) {
      setSelectedFilterText("All");
    }
  };

  const handleSearchChange = (event) => {
    setPage(1);
    setSearchInput(event.target.value.toLowerCase());
  };

  useEffect(() => {
    if (adminUserDetails._id) return;

    setLoading(true);
    let temp = [];
    if (selectedFilters.length == 0 || selectedFilters.length == 4) {
      for (let i of paginativeData) {
        if (
          (i.longURL.toLowerCase().includes(searchInput) ||
            i.alias.toLowerCase().includes(searchInput) ||
            i.shortURL.toLowerCase().includes(searchInput) ||
            i.remarks.toLowerCase().includes(searchInput)) &&
          isUnderDate(sliderValues, new Date(i.dateCreated))
        ) {
          temp.push(i);
        }
      }
    } else {
      for (let i of paginativeData) {
        let flag = false;
        for (let param of selectedFilters) {
          if (i?.[param]?.toLowerCase().includes(searchInput)) {
            flag = true;
            break;
          }
        }
        if (flag && isUnderDate(sliderValues, new Date(i.dateCreated)))
          temp.push(i);
      }
    }
    let paginatedData = [];
    for (let i = page * dataLimit - dataLimit; i <= page * dataLimit - 1; i++) {
      if (!temp[i]) break;
      paginatedData.push(temp[i]);
    }
    setFilteredData(paginatedData);
    setLoading(false);
  }, [
    searchLongURL,
    searchAlias,
    searchShortURL,
    searchRemarks,
    sliderValues,
    page,
    dataLimit,
    paginativeData,
    searchInput,
    selectedFilters,
  ]);

  // Validation for the domain input
  const validateDomain = (domain) => {
    // Regular expression for domain validation
    const domainPattern = /^(?!-)([a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,}$/;

    // Domain length validation
    if (domain.length < 3) {
      toast.error("Domain name is too short.");
      return false;
    }

    // Validate against domain pattern
    if (!domainPattern.test(domain)) {
      toast.error("Please enter a valid domain (e.g., example.com).");
      return false;
    }

    // Ensure no special characters outside the allowed ones
    const specialCharsPattern = /[^a-zA-Z0-9-.]/;
    if (specialCharsPattern.test(domain)) {
      toast.error(
        "Domain contains invalid characters. Only letters, numbers, hyphens, and periods are allowed."
      );
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (validateDomain(domain)) {
      setIsEditable(false);
      axios
        .patch(`${apiLink}shorten/users/${userDetails._id}`, {
          domain: domain,
        })
        .then(() => {
          setDomainValue(domain);
          toast.success("Domain saved successfully!");
        })
        .catch((error) => {
          toast.error("Failed to save the domain. Please try again.");
        });
    }
  };

  function sortAsc(param) {
    const temp = [...data];
    const sortedData = [...temp].sort((a, b) => {
      if (a[param] > b[param]) {
        return -1;
      }
      if (a[param] < b[param]) {
        return 1;
      }
      return 0;
    });

    setPaginativeData(
      sortedData.sort((a, b) => Number(b.favourite) - Number(a.favourite))
    );
  }

  function sortDes(param) {
    const temp = [...data];
    const sortedData = [...temp].sort((a, b) => {
      if (a[param] > b[param]) {
        return 1;
      }
      if (a[param] < b[param]) {
        return -1;
      }
      return 0;
    });
    setPaginativeData(
      sortedData.sort((a, b) => Number(b.favourite) - Number(a.favourite))
    );
  }

  function sortAscDate(param) {
    const temp = [...data];
    const sortedData = [...temp].sort((a, b) => {
      if (new Date(a[param]) > new Date(b[param])) {
        return -1;
      }
      if (new Date(a[param]) < new Date(b[param])) {
        return 1;
      }
      return 0;
    });

    setPaginativeData(
      sortedData.sort((a, b) => Number(b.favourite) - Number(a.favourite))
    );
  }

  function sortDesDate(param) {
    const temp = [...data];
    const sortedData = [...temp].sort((a, b) => {
      if (new Date(a[param]) > new Date(b[param])) {
        return 1;
      }
      if (new Date(a[param]) < new Date(b[param])) {
        return -1;
      }
      return 0;
    });

    setPaginativeData(
      sortedData.sort((a, b) => Number(b.favourite) - Number(a.favourite))
    );
  }

  useEffect(() => {
    axios.get(`${apiLink}shorten/users/${userDetails._id}`).then((res) => {
      setDomainValue(res.data.domain);
      setDomain(res.data.domain);
    });
  }, [apiLink, setDomainValue, userDetails._id]);

  const funcOnClose = async () => {
    for (let i of selected) {
      i.setCheck(false);
      i.setIsReadOnly(true);
    }
    setSelected([]);
  };

  const totalPages = Math.ceil(data.length / dataLimit);

  if (!userDetails._id) return <Navigate to={"/client/login"} />;

  return (
    <Box
      sx={{
        height: "100vh",
        background:
          theme === "light"
            ? `url("/lightBg.svg") no-repeat center center`
            : `url("/loginBg.svg") no-repeat center center`,
        backgroundSize: "cover",
        paddingBottom: "25px",
      }}
    >
      {/* Add Button Section*/}
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor:
            theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.04)",
          p: ["20px 34px", "20px 34px", "20px 34px", "0px 34px"],
          width: "95%",
          m: "auto",
          height: ["auto", "auto", "auto", "105px"],
          display: ["grid", "grid", "grid", "flex"],
          gap: ["20px", "20px", "20px", ""],
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
              width: "457px",
            }}
          >
            <InputGroup size={"lg"}>
              <InputLeftElement
                pointerEvents="none"
                children={
                  <IoSearchOutline
                    color={theme === "light" ? "#060C16" : "#ACB0FF"}
                  />
                }
              />
              <Input
                placeholder="Search"
                bg={theme === "light" ? "#F6F6FF" : "rgba(255, 255, 255, 0.06)"}
                borderRadius="17px"
                value={searchInput}
                onChange={handleSearchChange}
                sx={{
                  // width: "90%",
                  pr: "100px",
                }}
                _placeholder={{
                  color: theme === "light" ? "#060C16" : "#ACB0FF",
                }}
                color={theme === "light" ? "#060C16" : "#fff"}
                borderColor="transparent"
                _hover={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
                _focus={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
              />
              {/* <InputRightElement >
              </InputRightElement> */}
            </InputGroup>

            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                variant="contained"
                rightIcon={<FaChevronDown />}
                sx={{
                  position: "absolute",
                  right: 0,
                  borderTopRightRadius: "17px",
                  borderBottomRightRadius: "17px",
                  textTransform: "none",
                  fontSize: "16px",
                  height: "52px",
                  padding: "0px 20px",
                  color: theme === "light" ? "#6231F7" : "#fff",
                  background: "transparent",
                  fontWeight: 500,
                  _hover: {
                    background: "transparent",
                  },
                }}
              >
                {selectedFilterText}
              </MenuButton>

              <MenuList
                sx={{
                  background: theme === "light" ? "#fff" : "#1F243D",
                  color: theme === "light" ? "#060C16" : "#fff",
                  borderRadius: "12px",
                }}
              >
                <MenuOptionGroup
                  type="checkbox"
                  value={selectedFilters}
                  onChange={handleFilterChange}
                >
                  <MenuItemOption
                    sx={{
                      background: theme === "light" ? "#fff" : "#1F243D",
                      color: theme === "light" ? "#060C16" : "#fff",
                      _hover: {
                        background:
                          theme === "light"
                            ? "#F6F6FF"
                            : "rgba(255, 255, 255, 0.05)",
                      },
                    }}
                    value="selectAll"
                    onClick={() =>
                      handleFilterChange(
                        selectedFilters.length === 0
                          ? ["longURL", "shortURL", "alias", "remarks"]
                          : []
                      )
                    }
                  >
                    Select All
                  </MenuItemOption>
                  <MenuItemOption
                    value="longURL"
                    sx={{
                      background: theme === "light" ? "#fff" : "#1F243D",
                      color: theme === "light" ? "#060C16" : "#fff",
                      _hover: {
                        background:
                          theme === "light"
                            ? "#F6F6FF"
                            : "rgba(255, 255, 255, 0.05)",
                      },
                    }}
                  >
                    Long URLs
                  </MenuItemOption>
                  <MenuItemOption
                    value="shortURL"
                    sx={{
                      background: theme === "light" ? "#fff" : "#1F243D",
                      color: theme === "light" ? "#060C16" : "#fff",
                      _hover: {
                        background:
                          theme === "light"
                            ? "#F6F6FF"
                            : "rgba(255, 255, 255, 0.05)",
                      },
                    }}
                  >
                    Short URLs
                  </MenuItemOption>
                  <MenuItemOption
                    sx={{
                      background: theme === "light" ? "#fff" : "#1F243D",
                      color: theme === "light" ? "#060C16" : "#fff",
                      _hover: {
                        background:
                          theme === "light"
                            ? "#F6F6FF"
                            : "rgba(255, 255, 255, 0.05)",
                      },
                    }}
                    value="alias"
                  >
                    Alias
                  </MenuItemOption>
                  <MenuItemOption
                    sx={{
                      background: theme === "light" ? "#fff" : "#1F243D",
                      color: theme === "light" ? "#060C16" : "#fff",
                      _hover: {
                        background:
                          theme === "light"
                            ? "#F6F6FF"
                            : "rgba(255, 255, 255, 0.05)",
                      },
                    }}
                    value="remarks"
                  >
                    Remarks
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "25px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "25px",
            }}
          >
            {!isEditable && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // gap: "16px",
                }}
              >
                <Button
                  variant="contained"
                  leftIcon={domain ? <CiEdit /> : <MdLanguage />}
                  onClick={() => {
                    setIsEditable(!isEditable);
                  }}
                  sx={{
                    textTransform: "none",
                    fontSize: "16px",
                    height: "52px",
                    padding: "0px 10px",
                    color: theme === "light" ? "#060C16" : "#fff",
                    background: "transparent",
                    fontWeight: 500,
                    boxShadow: "none",
                    _hover: {
                      background: "transparent",
                    },
                  }}
                >
                  {domain ? "Edit Domain" : "Add Domain"}
                </Button>
                <Tooltip
                  hasArrow
                  label="Go to your domain hosting site (ex. GoDaddy, etc.) and go to that particular domain and then in the Forwarding section, edit the IP from the existing to - 103.209.145.15, until these changes are not done above view link will not work."
                >
                  <Box>
                    <BsInfoCircle
                      size={16}
                      color={theme === "light" ? "#6231F7" : "#fff"}
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            )}

            {isEditable ? (
              <Box>
                <InputGroup size={"lg"}>
                  <InputRightElement
                    // pointerEvents="none"
                    children={
                      <Button
                        isDisabled={!domain}
                        onClick={handleSave}
                        // onClick={() => {
                        //   setIsEditable(false);
                        //   axios.patch(
                        //     `${apiLink}shorten/users/${userDetails._id}`,
                        //     {
                        //       domain: domain,
                        //     }
                        //   );
                        //   setDomainValue(domain);
                        // }}
                        sx={{
                          borderRadius: "17px",
                          background: "#6231F7",
                          fontSize: "14px",
                          cursor: "pointer",
                          p: "0px 35px",
                          color: "#fff",
                          mb: "10px",
                          _hover: {
                            background: "#6231F7",
                          },
                        }}
                      >
                        Save
                      </Button>
                    }
                  />
                  <Input
                    variant="flushed"
                    placeholder="Ex. cbxmeet.in"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    // bg="rgba(255, 255, 255, 0.06)"
                    background={"transparent"}
                    _placeholder={{
                      color: theme === "light" ? "#060C16" : "#6E8BC2",
                    }}
                    color={theme === "light" ? "#060C16" : "#fff"}
                    borderColor={theme === "light" ? "#060C16" : "#fff"}
                    _hover={{
                      borderColor: theme === "light" ? "#060C16" : "#fff",
                    }}
                    _focus={{
                      borderColor: theme === "light" ? "#060C16" : "#fff",
                    }}
                  />
                </InputGroup>
              </Box>
            ) : (
              domain && (
                <Box
                  sx={{
                    color: theme === "light" ? "#060C16" : "#fff",
                    border:
                      theme === "light"
                        ? "1px solid #060C16"
                        : "1px solid #fff",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "45px",
                    padding: "0px 20px",
                    borderRadius: "82px",
                  }}
                >
                  {domain}
                </Box>
              )
            )}
          </Box>

          <Button
            onClick={() => {
              if (shortLimit === 0) {
                return toast.error("Please wait or reload the app.");
                // return toast({
                //   title: `Please wait or reload the app.`,
                //   status: "error",
                //   isClosable: true,
                //   position: "top",
                // });
              }
              setIsModalOpen(true);
            }}
            variant="outline"
            leftIcon={<IoMdAdd />}
            fontSize="16px"
            height="52px"
            padding="0px 40px"
            borderRadius="17px"
            color={theme === "light" ? "#fff" : "#fff"}
            border="1px solid #6231F7"
            bg={theme === "light" ? "#6231f7" : "rgba(255, 255, 255, 0.06)"}
            fontWeight="500"
            boxShadow="none"
            _hover={{
              border: "1px solid #6231F7",
              color: theme === "light" ? "#fff" : "#6231F7",
              backgroundColor: theme === "light" ? "#4A1FDB" : "#fff",
            }}
          >
            Add New URL
          </Button>
        </Box>
      </Box>

      <Box w={"100%"} flexDir={"column"} display={"flex"}>
        <ChakraProvider>
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"90%"}
            margin={"auto"}
            justifyContent={"space-between"}
            p={"20px 0px"}
          >
            <Box>
              <Text
                color={theme === "light" ? "#060C16" : "#fff"}
                fontSize={["14px", "16px", "24px", "24px"]}
                fontWeight="550"
                lineHeight="normal"
              >
                List of Links
              </Text>
            </Box>
            <Box display={"flex"} gap={"20px"}>
              {/* <Button
                disabled={selected.length === 0}
                _hover={{ background: "#00af00" }}
                color={"white"}
                background={"green"}
                display={editing ? "none" : "flex"}
                onClick={() => {
                  if (selected.length === 0) {
                    return toast({
                      title: `Please Select Data to Edit`,
                      status: "error",
                      isClosable: true,
                      position: "top",
                    });
                  }
                  setEditing(true);
                  for (let i of selected) {
                    i.setIsReadOnly(false);
                  }
                }}
              >
                {" "}
                <EditIcon sx={{ mr: "10px" }} /> Edit
              </Button> */}
              <Tooltip hasArrow label="Reset filters">
                <IconButton
                  size={"md"}
                  isRound={true}
                  icon={<GrPowerReset size={18} />}
                  onClick={() => {
                    setSearchInput("");
                    // setSelectedFilterText("All");
                    setSearchLongURL("");
                    setSearchAlias("");
                    setsearchShortURL("");
                    setSearchRemarks("");
                    setSliderValues([
                      new Date("2022.01.01").getTime() / 1000,
                      new Date().getTime() / 1000,
                    ]);
                    setSliderVal([
                      new Date("2022.01.01").getTime() / 1000,
                      new Date().getTime() / 1000,
                    ]);
                    setRangeSliderReset(uid());
                  }}
                  aria-label="Edit"
                  bg={theme === "light" ? "#fff" : "gray.700"}
                  color={theme === "light" ? "#6231f7" : "white"}
                  border={
                    theme === "light" ? "1px solid #fff" : "1px solid #6231F7"
                  }
                  _hover={{
                    bg: theme === "light" ? "#6231f7" : "white",
                    color: theme === "light" ? "#fff" : "#6231F7",
                    border:
                      theme === "light"
                        ? "1px solid #6231f7"
                        : "1px solid #fff",
                  }}
                />
              </Tooltip>
              <Tooltip hasArrow label="Edit">
                <IconButton
                  isDisabled={selected.length === 0}
                  display={editing ? "none" : "flex"}
                  onClick={() => {
                    if (selected.length === 0) {
                      return toast.error("Please Select Data to Edit");
                    }
                    setEditing(true);
                    for (let i of selected) {
                      i.setIsReadOnly(false);
                    }
                  }}
                  size={"md"}
                  isRound={true}
                  icon={<MdOutlineEdit size={18} />}
                  aria-label="Edit"
                  bg={theme === "light" ? "#fff" : "gray.700"}
                  color={theme === "light" ? "#6231f7" : "white"}
                  border={
                    theme === "light" ? "1px solid #fff" : "1px solid #6231F7"
                  }
                  _hover={{
                    bg: theme === "light" ? "#6231f7" : "white",
                    color: theme === "light" ? "#fff" : "#6231F7",
                    border: theme === "light" ? "#060C16" : "1px solid #fff",
                    cursor: selected.length === 0 ? "default" : "pointer",
                  }}
                  _disabled={{
                    border: "1px solid #E7EAF0",
                    backgroundColor: "#E7EAF0",
                    color: "#A5A9C1",
                  }}
                />
              </Tooltip>

              {/* <Button
                _hover={{ background: "green" }}
                color={"white"}
                background={"lightgreen"}
                display={editing ? "flex" : "none"}
                onClick={async () => {
                  setEditing(false);
                  if (selected.length === 0) {
                    return toast({
                      title: `Please Select Data to Edit`,
                      status: "error",
                      isClosable: true,
                      position: "top",
                    });
                  }
                  for (let i of selected) {
                    let temp = {};
                    for (let j of data) {
                      if (j._id === i._id) {
                        temp = j;
                        break;
                      }
                    }
                    axios.patch(`${apiLink}shorten/AllData/${i._id}`, temp);
                    i.setCheck(false);
                    i.setIsReadOnly(true);
                  }
                  for (let i of clickDetails) {
                    axios.patch(`${apiLink}shorten/clicks/${i._id}`, {
                      shortURL: i.shortURL,
                    });
                  }
                  setSelected([]);
                  toast({
                    title: `Data Edited Successfully`,
                    status: "success",
                    isClosable: true,
                    position: "top",
                  });
                  setPaginativeData(structuredClone(data));
                }}
              >
                Save
              </Button> */}
              <Tooltip hasArrow label="Cancel">
                <IconButton
                  display={editing ? "flex" : "none"}
                  onClick={async () => {
                    await funcOnClose();
                    setEditing(false);
                  }}
                  size={"md"}
                  isRound={true}
                  icon={<IoClose size={18} />}
                  aria-label="Save"
                  bg={theme === "light" ? "#fff" : "gray.700"}
                  color={theme === "light" ? "#6231f7" : "white"}
                  border={
                    theme === "light" ? "1px solid #fff" : "1px solid #6231F7"
                  }
                  _hover={{
                    bg: theme === "light" ? "#6231f7" : "white",
                    color: theme === "light" ? "#fff" : "#6231F7",
                    border: theme === "light" ? "#060C16" : "1px solid #fff",
                  }}
                />
              </Tooltip>
              <Tooltip hasArrow label="Save">
                <IconButton
                  display={editing ? "flex" : "none"}
                  onClick={async () => {
                    setEditing(false);
                    if (selected.length === 0) {
                      return toast.error("Please Select Data to Edit");
                      // return toast({
                      //   title: `Please Select Data to Edit`,
                      //   status: "error",
                      //   isClosable: true,
                      //   position: "top",
                      // });
                    }
                    for (let i of selected) {
                      let temp = {};
                      for (let j of data) {
                        if (j._id === i._id) {
                          temp = j;
                          break;
                        }
                      }
                      axios.patch(`${apiLink}shorten/AllData/${i._id}`, temp);
                      i.setCheck(false);
                      i.setIsReadOnly(true);
                    }
                    for (let i of clickDetails) {
                      axios.patch(`${apiLink}shorten/clicks/${i._id}`, {
                        shortURL: i.shortURL,
                      });
                    }
                    setSelected([]);
                    toast.success("Data Edited Successfully");
                    // toast({
                    //   title: `Data Edited Successfully`,
                    //   status: "success",
                    //   isClosable: true,
                    //   position: "top",
                    // });
                    setPaginativeData(structuredClone(data));
                  }}
                  size={"md"}
                  isRound={true}
                  icon={<FaSave size={18} />}
                  aria-label="Save"
                  bg="green"
                  color="white"
                  border="1px solid green"
                  _hover={{
                    bg: "green",
                    color: "#fff",
                    border: "1px solid green",
                  }}
                />
              </Tooltip>

              <BasicUsage
                theme={theme}
                funcClose={async () => {
                  for (let i of selected) {
                    i.setCheck(false);
                    i.setIsReadOnly(true);
                  }
                  setSelected([]);
                }}
                selected={selected}
                openModelFunc={() => {
                  if (selected.length === 0) {
                    toast.error("Please Select Data to Delete");
                    // toast({
                    //   title: `Please Select Data to Delete`,
                    //   status: "error",
                    //   isClosable: true,
                    //   position: "top",
                    // });
                    return false;
                  }
                  if (editing === true) {
                    toast.error("Cannot Delete while Editing");
                    // toast({
                    //   title: `Cannot Delete while Editing`,
                    //   status: "error",
                    //   isClosable: true,
                    //   position: "top",
                    // });
                    return false;
                  }
                  return true;
                }}
                onClick={async () => {
                  for (let i of selected) {
                    axios.delete(`${apiLink}shorten/AllData/${i._id}`);
                  }
                  let filterData = [...data];
                  for (let i of selected) {
                    filterData = filterData.filter((e) => e._id != i._id);
                  }
                  for (let i of selected) {
                    let temp = clickDetails.filter(
                      (element) => element.shortURL == i.shortLinkValue
                    );
                    for (let j of temp) {
                      axios.delete(`${apiLink}shorten/clicks/${j._id}`);
                    }
                  }
                  setFilteredData(filterData);
                  setData(filterData);
                  setSelected([]);
                  toast.success("Data Deleted Successfully");
                  // toast({
                  //   title: `Data Deleted Successfully`,
                  //   status: "success",
                  //   isClosable: true,
                  //   position: "top",
                  // });
                }}
              ></BasicUsage>
              <DateRangeSlider
                sliderVal={sliderVal}
                setSliderVal={setSliderVal}
                resetKey={rangeSliderReset}
                sliderValues={sliderValues}
                setSliderValues={setSliderValues}
                theme={theme}
              />
            </Box>

            {/* <Box display={"flex"} columnGap={"20px"} flexWrap={"wrap"}>
              <Button
                _hover={{ background: "lightgreen" }}
                color={"black"}
                background={"lightgreen"}
                display={isEditable ? "none" : "flex"}
                onClick={() => {
                  setIsEditable(true);
                }}
              >
                <EditIcon sx={{ mr: "10px" }} />
                Add Domain
              </Button>
              <Button
                _hover={{ background: "lightgreen" }}
                color={"black"}
                background={"lightgreen"}
                display={!isEditable ? "none" : "flex"}
                onClick={() => {
                  setIsEditable(false);
                  axios.patch(`${apiLink}shorten/users/${userDetails._id}`, {
                    domain: domain,
                  });
                  setDomainValue(domain);
                }}
              >
                Save
              </Button>
              <Input
                placeholder=""
                w={"45%"}
                style={{ color: "black", fontWeight: "900" }}
                border={"2px solid gray"}
                disabled={!isEditable}
                bgColor={!isEditable ? "gray.100" : "white"}
                readOnly={!isEditable}
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </Box> */}
          </Box>
        </ChakraProvider>

        {/* <ChakraProvider>
          <Box
            // minH={"50%"}
            w={"100%"}
            display={"none"}
            justifyContent={"space-between"}
          >
            <Filters />
          </Box>
        </ChakraProvider> */}
      </Box>
      <ChakraProvider>
        {/* Pagination  */}
        {/* <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={"20px"}
          p={"20px 0px"}
          sx={{ width: "95%", margin: "auto" }}
        >
          <Box sx={{ color: "#fff" }}>
            {`${page * dataLimit - dataLimit + 1} - ${
              page * dataLimit - dataLimit + filteredData.length
            }`}{" "}
            of
            {" " + data.length}
          </Box>
          <Box display={"flex"} gap={"15px"}>
            <Select
              sx={{ color: "#fff" }}
              placeholder="Select Limit"
              value={dataLimit}
              onChange={({ target }) => {
                setDataLimit(+target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
            <Button
              onClick={() => {
                if (page > 1) {
                  setPage((page) => page - 1);
                }
              }}
            >
              <ChevronLeftIcon />
            </Button>
            <Button
              disabled={true}
              onClick={() => {
                if (page < data.length / dataLimit) {
                  setPage((page) => page + 1);
                }
              }}
            >
              <ChevronRightIcon />
            </Button>
          </Box>
        </Box> */}

        <Box
          sx={{
            borderRadius: "10px",
            background:
              theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)",
            width: "95%",
            margin: "auto",
            overflow: "hidden",
            p: "10px 20px 40px 10px",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              width: "50%",
              borderBottom:
                theme === "light" ? "2px solid #5733F7" : "2px solid #fff",
              left: "50%",
              transform: "translateX(-50%)",
            },
          }}
        >
          <Box
            position="sticky"
            top={0}
            // zIndex={2}
            p="0px 15px 0px 10px"
          >
            <TableHeader
              sortAscDate={sortAscDate}
              sortDesDate={sortDesDate}
              sortAsc={sortAsc}
              sortDes={sortDes}
            />
          </Box>

          {loading && (
            <Box display={"flex"} flexDir={"column"} gap={"1px"}>
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
            </Box>
          )}

          <Box
            display={"flex"}
            flexDir={"column"}
            overflowY={"scroll"}
            maxHeight={"45vh"}
            p="0px 15px 0px 10px"
            className={theme === "light" ? "tableBody" : "darkTableBody"}
          >
            {/* {!loading &&
              filteredData.map((i, index) => {
                let date = new Date(i.dateCreated);
                let str = (date + "").split(" ");
                let showDate =
                  str[2] + "-" + str[1] + "-" + str[3] + " " + str[4];
                return (
                  <TableRows
                    date={showDate}
                    toast={toast}
                    key={index + i._id}
                    setData={setData}
                    userDataArr={data}
                    data={{ ...i, showDate }}
                    index={index}
                  />
                );
              })} */}
            {!loading && filteredData.length > 0 ? (
              filteredData.map((i, index) => {
                let date = new Date(i.dateCreated);
                let str = date.toString().split(" ");
                let showDate = `${str[2]}-${str[1]}-${str[3]} ${str[4]}`;

                return (
                  <TableRows
                    date={showDate}
                    toast={toast}
                    key={index + i._id}
                    setData={setData}
                    userDataArr={data}
                    data={{ ...i, showDate }}
                    index={index}
                  />
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "16px",
                  padding: "20px 0px",
                }}
              >
                <Text
                  sx={{
                    fontSize: "64px",
                    color: theme === "light" ? "#060C16" : "#fff",
                  }}
                >
                  {" "}
                  Oops!
                </Text>
                <Text
                  sx={{
                    fontSize: "28px",
                    color: theme === "light" ? "#060C16" : "#fff",
                  }}
                >
                  No data yet that to be shown.
                </Text>
                {/* <img src="/NoData.svg" alt="No Data Found" /> */}
              </div>
            )}
          </Box>
        </Box>

        {/* Pagination */}
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          py="20px"
          width="95%"
          mx="auto"
          color={theme === "light" ? "#060C16" : "white"}
        >
          <Box display="flex" alignItems="center" gap="20px">
            <Text
              sx={{
                fontSize: "14px",
                color: theme === "light" ? "#060C16" : "#fff",
                fontWeight: 500,
              }}
            >
              Rows per page :{" "}
            </Text>

            <Box>
              <Select
                size={"xs"}
                width="fit-content"
                value={dataLimit}
                onChange={({ target }) => {
                  setDataLimit(+target.value);
                  setPage(1);
                }}
                color={theme === "light" ? "#060D17" : "white"}
                borderRadius="4px"
                sx={{
                  background:
                    theme === "light" ? "#F6F6FF" : "rgba(255, 255, 255, 0.06)",
                  "& option": {
                    background:
                      theme === "light"
                        ? "#F6F6FF"
                        : "rgba(255, 255, 255, 0.06)",
                    color: "#060D17",
                    borderRadius: "4px",
                    fontWeight: 500,
                  },
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </Box>

            <Box fontSize="14px">
              {`${page * dataLimit - dataLimit + 1} - ${
                page * dataLimit > data.length ? data.length : page * dataLimit
              }`}{" "}
              of {data.length}
            </Box>
            <Box display="flex" alignItems="center" gap="15px">
              <IconButton
                size={"xs"}
                icon={
                  <ChevronLeftIcon sx={{ width: "20px", height: "20px" }} />
                }
                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                isDisabled={page === 1}
                aria-label="Previous Page"
                bg={theme === "light" ? "#F6F6FF" : "gray.700"}
                color={theme === "light" ? "#060D17" : "white"}
                _hover={{
                  bg: "white",
                  color: "#060D17",
                }}
              />

              <IconButton
                size={"xs"}
                icon={
                  <ChevronRightIcon sx={{ width: "20px", height: "20px" }} />
                }
                onClick={() =>
                  setPage((prev) => Math.min(prev + 1, totalPages))
                }
                isDisabled={page === totalPages}
                aria-label="Next Page"
                bg={theme === "light" ? "#F6F6FF" : "gray.700"}
                color={theme === "light" ? "#060D17" : "white"}
                _hover={{
                  bg: "white",
                  color: "#060D17",
                }}
              />
            </Box>
          </Box>
        </Box>
      </ChakraProvider>

      {/* Add new url modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          maxWidth={["90%", "500px", "981px"]}
          width="100vw"
          position="relative"
          // p="16px 10px"
          p={["16px 10px", "16px 10px", "20px 25px", "25px 30px"]}
          borderRadius={["27px", "37px", "47px", "47px"]}
          boxShadow="lg"
          bg={theme === "light" ? "#fff" : "#23213B"}
          sx={{
            // backdropFilter: "blur(23.399999618530273px)",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              width: "55%",
              borderBottom:
                theme === "light" ? "3px solid #6231F7" : "3px solid #fff",
              left: "50%",
              transform: "translateX(-50%)",
            },
          }}
        >
          <ModalBody>
            <AddNewData
              onClose={() => setIsModalOpen(false)}
              onSub={async (obj, setLoading) => {
                if (data.length > shortLimit) {
                  return toast.error("Free Limit reached !");
                }
                if (obj.longURL.length === 0) {
                  return toast.error("Long URL is required");
                }
                setLoading(true);
                let tempAllData = await axios.post(
                  `${apiLink}shorten/uniqueCheck`,
                  { URL: obj.shortURL }
                );
                if (!tempAllData.data.isUnique) {
                  setLoading(false);
                  return toast.error(
                    "The alias is already being used on the same domain"
                  );
                }
                axios.post(`${apiLink}shorten/AllData`, obj);
                let tempData = [obj, ...data].sort(
                  (a, b) => Number(b.favourite) - Number(a.favourite)
                );
                setData(tempData);
                setPaginativeData(tempData);
                toast.success("New Link Created");
                setLoading(false);
                setIsModalOpen(false);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
