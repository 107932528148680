import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Container,
} from "@mui/material";
import { Lock, Visibility, VisibilityOff, Email } from "@mui/icons-material";
import { CheckCircleIcon, ChevronRightIcon } from "@chakra-ui/icons";
import NavigationBar from "../Nav/NavigationBar";
import { Navigate } from "react-router-dom";
import { Context } from "../../context";
import toast from "react-hot-toast";
import { MdOutlineMail } from "react-icons/md";

const ResetPassword = () => {
  const { axios, apiLink, theme } = useContext(Context);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otpDigits, setOtpDigits] = useState(Array(6).fill(""));
  const [isOtpFilled, setIsOtpFilled] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationOtp, setVerificationOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpTimeout, setOtpTimeout] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);
  const handleToggleConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleOTPChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index] = value;
      setOtpDigits(newOtpDigits);
      if (value.length === 1 && index < otpDigits.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otpDigits[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("Text");
    const digits = pasteData.split("").filter((char) => /^\d$/.test(char));

    // Update otpDigits with pasted digits
    const newOtpDigits = [...otpDigits];

    digits.forEach((digit, idx) => {
      if (idx < newOtpDigits.length) {
        newOtpDigits[idx] = digit;
      }
    });

    setOtpDigits(newOtpDigits);

    // Focus the next empty input
    for (let i = 0; i < newOtpDigits.length; i++) {
      if (newOtpDigits[i] === "") {
        document.getElementById(`otp-${i}`).focus();
        break;
      }
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const otp = otpDigits.join("");
      if (otp === verificationOtp) {
        toast.success("OTP Verified");
        setIsOtpVerified(true);
      } else {
        toast.error("Invalid OTP");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (!password || password !== confirmPassword) {
      return toast.error("Passwords do not match or are invalid");
    }
    try {
      const response = await axios.patch(`${apiLink}forgotpassword/users`, {
        password,
        email,
      });
      toast.success("Password Changed Successfully");
      setTimeout(() => {
        setSubmitted(true);
      }, [2000]);
    } catch (error) {
      console.log(error);
      toast.error("Password reset failed.");
    }
  };

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const isComplete = otpDigits.every((digit) => digit !== "");
    setIsOtpFilled(isComplete);
  }, [otpDigits]);

  useEffect(() => {
    if (otpTimeout > 0) {
      const timer = setTimeout(() => setOtpTimeout(otpTimeout - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [otpTimeout]);

  const handleSendOtp = async () => {
    if (!isValidEmail()) return toast.error("Invalid Email");
    try {
      let { data: user } = await axios.post(
        `${apiLink}shorten/users/uniqueCheck`,
        { email }
      );
      if (user.isUnique) return toast.error("User does not exist");
      setOtpTimeout(60);
      const res = await axios.post(`${apiLink}shorten/sendOtp`, {
        email,
      });
      setIsOtpSent(true);
      setVerificationOtp(res.data + "");
    } catch (error) {
      console.error(error);
    }
  };

  if (submitted) return <Navigate to="/client/login" />;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          theme === "light"
            ? `url("/lightBg.svg") no-repeat center center`
            : `url("/loginBg.svg") no-repeat center center`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        padding: 2,
      }}
    >
      {/* Top Navigation Bar */}

      <NavigationBar />

      {/* Login Form */}
      <Container
        sx={{
          width: { xs: "100%", sm: "500px", lg: "500px" },
          mb: 5,
          mt: "100px",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px", lg: "24px" },
            fontWeight: 500,
            letterSpacing: "0.17px",
            lineHeight: "143%",
            mb: 5,
            color: theme === "light" ? "#060C16" : "#fff",
          }}
        >
          Forgot Password?
        </Typography>

        {/* Email */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            mb: 2,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdOutlineMail
                    size={24}
                    color={theme === "light" ? "#2D2C56" : "#fff"}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: !email
                ? theme === "light"
                  ? "rgba(156, 159, 205, 0.13)"
                  : "rgba(255, 255, 255, 0.06)"
                : "transparent",
              borderRadius: "30px",
              input: {
                color: theme === "light" ? "#2D2C56" : "#fff",
                "&::placeholder": {
                  color: theme === "light" ? "#2D2C56" : "#fff",
                  opacity: 1,
                },
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                borderTopRightRadius: "30px",
                borderBottomRightRadius: "30px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: email
                    ? theme === "light"
                      ? "1px solid #2400FF"
                      : "1px solid #fff"
                    : "transparent",
                  borderRadius: "30px",
                },
                "&:hover fieldset": {
                  border:
                    theme === "light" ? "1px solid #2400FF" : "1px solid #fff",
                  borderRadius: "30px",
                },
                "&.Mui-focused fieldset": {
                  border:
                    theme === "light" ? "2px solid #2400FF" : "2px solid #fff",
                  borderRadius: "30px",
                },
              },
            }}
            required
          />
          <Button
            variant="contained"
            disabled={
              !isValidEmail() || isOtpSent || otpTimeout > 0 || isOtpVerified
            }
            onClick={handleSendOtp}
            sx={{
              position: "absolute",
              right: 0,
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px",
              textTransform: "none",
              fontSize: "16px",
              height: "54px",
              padding: "0px 20px",
              color: "#fff",
              background: "#5734F8",
              fontWeight: 500,
              "&:hover": {
                background: "#411ceb",
              },
              "&.Mui-disabled": {
                backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                color: theme === "light" ? "#848484" : "#060C16",
              },
            }}
          >
            {isOtpSent ? "Sent OTP" : "Send OTP"}
          </Button>
        </Box>
        {isOtpSent && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mb: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: { xs: "10px", sm: "15px", lg: "15px" },
              }}
            >
              {otpDigits.map((digit, index) => (
                <TextField
                  fullWidth
                  key={index}
                  margin="normal"
                  required
                  id={`otp-${index}`}
                  name={`otp-${index}`}
                  autoFocus={index === 0}
                  value={otpDigits[index]}
                  onChange={(e) => handleOTPChange(index, e.target.value)}
                  onPaste={handlePaste}
                  inputProps={{ maxLength: 1 }}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  sx={{
                    backgroundColor:
                      otpDigits[index] === ""
                        ? theme === "light"
                          ? "rgba(156, 159, 205, 0.13)"
                          : "rgba(255, 255, 255, 0.06)"
                        : "transparent",
                    borderRadius: "18px",
                    input: {
                      color: theme === "light" ? "#060C16" : "#fff",
                      fontSize: "20px",
                      textAlign: "center",
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow:
                        "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                      borderRadius: "18px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border:
                          otpDigits[index] !== ""
                            ? theme === "light"
                              ? "1px solid #2400FF"
                              : "1px solid #fff"
                            : "transparent",
                        borderRadius: "18px",
                      },
                      "&:hover fieldset": {
                        border:
                          theme === "light"
                            ? "1px solid #2400FF"
                            : "1px solid #fff",
                        // borderColor: theme === "light" ? "#2400FF" : "#fff",
                        borderRadius: "18px",
                      },
                      "&.Mui-focused fieldset": {
                        border:
                          theme === "light"
                            ? "2px solid #2400FF"
                            : "2px solid #fff",
                        // borderColor: theme === "light" ? "#2400FF" : "#fff",
                        borderRadius: "18px",
                      },
                    },
                  }}
                />
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                disabled={!isOtpFilled}
                onClick={!isOtpVerified && handleVerifyOtp}
                variant="contained"
                endIcon={
                  isOtpVerified ? (
                    <CheckCircleIcon
                      sx={{
                        width: "23px",
                        height: "23px",
                        color: "#00A825",
                      }}
                    />
                  ) : (
                    ""
                  )
                }
                sx={{
                  borderRadius: "30px",
                  textTransform: "none",
                  color: "#060C16",
                  fontSize: { xs: "14px", md: "16px" },
                  padding: { xs: "0px 25px", sm: "0px 35px", lg: "0px 35px" },
                  height: { xs: "50px", md: "55px" },
                  fontWeight: 500,
                  backgroundColor: theme === "light" ? "#fff" : "#fff",
                  mt: 2,
                  border: theme === "light" ? "1px solid #B8B8FF" : "",
                  boxShadow: "none",

                  "&:hover": {
                    boxShadow: "none",
                    background: theme === "light" ? "#fff" : "#fff",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                    color: theme === "light" ? "#848484" : "#060C16",
                  },
                }}
              >
                {isOtpVerified ? "Verified" : "Verify OTP"}
              </Button>
              <Button
                variant="outlined"
                disabled={!isOtpSent || isOtpVerified}
                onClick={otpTimeout === 0 && handleSendOtp}
                sx={{
                  borderRadius: "30px",
                  textTransform: "none",
                  color: theme === "light" ? "#060C16" : "#fff",
                  border:
                    theme === "light" ? "1px solid #151515" : "1px solid #fff",
                  fontSize: { xs: "14px", md: "16px" },
                  padding: { xs: "0px 25px", sm: "0px 35px", lg: "0px 34px" },
                  height: { xs: "50px", md: "54px" },
                  fontWeight: 500,
                  mt: 2,

                  "&:hover": {
                    border:
                      theme === "light"
                        ? "1px solid #151515"
                        : "1px solid #fff",
                    color:
                      otpTimeout === 0
                        ? theme === "light"
                          ? "#060C16"
                          : "#060C16"
                        : theme === "light"
                        ? "#151515"
                        : "#fff",
                    background: otpTimeout === 0 ? "#fff" : "",
                  },

                  "&.Mui-disabled": {
                    backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                    color: theme === "light" ? "#848484" : "#060C16",
                  },
                }}
              >
                {isOtpSent && !isOtpVerified
                  ? `Resend OTP ${otpTimeout}`
                  : " Resend OTP"}
              </Button>
            </Box>
          </Box>
        )}

        {isOtpVerified && (
          <>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock
                      sx={{ color: theme === "light" ? "#2D2C56" : "#fff" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePassword}
                      sx={{ color: theme === "light" ? "#2D2C56" : "#fff" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // sx={{
              //   backgroundColor: "rgba(255, 255, 255, 0.06)",
              //   borderRadius: "30px",
              //   marginBottom: 2,
              //   input: { color: "#fff" },
              //   "& input:-webkit-autofill": {
              //     WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
              //     borderTopRightRadius: "30px",
              //     borderBottomRightRadius: "30px",
              //   },
              //   "& .MuiOutlinedInput-root": {
              //     "& fieldset": {
              //       borderRadius: "30px",
              //     },
              //     "&:hover fieldset": {
              //       borderColor: "#fff",
              //       borderRadius: "30px",
              //     },
              //     "&.Mui-focused fieldset": {
              //       borderColor: "#fff",
              //       borderRadius: "30px",
              //     },
              //   },
              // }}
              sx={{
                backgroundColor: !password
                  ? theme === "light"
                    ? "rgba(156, 159, 205, 0.13)"
                    : "rgba(255, 255, 255, 0.06)"
                  : "transparent",
                borderRadius: "30px",
                marginBottom: 2,
                input: {
                  color: theme === "light" ? "#2D2C56" : "#fff",
                  "&::placeholder": {
                    color: theme === "light" ? "#2D2C56" : "#fff",
                    opacity: 1,
                  },
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: password
                      ? theme === "light"
                        ? "1px solid #2400FF"
                        : "1px solid #fff"
                      : "transparent",
                    borderRadius: "30px",
                  },
                  "&:hover fieldset": {
                    border:
                      theme === "light"
                        ? "1px solid #2400FF"
                        : "1px solid #fff",
                    borderRadius: "30px",
                  },
                  "&.Mui-focused fieldset": {
                    border:
                      theme === "light"
                        ? "2px solid #2400FF"
                        : "2px solid #fff",
                    borderRadius: "30px",
                  },
                },
              }}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Confirm Password"
              autoFocus
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={showConfirmPassword ? "text" : "password"}
              // onKeyPress={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock
                      sx={{ color: theme === "light" ? "#2D2C56" : "#fff" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConfirmPassword}
                      sx={{ color: theme === "light" ? "#2D2C56" : "#fff" }}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // sx={{
              //   backgroundColor: "rgba(255, 255, 255, 0.06)",
              //   borderRadius: "30px",
              //   marginBottom: 2,
              //   input: { color: "#fff" },
              //   "& input:-webkit-autofill": {
              //     WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
              //     borderTopRightRadius: "30px",
              //     borderBottomRightRadius: "30px",
              //   },
              //   "& .MuiOutlinedInput-root": {
              //     "& fieldset": {
              //       borderRadius: "30px",
              //     },
              //     "&:hover fieldset": {
              //       borderColor: "#fff",
              //       borderRadius: "30px",
              //     },
              //     "&.Mui-focused fieldset": {
              //       borderColor: "#fff",
              //       borderRadius: "30px",
              //     },
              //   },
              // }}
              sx={{
                backgroundColor: !confirmPassword
                  ? theme === "light"
                    ? "rgba(156, 159, 205, 0.13)"
                    : "rgba(255, 255, 255, 0.06)"
                  : "transparent",
                borderRadius: "30px",
                marginBottom: 2,
                input: {
                  color: theme === "light" ? "#2D2C56" : "#fff",
                  "&::placeholder": {
                    color: theme === "light" ? "#2D2C56" : "#fff",
                    opacity: 1,
                  },
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: confirmPassword
                      ? theme === "light"
                        ? "1px solid #2400FF"
                        : "1px solid #fff"
                      : "transparent",
                    borderRadius: "30px",
                  },
                  "&:hover fieldset": {
                    border:
                      theme === "light"
                        ? "1px solid #2400FF"
                        : "1px solid #fff",
                    borderRadius: "30px",
                  },
                  "&.Mui-focused fieldset": {
                    border:
                      theme === "light"
                        ? "2px solid #2400FF"
                        : "2px solid #fff",
                    borderRadius: "30px",
                  },
                },
              }}
            />
          </>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: { xs: "10px", md: "16px" },
            mt: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "10px", md: "16px" },
            }}
          >
            <Button
              variant="contained"
              disabled={!password || !confirmPassword}
              onClick={handleSubmit}
              sx={{
                backgroundColor: theme === "light" ? "#2100EB" : "#fff",
                color: theme === "light" ? "#fff" : "#000000",
                fontWeight: 500,
                borderRadius: "100px",
                fontSize: { xs: "14px", md: "16px" },
                padding: { xs: "0px 25px", sm: "0px 50px", lg: "0px 50px" },
                height: { xs: "50px", md: "55px" },
                "&:hover": {
                  color: theme === "light" ? "#fff" : "#060C16",
                  background: theme === "light" ? "#1A00B8" : "#AEA9BA",
                },

                "&.Mui-disabled": {
                  backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                  color: theme === "light" ? "#848484" : "#060C16",
                },
              }}
            >
              Save new password
            </Button>
            {/* <IconButton
              onClick={handleSubmit}
              disabled={!password || !confirmPassword}
              sx={{
                color: "#000000",
                background: "#fff",
                padding: { xs: "10px", md: "14px" },
                "&:hover": {
                  color: "#060C16",
                  background: "#AEA9BA",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#413D4F",
                  color: "#060C16",
                },
              }}
            >
              <ChevronRightIcon sx={{ width: "26px", height: "26px" }} />
            </IconButton> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ResetPassword;
