import {
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Heading,
  Box,
  Input,
  Button,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { uid } from "uid";
import { Context } from "../../context";
import { IoMdClose } from "react-icons/io";
import toast from "react-hot-toast";

export default function AddNewData({ onSub, onClose }) {
  const [longURL, setLongURL] = useState("");
  const [alias, setAlias] = useState("");
  const [remarks, setRemarks] = useState("");
  const {
    data,
    // toast,
    setData,
    shortLimit,
    setFilteredData,
    newDataAdded,
    userDetails,
    setLoading,
    domainValue,
    apiLink,
    theme,
  } = useContext(Context);
  const [shortLink, setShortLink] = useState(apiLink);

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };
  async function handleSubmit() {
    if (!isValidURL(longURL)) {
      toast.error("Please enter a valid URL.");
      return;
    }

    let obj = {
      _id: uid(24),
      longURL: longURL,
      alias: alias,
      shortURL: shortLink.toLowerCase(),
      remarks: remarks,
      clicks: 0,
      domain: domainValue || "",
      userID: userDetails._id,
      favourite: false,
      dateCreated: new Date(),
    };
    if (alias.length === 0) {
      let temp = uid().slice(0, 5);
      obj.alias = temp;
      obj.shortURL = obj.shortURL + temp;
    }
    onSub(obj, setLoading);
  }

  useEffect(() => {
    setShortLink(
      `${"https://" + (domainValue || "cbxit.in") + "/" || apiLink}${alias}`
    );
  }, [alias]);

  if (newDataAdded) return <Navigate to={"/client/links"} />;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <Text
          sx={{
            color: theme === "light" ? "#060C16" : "#fff",
            fontSize: "20px",
            fontWeight: 550,
            lineHeight: "25.2px",
          }}
        >
          Add New URL
        </Text>
        <IconButton
          onClick={onClose}
          isRound={true}
          size={"sm"}
          sx={{
            background:
              theme === "light" ? "#F6F7F8" : "rgba(255, 255, 255, 0.08)",
            color: theme === "light" ? "#060C16" : "#fff",
            _hover: {
              background:
                theme === "light" ? "#f0f5fa" : "rgba(255, 255, 255, 0.22)",
              color: theme === "light" ? "#060C16" : "#fff",
            },
          }}
          aria-label="Close"
          icon={<IoMdClose size={22} />}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // flexDirection: "column",
          gap: "25px",
          flexWrap: "wrap",
        }}
      >
        <Input
          type="url"
          value={longURL}
          onChange={(e) => setLongURL(e.target.value)}
          variant="outline"
          width={["100%", "90%", "419px"]}
          bg={theme === "light" ? "#F6F7F8" : "#000627"}
          placeholder="Long URL*"
          borderRadius="10px"
          sx={{ height: "49px", fontSize: "15px" }}
          _placeholder={{ color: theme === "light" ? "#707A89" : "#fff" }}
          color={theme === "light" ? "#060C16" : "#fff"}
          borderColor="transparent"
          _hover={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
          _focus={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
        />
        <Input
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          width={["100%", "90%", "419px"]}
          variant="outline"
          bg={theme === "light" ? "#F6F7F8" : "#000627"}
          placeholder="Remarks"
          borderRadius="10px"
          sx={{ height: "49px", fontSize: "15px" }}
          _placeholder={{ color: theme === "light" ? "#707A89" : "#fff" }}
          color={theme === "light" ? "#060C16" : "#fff"}
          borderColor="transparent"
          _hover={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
          _focus={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
          type="text"
        />
        <Input
          value={alias}
          onChange={(e) => {
            setAlias(e.target.value);
          }}
          width={["100%", "90%", "419px"]}
          variant="outline"
          bg={theme === "light" ? "#F6F7F8" : "#000627"}
          placeholder="Alias"
          borderRadius="10px"
          sx={{ height: "49px", fontSize: "15px" }}
          _placeholder={{ color: theme === "light" ? "#707A89" : "#fff" }}
          color={theme === "light" ? "#060C16" : "#fff"}
          borderColor="transparent"
          _hover={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
          _focus={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
          type="text"
        />
        <Input
          readOnly={true}
          value={shortLink}
          onChange={(e) => console.log()}
          variant="outline"
          width={["100%", "90%", "419px"]}
          bg={theme === "light" ? "#F6F7F8" : "#000627"}
          placeholder="Short URL"
          borderRadius="10px"
          sx={{ height: "49px", fontSize: "15px" }}
          _placeholder={{ color: theme === "light" ? "#060C16" : "#fff" }}
          color={theme === "light" ? "#060C16" : "#fff"}
          borderColor="transparent"
          _hover={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
          _focus={{ borderColor: theme === "light" ? "#6231F7" : "#fff" }}
          type="text"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: "20px",
          }}
        >
          <Button
            isDisabled={!longURL}
            onClick={handleSubmit}
            variant="contained"
            sx={{
              borderRadius: "17px",
              textTransform: "none",
              background: theme === "light" ? "#F6F7F8" : "#6231F7",
              color: theme === "light" ? "#6231F7" : "#fff",
              width: "131px",
              lineHeight: "17.64px",
              fontSize: "16px",
              fontWeight: 550,
              p: "25px 0px",
              transition: "transform 0.5s ease-in-out",

              _hover: {
                color: theme === "light" ? "#5825f5" : "",
                transform: "scale(1.05)",
              },
              _disabled: {
                background: theme === "light" ? "#F6F7F8" : "#B5A6F7",
                color: theme === "light" ? "#bab6b6" : "#D0D0D0",
                cursor: "not-allowed",
                transform: "none",
              },
            }}
          >
            Submit
          </Button>{" "}
        </Box>
      </Box>
    </Box>
  );
}

// <Flex align={"center"} justify={"center"}>
//   <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
//     <Stack align={"center"}>
//       <Heading fontSize={"4xl"}>New URL</Heading>
//     </Stack>
//     <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
//       <Stack spacing={4}>
//         <FormControl>
//           <FormLabel>Long URL</FormLabel>
//           <Input
//             value={longURL}
//             onChange={(e) => setLongURL(e.target.value)}
//             type="text"
//           />
//         </FormControl>
//         <FormControl>
//           <FormLabel>Remarks</FormLabel>
//           <Input
//             value={remarks}
//             onChange={(e) => setRemarks(e.target.value)}
//             type="text"
//           />
//         </FormControl>
//         <FormControl>
//           <FormLabel>Alias</FormLabel>
//           <Input
//             value={alias}
//             onChange={(e) => {
//               setAlias(e.target.value);
//             }}
//             type="text"
//           />
//         </FormControl>
//         <FormControl>
//           <FormLabel>Short Link</FormLabel>
//           <Input
//             readOnly={true}
//             value={shortLink}
//             onChange={(e) => console.log()}
//             type="text"
//           />
//         </FormControl>
//         <Stack spacing={10}>
//           <Stack
//             direction={{ base: "column", sm: "row" }}
//             align={"start"}
//             justify={"space-between"}
//           ></Stack>
//           <Button
//             onClick={handleSubmit}
//             bg={"blue.400"}
//             color={"white"}
//             _hover={{
//               bg: "blue.500",
//             }}
//           >
//             Submit
//           </Button>
//         </Stack>
//       </Stack>
//     </Box>
//   </Stack>
// </Flex>
