import { Box, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { FaSort } from "react-icons/fa";

// const SortIcon = () => {
//   return (
//     <svg
//       style={{ height: "50%", position: "absolute", right: "10px" }}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 320 512"
//     >
//       <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" />
//     </svg>
//   );
// };

const TableHeader = ({ sortAsc, sortDes }) => {
  const { selected, setSelected, theme } = useContext(Context);
  let arr;
  let [selectAll, setSelectAll] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  useEffect(() => {
    arr = document.querySelectorAll(".select");
  });
  return (
    <Box
      display={"flex"}
      gap={"20px"}
      minW={"100%"}
      position={"relative"}
      sx={{
        borderBottom:
          theme === "light" ? "rgba(255, 255, 255, 0.06)" : "1px solid #fff",
        paddingBottom: "10px",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}

        cursor={"pointer"}
        borderRadius={"none"}
        color={theme === "light" ? "#060C16" : "#fff"}
        h={"40px"}
        w={"6%"}
        fontSize={"14px"}
        fontWeight={"500"}
        onClick={() => {
          let value = !selectAll;
          if (value) {
            for (let i of arr) {
              i.click();
            }
          } else {
            for (let i of selected) {
              i.setCheck(false);
              i.setIsReadOnly(true);
            }
            setSelected([]);
          }
          setSelectAll(value);
        }}
      >
        Checkbox
      </Box>

      <Box
        onClick={() => {
          let value = !firstName;
          if (value) {
            sortAsc("firstName");
          } else sortDes("firstName");
          setFirstName(value);
        }}
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}
        w={"12%"}
        cursor={"pointer"}
        borderRadius={"none"}
        color={theme === "light" ? "#060C16" : "#fff"}
        h={"40px"}
        fontSize={"14px"}
        fontWeight={"500"}
        // paddingRight="15px"
      >
        First Name{" "}
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        onClick={() => {
          let value = !lastName;
          if (value) {
            sortAsc("lastName");
          } else sortDes("lastName");
          setLastName(value);
        }}
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}
        w={"6%"}
        cursor={"pointer"}
        borderRadius={"none"}
        color={theme === "light" ? "#060C16" : "#fff"}
        h={"40px"}
        fontSize={"14px"}
        fontWeight={"500"}
        whiteSpace={"nowrap"}
        // paddingRight="15px"
      >
        Last Name
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        onClick={() => {
          let value = !email;
          if (value) {
            sortAsc("email");
          } else sortDes("email");
          setEmail(value);
        }}
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}
        w={"20%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        fontSize={"14px"}
        fontWeight={"500"}
        color={theme === "light" ? "#060C16" : "#fff"}
      >
        Email{" "}
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}
        w={"10%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
      >
        Password{" "}
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}
        w={"10%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
      >
        Created At
      </Box>

      <Box
        onClick={() => {
          let value = !domain;
          if (value) {
            sortAsc("domain");
          } else sortDes("domain");
          setDomain(value);
        }}
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}
        w={"10%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
      >
        Domain{" "}
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />{" "}
      </Box>

      <Box
        onClick={() => {
          let value = !isAdmin;
          if (value) {
            sortAsc("isAdmin");
          } else sortDes("isAdmin");
          setIsAdmin(value);
        }}
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}
        w={"8%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
        whiteSpace={"nowrap"}
      >
        Is Admin{" "}
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />{" "}
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flexShrink={0}
        w={"11%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
        whiteSpace={"nowrap"}
      >
        Credits Allowed{" "}
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        w={"11%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
        whiteSpace={"nowrap"}
      >
        Credits Remaining{" "}
      </Box>
    </Box>
  );
};

export default TableHeader;
