import {
  Box,
  Button,
  Select,
  Text,
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Input,
  InputLeftElement,
  InputGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import { useContext, useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import { Context } from "../../context";
import AdminTableHeader from "./TableHeader";
import TableRows from "./TableRows";
import { ChakraProvider } from "@chakra-ui/react";
import BasicUsage from "./Modal";
import Filters from "./Filters";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { MdOutlineEdit } from "react-icons/md";
import SkeletonLoading from "./SkeletonLoading";
import toast from "react-hot-toast";
import { FaChevronDown } from "react-icons/fa6";
import { IoSearchOutline, IoClose } from "react-icons/io5";
import { FaSave } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";

export default function AdminTablePage() {
  const {
    axios,
    loading,
    selected,
    setSelected,
    editing,
    setEditing,
    isAdminLogin,
    apiLink,
    dataLimit,
    setDataLimit,
    page,
    setPage,
    setLoading,
    theme,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const [paginativeData, setPaginativeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilterText, setSelectedFilterText] = useState("All");

  const handleFilterChange = (values) => {
    setSelectedFilters(values);
    // setSelectedFilterText(values.length === 0 ? "Filter Options" : "All");
    if (values.length === 0) {
      setSelectedFilterText("All");
    } else if (values.length === 1) {
      setSelectedFilterText(values[0]);
    } else if (values.length > 1 && values.length < 4) {
      setSelectedFilterText(`${values[0]}...`);
    } else if (values.length === 4) {
      setSelectedFilterText("All");
    }
  };

  const handleSearchChange = (event) => {
    // if (selectedFilters.length === 0) {
    //   toast.error("Please select at least one filter before searching.");
    //   return;
    // }

    setPage(1);
    setSearchInput(event.target.value.toLowerCase());
  };

  useEffect(() => {
    if (!isAdminLogin) return;

    let temp = [];

    if (selectedFilters.length === 0 || selectedFilters.length === 4) {
      for (let i of paginativeData) {
        if (
          i.firstName?.toLowerCase()?.includes(searchInput.toLowerCase()) ||
          "" ||
          i.lastName?.toLowerCase()?.includes(searchInput.toLowerCase()) ||
          "" ||
          i.email?.toLowerCase()?.includes(searchInput.toLowerCase()) ||
          "" ||
          i.domain?.toLowerCase()?.includes(searchInput.toLowerCase()) ||
          ""
        ) {
          temp.push(i);
        }
      }
    } else {
      // Filter based on selected filters
      for (let i of paginativeData) {
        let flag = false;
        for (let param of selectedFilters) {
          if (i?.[param]?.toLowerCase()?.includes(searchInput.toLowerCase())) {
            flag = true;
            break;
          }
        }
        if (flag) temp.push(i);
      }
    }

    // Paginate the filtered data
    let paginatedData = [];
    for (let i = page * dataLimit - dataLimit; i < page * dataLimit; i++) {
      if (!temp[i]) break;
      paginatedData.push(temp[i]);
    }

    setFilteredData(paginatedData);
  }, [
    isAdminLogin,
    firstName,
    lastName,
    email,
    domain,
    page,
    dataLimit,
    paginativeData,
    searchInput,
    selectedFilters,
  ]);

  function sortAsc(param) {
    const temp = [...data];
    const sortedData = [...temp].sort((a, b) => {
      if ((a[param] || "") > (b[param] || "")) {
        return -1;
      }
      if ((a[param] || "") < (b[param] || "")) {
        return 1;
      }
      return 0;
    });

    setPaginativeData(
      sortedData.sort((a, b) => Number(b.favourite) - Number(a.favourite))
    );
  }
  function sortDes(param) {
    const temp = [...data];
    const sortedData = [...temp].sort((a, b) => {
      if ((a[param] || "") > (b[param] || "")) {
        return 1;
      }
      if ((a[param] || "") < (b[param] || "")) {
        return -1;
      }
      return 0;
    });
    setPaginativeData(
      sortedData.sort((a, b) => Number(b.favourite) - Number(a.favourite))
    );
  }
  function sortAscDate(param) {
    const temp = [...data];
    const sortedData = [...temp].sort((a, b) => {
      if (new Date(a[param]) > new Date(b[param])) {
        return -1;
      }
      if (new Date(a[param]) < new Date(b[param])) {
        return 1;
      }
      return 0;
    });

    setPaginativeData(
      sortedData.sort((a, b) => Number(b.favourite) - Number(a.favourite))
    );
  }
  function sortDesDate(param) {
    const temp = [...data];
    const sortedData = [...temp].sort((a, b) => {
      if (new Date(a[param]) > new Date(b[param])) {
        return 1;
      }
      if (new Date(a[param]) < new Date(b[param])) {
        return -1;
      }
      return 0;
    });

    setPaginativeData(
      sortedData.sort((a, b) => Number(b.favourite) - Number(a.favourite))
    );
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    axios.get(`${apiLink}shorten/users`).then((res) => {
      setData(res.data);
      setPaginativeData(res.data);

      setLoading(false);
    });
  }, [apiLink, isAdminLogin]);

  const funcOnClose = async () => {
    for (let i of selected) {
      i.setCheck(false);
      i.setIsReadOnly(true);
    }
    setSelected([]);
  };

  const totalPages = Math.ceil(data.length / dataLimit);

  if (!isAdminLogin) return <Navigate to={"/client/links"} />;

  return (
    <Box
      sx={{
        // minHeight: "100vh",
        height: "100vh",
        background:
          theme === "light"
            ? `url("/lightBg.svg") no-repeat center center`
            : `url("/loginBg.svg") no-repeat center center`,
        backgroundSize: "cover",
        paddingBottom: "25px",
      }}
    >
      <Box w={"100%"} display={"flex"} flexDir={"column"}>
        <ChakraProvider>
          <Box
            display={["grid", "grid", "flex", "flex"]}
            gap={["20px", "20px", "", ""]}
            alignItems={"center"}
            width={"95%"}
            margin={"auto"}
            justifyContent={"space-between"}
            p={"20px 0px"}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                width: "457px",
              }}
            >
              <InputGroup size={"lg"}>
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    <IoSearchOutline
                      color={theme === "light" ? "#060C16" : "#ACB0FF"}
                    />
                  }
                />
                <Input
                  placeholder="Search"
                  bg={theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)"}
                  borderRadius="17px"
                  value={searchInput}
                  onChange={handleSearchChange}
                  sx={{
                    pr: "100px",
                    // fontSize: "15px",
                  }}
                  _placeholder={{
                    color: theme === "light" ? "#060C16" : "#ACB0FF",
                  }}
                  color={theme === "light" ? "#060C16" : "#fff"}
                  borderColor="transparent"
                  _hover={{
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                  }}
                  _focus={{
                    borderColor: theme === "light" ? "#6231F7" : "#fff",
                  }}
                />
              </InputGroup>

              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  variant="contained"
                  rightIcon={<FaChevronDown />}
                  sx={{
                    position: "absolute",
                    right: 0,
                    borderTopRightRadius: "17px",
                    borderBottomRightRadius: "17px",
                    textTransform: "none",
                    fontSize: "16px",
                    height: "52px",
                    padding: "0px 20px",
                    color: theme === "light" ? "#6231F7" : "#fff",
                    background: "transparent",
                    fontWeight: 500,
                    _hover: {
                      background: "transparent",
                    },
                  }}
                >
                  {selectedFilterText}
                </MenuButton>

                <MenuList
                  sx={{
                    background: theme === "light" ? "#fff" : "#1F243D",
                    color: theme === "light" ? "#060C16" : "#fff",
                    borderRadius: "12px",
                  }}
                >
                  <MenuOptionGroup
                    type="checkbox"
                    value={selectedFilters}
                    onChange={handleFilterChange}
                  >
                    <MenuItemOption
                      sx={{
                        background: theme === "light" ? "#fff" : "#1F243D",
                        color: theme === "light" ? "#060C16" : "#fff",
                        _hover: {
                          background:
                            theme === "light"
                              ? "#F6F6FF"
                              : "rgba(255, 255, 255, 0.05)",
                        },
                      }}
                      value="selectAll"
                      onClick={() =>
                        handleFilterChange(
                          selectedFilters.length === 0
                            ? ["firstName", "lastName", "email", "domain"]
                            : []
                        )
                      }
                    >
                      Select All
                    </MenuItemOption>
                    <MenuItemOption
                      value="firstName"
                      sx={{
                        background: theme === "light" ? "#fff" : "#1F243D",
                        color: theme === "light" ? "#060C16" : "#fff",
                        _hover: {
                          background:
                            theme === "light"
                              ? "#F6F6FF"
                              : "rgba(255, 255, 255, 0.05)",
                        },
                      }}
                    >
                      First Name
                    </MenuItemOption>
                    <MenuItemOption
                      value="lastName"
                      sx={{
                        background: theme === "light" ? "#fff" : "#1F243D",
                        color: theme === "light" ? "#060C16" : "#fff",
                        _hover: {
                          background:
                            theme === "light"
                              ? "#F6F6FF"
                              : "rgba(255, 255, 255, 0.05)",
                        },
                      }}
                    >
                      Last Name
                    </MenuItemOption>
                    <MenuItemOption
                      sx={{
                        background: theme === "light" ? "#fff" : "#1F243D",
                        color: theme === "light" ? "#060C16" : "#fff",
                        _hover: {
                          background:
                            theme === "light"
                              ? "#F6F6FF"
                              : "rgba(255, 255, 255, 0.05)",
                        },
                      }}
                      value="email"
                    >
                      Email
                    </MenuItemOption>
                    <MenuItemOption
                      sx={{
                        background: theme === "light" ? "#fff" : "#1F243D",
                        color: theme === "light" ? "#060C16" : "#fff",
                        _hover: {
                          background:
                            theme === "light"
                              ? "#F6F6FF"
                              : "rgba(255, 255, 255, 0.05)",
                        },
                      }}
                      value="domain"
                    >
                      Domian
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>

            {/* Cancel */}
            <Box display={"flex"} gap={"20px"}>
              <Tooltip hasArrow label="Reset filters">
                <IconButton
                  size={"md"}
                  isRound={true}
                  icon={<GrPowerReset size={18} />}
                  onClick={() => {
                    setSearchInput("");
                    setSelectedFilters([]);
                    setSelectedFilterText("Filter Options");
                  }}
                  aria-label="Edit"
                  bg={theme === "light" ? "#fff" : "gray.700"}
                  color={theme === "light" ? "#6231f7" : "white"}
                  border={
                    theme === "light" ? "1px solid #fff" : "1px solid #6231F7"
                  }
                  _hover={{
                    bg: theme === "light" ? "#6231f7" : "white",
                    color: theme === "light" ? "#fff" : "#6231F7",
                    border: theme === "light" ? "#060C16" : "1px solid #fff",
                  }}
                />
              </Tooltip>
              <Tooltip hasArrow label="Edit">
                {/* <Button
                  leftIcon={<MdOutlineEdit />}
                  variant="outline"
                  disabled={selected.length === 0}
                  _hover={{ background: "#6231F7", color: "#fff" }}
                  color={theme === "light" ? "#060C16" : "#fff"}
                  fontWeight={"500"}
                  display={editing ? "none" : "flex"}
                  onClick={() => {
                    if (selected.length === 0) {
                      return toast.error("Please Select Data to Edit");
                    }
                    setEditing(true);
                    for (let i of selected) {
                      i.setIsReadOnly(false);
                    }
                  }}
                  sx={{
                    borderRadius: "58px",
                    border: "2px solid #6231F7",
                    padding: "15px 25px",
                  }}
                >
                  {" "}
                  Edit
                </Button> */}
                <IconButton
                  isDisabled={selected.length === 0}
                  display={editing ? "none" : "flex"}
                  onClick={() => {
                    if (selected.length === 0) {
                      return toast.error("Please Select Data to Edit");
                    }
                    setEditing(true);
                    for (let i of selected) {
                      i.setIsReadOnly(false);
                    }
                  }}
                  size={"md"}
                  isRound={true}
                  icon={<MdOutlineEdit size={18} />}
                  aria-label="Edit"
                  bg={theme === "light" ? "#fff" : "gray.700"}
                  color={theme === "light" ? "#6231f7" : "white"}
                  border={
                    theme === "light" ? "1px solid #fff" : "1px solid #6231F7"
                  }
                  _hover={{
                    bg: theme === "light" ? "#6231f7" : "white",
                    color: theme === "light" ? "#fff" : "#6231F7",
                    border: theme === "light" ? "#060C16" : "1px solid #fff",
                    cursor: selected.length === 0 ? "default" : "pointer",
                  }}
                  _disabled={{
                    border: "1px solid #E7EAF0",
                    backgroundColor: "#E7EAF0",
                    color: "#A5A9C1",
                  }}
                />
              </Tooltip>
              <Tooltip hasArrow label="Cancel">
                <IconButton
                  display={editing ? "flex" : "none"}
                  onClick={async () => {
                    await funcOnClose();
                    setEditing(false);
                  }}
                  size={"md"}
                  isRound={true}
                  icon={<IoClose size={18} />}
                  aria-label="Save"
                  bg={theme === "light" ? "#fff" : "gray.700"}
                  color={theme === "light" ? "#6231f7" : "white"}
                  border={
                    theme === "light" ? "1px solid #fff" : "1px solid #6231F7"
                  }
                  _hover={{
                    bg: theme === "light" ? "#6231f7" : "white",
                    color: theme === "light" ? "#fff" : "#6231F7",
                    border: theme === "light" ? "#060C16" : "1px solid #fff",
                  }}
                />
              </Tooltip>
              <Tooltip hasArrow label="Save">
                <IconButton
                  display={editing ? "flex" : "none"}
                  onClick={async () => {
                    setEditing(false);
                    if (selected.length === 0) {
                      return toast.error("Please Select Data to Edit");
                    }
                    for (let i of selected) {
                      let temp = {};
                      for (let j of data) {
                        if (j._id == i._id) {
                          temp = j;
                          break;
                        }
                      }
                      console.log(temp);
                      axios.patch(`${apiLink}shorten/users/${i._id}`, temp);
                      i.setCheck(false);
                      i.setIsReadOnly(true);
                    }
                    setPaginativeData(structuredClone(data));
                    setSelected([]);
                    toast.success("Data Edited Successfully");
                  }}
                  size={"md"}
                  isRound={true}
                  icon={<FaSave size={18} />}
                  aria-label="Save"
                  bg="green"
                  color="white"
                  border="1px solid green"
                  _hover={{
                    bg: "green",
                    color: "#fff",
                    border: "1px solid green",
                  }}
                />
              </Tooltip>

              {/* Save */}
              {/* <Button
                _hover={{
                  background: "lightgreen",
                  border: "2px solid lightgreen",
                }}
                color={"white"}
                fontWeight={"500"}
                background={"green"}
                display={editing ? "flex" : "none"}
                sx={{
                  borderRadius: "58px",
                  border: "2px solid green",
                  padding: "15px 25px",
                }}
                onClick={async () => {
                  setEditing(false);
                  if (selected.length === 0) {
                    return toast.error("Please Select Data to Edit");
                  }
                  for (let i of selected) {
                    let temp = {};
                    for (let j of data) {
                      if (j._id == i._id) {
                        temp = j;
                        break;
                      }
                    }
                    console.log(temp);
                    axios.patch(`${apiLink}shorten/users/${i._id}`, temp);
                    i.setCheck(false);
                    i.setIsReadOnly(true);
                  }
                  setPaginativeData(structuredClone(data));
                  setSelected([]);
                  toast.success("Data Edited Successfully");
                }}
              >
                Save
              </Button>
               */}
              <BasicUsage
                theme={theme}
                selected={selected}
                funcClose={async () => {
                  for (let i of selected) {
                    i.setCheck(false);
                    i.setIsReadOnly(true);
                  }
                  setSelected([]);
                }}
                openModelFunc={() => {
                  if (selected.length === 0) {
                    toast.error("Please Select Data to Delete");

                    return false;
                  }
                  if (editing === true) {
                    toast.error("Cannot Delete while Editing");

                    return false;
                  }
                  return true;
                }}
                onClick={async () => {
                  let filterData = [...data];
                  for (let i of selected) {
                    filterData = filterData.filter(
                      (element) => element._id != i._id
                    );
                    axios.delete(`${apiLink}shorten/users/${i._id}`);
                    axios
                      .get(`${apiLink}shorten/AllData?userID=${i._id}`)
                      .then(({ data: userLinks }) => {
                        for (let links of userLinks) {
                          axios
                            .get(`${apiLink}shorten/clicks?userID=${i._id}`)
                            .then(({ data: clicksTempData }) => {
                              for (let clicksData of clicksTempData) {
                                axios.delete(
                                  `${apiLink}shorten/clicks/${clicksData._id}`
                                );
                              }
                              axios.delete(
                                `${apiLink}shorten/AllData/${links._id}`
                              );
                            });
                        }
                      });
                  }
                  setData(filterData);
                  setPaginativeData(filterData);
                  setSelected([]);
                  toast.success("Data Deleted Successfully");
                }}
              ></BasicUsage>
            </Box>
          </Box>
        </ChakraProvider>
      </Box>
      {/* <ChakraProvider>
        <Box
          minH={"50%"}
          w={"100%"}
          display={"none"}
          justifyContent={"space-between"}
        >
          <Filters
            paginativeData={paginativeData}
            setPaginativeData={setPaginativeData}
            setFilteredData={setFilteredData}
          />
        </Box>
      </ChakraProvider> */}
      <ChakraProvider>
        {/* Pagination */}
        {/* <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={"20px"}
          p={"10px 20px"}
        >
          <Box>
            {`${page * dataLimit - dataLimit + 1} - ${
              page * dataLimit - dataLimit + filteredData.length
            }`}{" "}
            of
            {" " + data.length}
          </Box>
          <Box display={"flex"} gap={"15px"}>
            <Select
              placeholder="Select Limit"
              value={dataLimit}
              onChange={({ target }) => {
                setDataLimit(+target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
            <Button
              onClick={() => {
                if (page > 1) {
                  setPage((page) => page - 1);
                }
              }}
            >
              <ChevronLeftIcon />
            </Button>
            <Button
              disabled={true}
              onClick={() => {
                if (page < data.length / dataLimit) {
                  setPage((page) => page + 1);
                }
              }}
            >
              <ChevronRightIcon />
            </Button>
          </Box>
        </Box> */}
        <Box
          sx={{
            borderRadius: "10px",
            background:
              theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)",
            width: "95%",
            margin: "auto",
            overflow: "hidden",
            p: "10px 20px 40px 10px",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              width: "50%",
              borderBottom:
                theme === "light" ? "2px solid #5733F7" : "2px solid #fff",
              left: "50%",
              transform: "translateX(-50%)",
            },
          }}
        >
          <Box
            position="sticky"
            top={0}
            //  zIndex={2}
            p="0px 15px 0px 10px"
          >
            <AdminTableHeader sortAsc={sortAsc} sortDes={sortDes} />
          </Box>
          {loading && (
            <Box display={"flex"} flexDir={"column"} gap={"1px"}>
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
            </Box>
          )}
          <Box
            display={"flex"}
            flexDir={"column"}
            overflowY={"scroll"}
            maxHeight={"60vh"}
            p="0px 15px 0px 10px"
            className={theme === "light" ? "tableBody" : "darkTableBody"}
          >
            {filteredData.map((i, index) => {
              let date = new Date(i.dateCreated);
              let str = (date + "").split(" ");
              let showDate =
                str[2] + "-" + str[1] + "-" + str[3] + " " + str[4];
              return (
                <TableRows
                  index={index}
                  date={showDate}
                  toast={toast}
                  key={index + i._id}
                  setData={setData}
                  userDataArr={data}
                  data={{ ...i, showDate }}
                />
              );
            })}
          </Box>
        </Box>
        {/* Pagination */}
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          py="20px"
          width="95%"
          mx="auto"
          color={theme === "light" ? "#060C16" : "white"}
        >
          <Box display="flex" alignItems="center" gap="20px">
            <Text
              sx={{
                fontSize: "14px",
                color: theme === "light" ? "#060C16" : "#fff",
                fontWeight: 500,
              }}
            >
              Rows per page :{" "}
            </Text>

            <Box>
              <Select
                size={"xs"}
                width="fit-content"
                value={dataLimit}
                onChange={({ target }) => {
                  setDataLimit(+target.value);
                  setPage(1);
                }}
                color={theme === "light" ? "#060D17" : "white"}
                borderRadius="4px"
                sx={{
                  background:
                    theme === "light" ? "#F6F6FF" : "rgba(255, 255, 255, 0.06)",
                  "& option": {
                    background:
                      theme === "light"
                        ? "#F6F6FF"
                        : "rgba(255, 255, 255, 0.06)",
                    color: "#060D17",
                    borderRadius: "4px",
                    fontWeight: 500,
                  },
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </Box>

            <Box fontSize="14px">
              {`${page * dataLimit - dataLimit + 1} - ${
                page * dataLimit > data.length ? data.length : page * dataLimit
              }`}{" "}
              of {data.length}
            </Box>
            <Box display="flex" alignItems="center" gap="15px">
              <IconButton
                size={"xs"}
                icon={
                  <ChevronLeftIcon sx={{ width: "20px", height: "20px" }} />
                }
                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                isDisabled={page === 1}
                aria-label="Previous Page"
                bg={theme === "light" ? "#F6F6FF" : "gray.700"}
                color={theme === "light" ? "#060D17" : "white"}
                _hover={{
                  bg: "white",
                  color: "#060D17",
                }}
              />

              <IconButton
                size={"xs"}
                icon={
                  <ChevronRightIcon sx={{ width: "20px", height: "20px" }} />
                }
                onClick={() =>
                  setPage((prev) => Math.min(prev + 1, totalPages))
                }
                isDisabled={page === totalPages}
                aria-label="Next Page"
                bg={theme === "light" ? "#F6F6FF" : "gray.700"}
                color={theme === "light" ? "#060D17" : "white"}
                _hover={{
                  bg: "white",
                  color: "#060D17",
                }}
              />
            </Box>
          </Box>
        </Box>
      </ChakraProvider>
    </Box>
  );
}
