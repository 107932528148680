import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Link,
  Container,
  Slider,
} from "@mui/material";
import { Email, Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { NavLink, Navigate } from "react-router-dom";
import { Context } from "../../../context";
import NavigationBar from "../../Nav/NavigationBar";
import toast from "react-hot-toast";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { MdOutlineMail } from "react-icons/md";

const RegisterForm = () => {
  const { axios, apiLink, theme } = useContext(Context);
  const [otpDigits, setOtpDigits] = useState(Array(6).fill(""));
  const inputRefs = useRef([]);
  const [isOtpFilled, setIsOtpFilled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [progress, setProgress] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpTimeout, setOtpTimeout] = useState(0);
  const [verificationOtp, setVerificationOtp] = useState("");
  const [inputOtp, setInputOtp] = useState("");
  const [submited, setSubmited] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  // OTP box
  const handleOTPChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtpDigits = [...otpDigits];
      for (let i = 0; i < value.length; i++) {
        if (index + i < newOtpDigits.length) {
          newOtpDigits[index + i] = value[i];
        }
      }

      setOtpDigits(newOtpDigits);

      if (index < newOtpDigits.length - 1 && value.length === 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      const newOtpDigits = [...otpDigits];

      if (newOtpDigits[index] === "" && index > 0) {
        newOtpDigits[index - 1] = "";
        document.getElementById(`otp-${index - 1}`).focus();
      } else {
        newOtpDigits[index] = "";
        if (index > 0) {
          document.getElementById(`otp-${index - 1}`).focus();
        }
      }

      setOtpDigits(newOtpDigits);
    } else if (e.key === "Enter" && isOtpFilled) {
      handleVerifyOtp(e);
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("Text");
    const digits = pasteData.split("").filter((char) => /^\d$/.test(char));

    // Update otpDigits with pasted digits
    const newOtpDigits = [...otpDigits];

    digits.forEach((digit, idx) => {
      if (idx < newOtpDigits.length) {
        newOtpDigits[idx] = digit;
      }
    });

    setOtpDigits(newOtpDigits);

    // Focus the next empty input
    for (let i = 0; i < newOtpDigits.length; i++) {
      if (newOtpDigits[i] === "") {
        document.getElementById(`otp-${i}`).focus();
        break;
      }
    }
  };

  // Update the progress based on email and password validity
  useEffect(() => {
    let progressValue = 0;
    if (isValidEmail(email)) {
      progressValue = 20;
    }
    if (isValidEmail(email) && inputOtp) {
      progressValue = 40;
    }
    if (email && inputOtp && firstName) {
      progressValue = 60;
    }
    if (email && firstName && inputOtp && lastName) {
      progressValue = 80;
    }
    if (email && firstName && lastName && password && password.length >= 7) {
      progressValue = 100;
    }

    setProgress(progressValue);
  }, [email, password, firstName, lastName, inputOtp]);

  const handleVerifyOtp = async () => {
    try {
      const otp = otpDigits.join("");
      setInputOtp(otp);
      if (!isOtpSent) return toast.error("Please send the OTP first.");
      if (verificationOtp == otp) {
        setIsOtpVerified(true);
        toast.success("OTP Verified Successfully");
        return;
      }
      if (verificationOtp != otp) return toast.error("OTP does not match");
    } catch (error) {
      console.log(error);
    }
  };

  //  ------------- ------------------- --------------- ------------------------

  // Handle function
  const handleSubmit = async () => {
    if (
      !isOtpVerified ||
      !isValidEmail() ||
      password.length < 8 ||
      !firstName ||
      !lastName
    ) {
      setShowError(true);
    }
    if (!isOtpVerified) return toast.error("OTP is not verified");

    if (!isValidEmail()) return toast.error("Invalid Email");

    if (password.length < 7)
      return toast.error("The length of the password should be greater than 7");

    if (!firstName || !lastName)
      return toast.error("First name and Last name is required");

    let obj = {
      firstName,
      lastName,
      email,
      password,
      domain: "",
    };

    let { data: res } = await axios.post(`${apiLink}shorten/register`, obj);
    if (res.error) return toast.error(res.error);

    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    toast.success("Account created successfully.");
    setTimeout(() => {
      setSubmited(true);
    }, 2000);

    setTimeout(() => setSubmited(false), 1000);
  };

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendText = () => {
    if (otpTimeout == 0 || isOtpVerified) return "Resend OTP";
    else return (otpTimeout - 60) * -1;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    const isComplete = otpDigits.every((digit) => digit !== "");
    setIsOtpFilled(isComplete);
  }, [otpDigits]);

  if (submited) return <Navigate to={"/client/login"} />;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          theme === "light"
            ? `url("/lightBg.svg") no-repeat center center`
            : `url("/loginBg.svg") no-repeat center center`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        padding: 2,
      }}
    >
      {/* Top Navigation Bar */}
      <NavigationBar />

      {/* Progress bar with icon */}
      <Box
        sx={{
          width: "90%",
          maxWidth: 550,
          textAlign: "center",
          marginBottom: { xs: 5, sm: "80px", lg: "80px" },
          mt: "120px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {progress == 100 ? (
            <Box
              sx={{
                position: "absolute",
                left: `${95}%`,
                top: { xs: "-50px", sm: "-75px", lg: "-100px" },
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100px", sm: "150px", lg: "180px" },
                  height: { xs: "50px", sm: "75px", lg: "90px" },
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={
                    theme === "light" ? "/happyLight.svg" : "/happyEmoji.svg"
                  }
                  alt="Icon"
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                left: `${progress}%`,
                top: "-85px",
                top: { xs: "-45px", sm: "-75px", lg: "-85px" },
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "70px", sm: "95px", lg: "111px" },
                  height: { xs: "50px", sm: "75px", lg: "94px" },
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={
                    theme === "light" ? "/lightEmojiIcon.svg" : "/emojiIcon.svg"
                  }
                  alt="Icon"
                />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              position: "absolute",
              left: `${progress}%`,
              top: "35px",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: theme === "light" ? "#080808" : "#fff",
                textTransform: "uppercase",
                fontWeight: 550,
              }}
            >
              {progress}%
            </Typography>
          </Box>

          <Slider
            value={progress}
            sx={{
              width: "100%",
              "& .MuiSlider-thumb": {
                backgroundColor: "#2400FF",
                width: 20,
                height: 20,
              },
              "& .MuiSlider-track": {
                backgroundColor: theme === "light" ? "#9D9DF0" : "#fff",
                height: 6,
              },
              "& .MuiSlider-rail": {
                backgroundColor:
                  theme === "light" ? "rgba(28, 25, 47, 0.08)" : "#1C192F",
                height: 6,
                transition: "all 0.5s ease",
              },
              "& .MuiSlider-thumb:hover, & .MuiSlider-thumb.Mui-focusVisible": {
                boxShadow: "0px 0px 0px 8px rgba(255, 64, 129, 0.16)",
              },
            }}
          />
        </Box>
      </Box>

      {/* Login Form */}
      <Container
        sx={{
          width: { xs: "100%", sm: "520px", lg: "520px" },
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            mb: 3,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={showError.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdOutlineMail
                    size={24}
                    color={theme === "light" ? "#2D2C56" : "#fff"}
                  />
                </InputAdornment>
              ),
            }}
            // sx={{
            //   backgroundColor: "rgba(255, 255, 255, 0.06)",
            //   borderRadius: "30px",
            //   input: { color: "#fff" },
            //   "& input:-webkit-autofill": {
            //     WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
            //     borderTopRightRadius: "30px",
            //     borderBottomRightRadius: "30px",
            //   },
            //   "& .MuiOutlinedInput-root": {
            //     "& fieldset": {
            //       borderRadius: "30px",
            //     },
            //     "&:hover fieldset": {
            //       borderColor: "#fff",
            //       borderRadius: "30px",
            //     },
            //     "&.Mui-focused fieldset": {
            //       borderColor: "#fff",
            //       borderRadius: "30px",
            //     },
            //   },
            // }}
            sx={{
              backgroundColor: !email
                ? theme === "light"
                  ? "rgba(156, 159, 205, 0.13)"
                  : "rgba(255, 255, 255, 0.06)"
                : "transparent",
              borderRadius: "30px",
              input: {
                color: theme === "light" ? "#2D2C56" : "#fff",
                "&::placeholder": {
                  color: theme === "light" ? "#2D2C56" : "#fff",
                  opacity: 1,
                },
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                borderTopRightRadius: "30px",
                borderBottomRightRadius: "30px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: email
                    ? theme === "light"
                      ? "1px solid #2400FF"
                      : "1px solid #fff"
                    : "transparent",
                  borderRadius: "30px",
                },
                "&:hover fieldset": {
                  border:
                    theme === "light" ? "1px solid #2400FF" : "1px solid #fff",
                  borderRadius: "30px",
                },
                "&.Mui-focused fieldset": {
                  border:
                    theme === "light" ? "2px solid #2400FF" : "2px solid #fff",
                  borderRadius: "30px",
                },
              },
            }}
            required
          />
          <Button
            variant="contained"
            disabled={!isValidEmail() || isOtpSent || isOtpVerified}
            sx={{
              position: "absolute",
              right: 0,
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px",
              textTransform: "none",
              fontSize: "16px",
              height: "54px",
              padding: "0px 20px",
              color: "#fff",
              background: "#5734F8",
              fontWeight: 500,
              "&:hover": {
                background: "#411ceb",
              },
              "&.Mui-disabled": {
                backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                color: theme === "light" ? "#848484" : "#060C16",
              },
            }}
            onClick={async () => {
              if (otpTimeout > 0 || !isValidEmail() || isOtpVerified)
                return toast.error("Please enter a Valid Email..");
              let { data: res } = await axios.post(
                `${apiLink}shorten/userExists`,
                { email }
              );
              if (!res.isUnique) {
                return toast.error("User already exist");
              }
              setOtpTimeout((prev) => prev + 1);
              let intervalId = setInterval(() => {
                setOtpTimeout((prev) => {
                  if (prev == 60) {
                    clearInterval(intervalId);
                    return 0;
                  }
                  return prev + 1;
                });
              }, 1000);

              axios
                .post(`${apiLink}shorten/sendOtp`, { email })
                .then((res) => setVerificationOtp(res.data + ""));
              setIsOtpSent(true);
              toast.success("OTP Sent Successfully!");
            }}
          >
            Send OTP
          </Button>
        </Box>

        {/*OTP input  */}
        {isOtpSent && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mb: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: { xs: "10px", sm: "15px", lg: "15px" },
              }}
            >
              {otpDigits.map((digit, index) => (
                <TextField
                  fullWidth
                  key={index}
                  margin="normal"
                  required
                  id={`otp-${index}`}
                  name={`otp-${index}`}
                  autoFocus={index === 0}
                  value={otpDigits[index]}
                  onChange={(e) => handleOTPChange(index, e.target.value)}
                  onPaste={handlePaste}
                  inputProps={{ maxLength: 1 }}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  // sx={{
                  //   backgroundColor: "rgba(255, 255, 255, 0.06)",
                  //   borderRadius: "18px",
                  //   input: {
                  //     color: "#fff",
                  //     fontSize: "20px",
                  //     textAlign: "center",
                  //   },
                  //   "& input:-webkit-autofill": {
                  //     WebkitBoxShadow:
                  //       "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                  //     borderRadius: "18px",
                  //   },
                  //   "& .MuiOutlinedInput-root": {
                  //     "& fieldset": {
                  //       borderRadius: "18px",
                  //     },
                  //     "&:hover fieldset": {
                  //       borderColor: "#fff",
                  //       borderRadius: "18px",
                  //     },
                  //     "&.Mui-focused fieldset": {
                  //       borderColor: "#fff",
                  //       borderRadius: "18px",
                  //     },
                  //   },
                  // }}
                  sx={{
                    backgroundColor:
                      otpDigits[index] === ""
                        ? theme === "light"
                          ? "rgba(156, 159, 205, 0.13)"
                          : "rgba(255, 255, 255, 0.06)"
                        : "transparent",
                    borderRadius: "18px",
                    input: {
                      color: theme === "light" ? "#060C16" : "#fff",
                      fontSize: "20px",
                      textAlign: "center",
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow:
                        "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                      borderRadius: "18px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border:
                          otpDigits[index] !== ""
                            ? theme === "light"
                              ? "1px solid #2400FF"
                              : "1px solid #fff"
                            : "transparent",
                        borderRadius: "18px",
                      },
                      "&:hover fieldset": {
                        border:
                          theme === "light"
                            ? "1px solid #2400FF"
                            : "1px solid #fff",
                        // borderColor: theme === "light" ? "#2400FF" : "#fff",
                        borderRadius: "18px",
                      },
                      "&.Mui-focused fieldset": {
                        border:
                          theme === "light"
                            ? "2px solid #2400FF"
                            : "2px solid #fff",
                        // borderColor: theme === "light" ? "#2400FF" : "#fff",
                        borderRadius: "18px",
                      },
                    },
                  }}
                />
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                disabled={!isOtpFilled}
                endIcon={
                  isOtpVerified ? (
                    <CheckCircleIcon
                      sx={{ width: "23px", height: "23px", color: "#00A825" }}
                    />
                  ) : (
                    ""
                  )
                }
                sx={{
                  borderRadius: "30px",
                  textTransform: "none",
                  color: "#060C16",
                  fontSize: { xs: "14px", md: "16px" },
                  padding: { xs: "0px 25px", sm: "0px 35px", lg: "0px 35px" },
                  height: { xs: "50px", md: "55px" },
                  fontWeight: 500,
                  backgroundColor: theme === "light" ? "#fff" : "#fff",
                  mt: 2,
                  border: theme === "light" ? "1px solid #B8B8FF" : "",
                  boxShadow: "none",
                  "&:hover": {
                    background: theme === "light" ? "#fff" : "#fff",
                    boxShadow: "none",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                    color: theme === "light" ? "#848484" : "#060C16",
                  },
                }}
                onClick={handleVerifyOtp}
              >
                {isOtpVerified ? "Verified" : "Verify OTP"}
              </Button>
              <Button
                variant="outlined"
                disabled={!isOtpSent || isOtpVerified}
                onClick={async () => {
                  if (otpTimeout > 0 || !isValidEmail() || isOtpVerified)
                    return toast.error("Please enter a Valid Email..");
                  let { data: res } = await axios.post(
                    `${apiLink}shorten/userExists`,
                    { email }
                  );
                  console.log("res", res);
                  if (!res.isUnique) {
                    return toast.error("User already exist");
                  }
                  setOtpTimeout((prev) => prev + 1);
                  let intervalId = setInterval(() => {
                    setOtpTimeout((prev) => {
                      if (prev == 60) {
                        clearInterval(intervalId);
                        return 0;
                      }
                      return prev + 1;
                    });
                  }, 1000);

                  axios
                    .post(`${apiLink}shorten/sendOtp`, { email })
                    .then((res) => setVerificationOtp(res.data + ""));
                  setIsOtpSent(true);
                  toast.success("OTP Sent Successfully!");
                }}
                sx={{
                  borderRadius: "30px",
                  textTransform: "none",
                  color: theme === "light" ? "#060C16" : "#fff",
                  border:
                    theme === "light" ? "1px solid #151515" : "1px solid #fff",
                  fontSize: { xs: "14px", md: "16px" },
                  padding: { xs: "0px 25px", sm: "0px 35px", lg: "0px 34px" },
                  height: { xs: "50px", md: "54px" },
                  fontWeight: 500,
                  mt: 2,
                  // "&:hover": {
                  //   color: "#060C16",
                  //   background: "#fff",
                  // },
                  // "&.Mui-disabled": {
                  //   backgroundColor: "#413D4F",
                  //   color: "#060C16",
                  // },
                  "&:hover": {
                    border:
                      theme === "light"
                        ? "1px solid #151515"
                        : "1px solid #fff",
                    color:
                      otpTimeout === 0
                        ? theme === "light"
                          ? "#060C16"
                          : "#060C16"
                        : theme === "light"
                        ? "#151515"
                        : "#fff",
                    background: otpTimeout === 0 ? "#fff" : "",
                  },

                  "&.Mui-disabled": {
                    backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                    color: theme === "light" ? "#848484" : "#060C16",
                  },
                }}
              >
                {sendText()}
              </Button>
            </Box>
          </Box>
        )}

        {/* Name input*/}
        {isOtpVerified && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              placeholder="*First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              // sx={{
              //   backgroundColor: "rgba(255, 255, 255, 0.06)",
              //   borderRadius: "30px",
              //   marginBottom: 3,
              //   input: { color: "#fff" },
              //   "& input:-webkit-autofill": {
              //     WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
              //     borderRadius: "30px",
              //   },
              //   "& .MuiOutlinedInput-root": {
              //     "& fieldset": {
              //       borderRadius: "30px",
              //     },
              //     "&:hover fieldset": {
              //       borderColor: "#fff",
              //       borderRadius: "30px",
              //     },
              //     "&.Mui-focused fieldset": {
              //       borderColor: "#fff",
              //       borderRadius: "30px",
              //     },
              //   },
              // }}
              sx={{
                backgroundColor: !firstName
                  ? theme === "light"
                    ? "rgba(156, 159, 205, 0.13)"
                    : "rgba(255, 255, 255, 0.06)"
                  : "transparent",
                borderRadius: "30px",
                marginBottom: 3,
                input: {
                  color: theme === "light" ? "#2D2C56" : "#fff",
                  "&::placeholder": {
                    color: theme === "light" ? "#2D2C56" : "#fff",
                    opacity: 1,
                  },
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: firstName
                      ? theme === "light"
                        ? "1px solid #2400FF"
                        : "1px solid #fff"
                      : "transparent",
                    borderRadius: "30px",
                  },
                  "&:hover fieldset": {
                    border:
                      theme === "light"
                        ? "1px solid #2400FF"
                        : "1px solid #fff",
                    borderRadius: "30px",
                  },
                  "&.Mui-focused fieldset": {
                    border:
                      theme === "light"
                        ? "2px solid #2400FF"
                        : "2px solid #fff",
                    borderRadius: "30px",
                  },
                },
              }}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={lastName}
              placeholder="*Last Name"
              onChange={(e) => setLastName(e.target.value)}
              // sx={{
              //   backgroundColor: "rgba(255, 255, 255, 0.06)",
              //   borderRadius: "30px",
              //   marginBottom: 3,
              //   input: { color: "#fff" },
              //   "& input:-webkit-autofill": {
              //     WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
              //     borderRadius: "30px",
              //   },
              //   "& .MuiOutlinedInput-root": {
              //     "& fieldset": {
              //       borderRadius: "30px",
              //     },
              //     "&:hover fieldset": {
              //       borderColor: "#fff",
              //       borderRadius: "30px",
              //     },
              //     "&.Mui-focused fieldset": {
              //       borderColor: "#fff",
              //       borderRadius: "30px",
              //     },
              //   },
              // }}
              sx={{
                backgroundColor: !lastName
                  ? theme === "light"
                    ? "rgba(156, 159, 205, 0.13)"
                    : "rgba(255, 255, 255, 0.06)"
                  : "transparent",
                borderRadius: "30px",
                marginBottom: 3,
                input: {
                  color: theme === "light" ? "#2D2C56" : "#fff",
                  "&::placeholder": {
                    color: theme === "light" ? "#2D2C56" : "#fff",
                    opacity: 1,
                  },
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: lastName
                      ? theme === "light"
                        ? "1px solid #2400FF"
                        : "1px solid #fff"
                      : "transparent",
                    borderRadius: "30px",
                  },
                  "&:hover fieldset": {
                    border:
                      theme === "light"
                        ? "1px solid #2400FF"
                        : "1px solid #fff",
                    borderRadius: "30px",
                  },
                  "&.Mui-focused fieldset": {
                    border:
                      theme === "light"
                        ? "2px solid #2400FF"
                        : "2px solid #fff",
                    borderRadius: "30px",
                  },
                },
              }}
              required
            />
          </Box>
        )}

        {isOtpVerified && (
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={showError.password}
            type={showPassword ? "text" : "password"}
            onKeyPress={handleKeyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock
                    sx={{ color: theme === "light" ? "#2D2C56" : "#fff" }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePassword}
                    sx={{ color: theme === "light" ? "#2D2C56" : "#fff" }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: !password
                ? theme === "light"
                  ? "rgba(156, 159, 205, 0.13)"
                  : "rgba(255, 255, 255, 0.06)"
                : "transparent",
              borderRadius: "30px",
              marginBottom: 2,
              input: {
                color: theme === "light" ? "#2D2C56" : "#fff",
                "&::placeholder": {
                  color: theme === "light" ? "#2D2C56" : "#fff",
                  opacity: 1,
                },
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                borderTopRightRadius: "30px",
                borderBottomRightRadius: "30px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: password
                    ? theme === "light"
                      ? "1px solid #2400FF"
                      : "1px solid #fff"
                    : "transparent",
                  borderRadius: "30px",
                },
                "&:hover fieldset": {
                  border:
                    theme === "light" ? "1px solid #2400FF" : "1px solid #fff",
                  borderRadius: "30px",
                },
                "&.Mui-focused fieldset": {
                  border:
                    theme === "light" ? "2px solid #2400FF" : "2px solid #fff",
                  borderRadius: "30px",
                },
              },
            }}
            required
          />
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: { xs: "10px", md: "16px" },
            mt: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "10px", md: "16px" },
            }}
          >
            <Button
              variant="contained"
              disabled={
                !email || !password || !firstName || !lastName || !password
              }
              onClick={handleSubmit}
              sx={{
                backgroundColor: theme === "light" ? "#2100EB" : "#fff",
                color: theme === "light" ? "#fff" : "#000000",
                fontWeight: 500,
                borderRadius: "100px",
                fontSize: { xs: "14px", md: "16px" },
                padding: { xs: "0px 25px", sm: "0px 50px", lg: "0px 50px" },
                height: { xs: "50px", md: "55px" },
                "&:hover": {
                  color: theme === "light" ? "#fff" : "#060C16",
                  background: theme === "light" ? "#1A00B8" : "#AEA9BA",
                },
                "&.Mui-disabled": {
                  backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                  color: theme === "light" ? "#848484" : "#060C16",
                },
              }}
            >
              REGISTER Now
            </Button>
            {/* <IconButton
              onClick={handleSubmit}
              disabled={
                !email || !password || !firstName || !lastName || !password
              }
              sx={{
                color: "#000000",
                background: "#fff",
                padding: { xs: "10px", md: "14px" },
                "&:hover": {
                  color: "#060C16",
                  background: "#AEA9BA",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#413D4F",
                  color: "#060C16",
                },
              }}
            >
              <ChevronRightIcon sx={{ width: "26px", height: "26px" }} />
            </IconButton> */}
          </Box>
          <Box>
            <Link
              component={NavLink}
              to="/client/login"
              sx={{
                color: "#5733F7",
                fontSize: { xs: "14px", sm: "16px", lg: "16px" },
              }}
            >
              Already User? Login
            </Link>
          </Box>
        </Box>
        {/* <Typography
          sx={{
            fontSize: "16px",
            color: "#fff",
            p: "5px 0px  5px 20%",
          }}
        >
          or
        </Typography>

        <Button
          disabled
          variant="contained"
          startIcon={<img src="/googleIcon.svg" alt="Google" />}
          sx={{
            color: "#000000",
            fontWeight: 500,
            borderRadius: "100px",
            background: "#fff",
            fontSize: { xs: "14px", md: "14px" },
            padding: { xs: "0px 25px", sm: "0px 35px", lg: "0px 35px" },
            height: { xs: "50px", md: "55px" },
            "&:hover": {
              color: "#060C16",
              background: "#AEA9BA",
            },
            "&.Mui-disabled": {
              backgroundColor: "#413D4F",
              color: "#060C16",
            },
          }}
        >
          Google Sign In
        </Button> */}
      </Container>
    </Box>
  );
};

export default RegisterForm;
