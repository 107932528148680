// import React, { useEffect, useState } from "react";
// import { Box, Button, Typography } from "@mui/material";
// import { motion, useAnimation } from "framer-motion";
// import { useInView } from "react-intersection-observer";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import Typewriter from "./Typewriter";

// const WhereSection = ({ handleToggle }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isHoveredEffi, setIsHoveredEffi] = useState(false);
//   const [isHoveredSec, setIsHoveredSec] = useState(false);
//   const headingControls = useAnimation();
//   const { ref, inView } = useInView({
//     triggerOnce: false,
//     threshold: 0.4,
//   });

//   useEffect(() => {
//     if (inView) {
//       headingControls.start({
//         opacity: 1,
//         y: 0,
//         transition: { delay: 0.1, duration: 0.5 },
//       });
//     } else {
//       headingControls.start({ opacity: 0, y: 20 });
//     }
//   }, [inView, headingControls]);

//   return (
//     <Box
//       width={"100%"}
//       ref={ref}
//       // sx={{ background: "#060C16" }}
//       component={motion.div}
//       initial={{ backgroundPosition: "100% 0", x: 300, opacity: 0 }}
//       animate={inView ? { backgroundPosition: "0% 0", x: 0, opacity: 1 } : {}}
//       transition={{ duration: 0.8, ease: "easeOut" }}
//       sx={{
//         width: "100%",
//         background: "#060C16",
//         backgroundSize: "200% 100%",
//         overflow: "hidden",
//         p: "5% 0px",
//         position: "relative",
//       }}
//     >
//       {/* Why and Where text clickble */}

//       <Button
//         onClick={handleToggle}
//         variant="outlined"
//         sx={{
//           position: "absolute",
//           right: { xs: "20px", sm: "20px", lg: "50px" },
//           textTransform: "uppercase",
//           color: "#BFBFBF",
//           fontWeight: 600,
//           letterSpacing: { xs: "2px", sm: "2px", lg: "8px" },
//           cursor: "pointer",
//           zIndex: 999,
//           transition: "all 0.3s ease",
//           border: "1px solid rgba(189, 186, 255, 0.40)",
//           borderRadius: "82px",
//           color: "#2201F0",
//           p: { xs: "4px 10px", sm: "5px 12px", md: "6px 16px" },
//           "&:hover": {
//             color: "#2404e0",
//           },
//         }}
//       >
//         Why ?
//       </Button>

//       {/* Heading */}
//       <motion.div
//         style={{ textAlign: "center" }}
//         initial={{ opacity: 0, y: 20 }}
//         animate={headingControls}
//         whileHover={{
//           scale: 1.2,
//           transition: { duration: 0.4 },
//         }}
//       >
//         <Typography
//           className="where-heading"
//           sx={{
//             fontSize: { xs: "16px", sm: "28px", lg: "36px" },
//             display: "inline-block",
//             letterSpacing: { xs: "2px", sm: "9px", md: "12.48px" },
//             lineHeight: { xs: "30px", sm: "35px", md: "39px" },
//             fontWeight: 500,
//           }}
//         >
//           WHERE USE THIS product ?
//         </Typography>
//       </motion.div>

//       <Box
//         sx={{
//           width: "90%",
//           margin: "auto",
//           mt: "5%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         {/* 1 */}
//         <Box
//           component={motion.div}
//           initial={{ x: 500, opacity: 0 }}
//           animate={inView ? { x: 0, opacity: 1 } : { x: 500, opacity: 0 }}
//           transition={{ duration: 0.3 }}
//           className="wherefirstParentBox"
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             gap: "50px",
//             ml: "25%",
//             "&:hover": {
//               mr: "25%",
//             },
//             // "&:hover": {
//             //   transform: "scale(1.05)",
//             //   transition: "transform 0.3s ease",
//             // },
//           }}
//           onMouseEnter={() => {
//             setIsHovered(true);
//             setIsHoveredEffi(false);
//             setIsHoveredSec(false);
//           }}
//           onMouseLeave={() => setIsHovered(false)}
//         >
//           {isHovered ? (
//             <Typewriter text="Boosting" delay={200} infinite />
//           ) : (
//             <Typography
//               className="whyAndWhereHeading"
//               sx={{
//                 fontSize: { xs: "50px", sm: "100px", lg: "133px" },
//                 fontWeight: 700,
//                 textTransform: "uppercase",
//                 color: "#3B3C44",
//               }}
//             >
//               Boosting
//             </Typography>
//           )}

//           <Box
//             className="numbersBox"
//             sx={{
//               display: "none",
//               alignItems: "center",
//               gap: "35px",
//             }}
//           >
//             <Box sx={{ position: "relative" }}>
//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   className="firstDot"
//                   sx={{ width: "5px", height: "5px", color: "#fff" }}
//                 />

//                 <Typography
//                   className="firstNo"
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#BABABA",
//                   }}
//                 >
//                   1
//                 </Typography>
//               </Box>

//               <Box sx={{ position: "absolute" }}>
//                 {" "}
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="116"
//                   height="12"
//                   viewBox="0 0 116 12"
//                   fill="none"
//                 >
//                   <path
//                     d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
//                     fill="white"
//                   />
//                 </svg>
//               </Box>
//             </Box>

//             <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//               <FiberManualRecordIcon
//                 sx={{ width: "5px", height: "5px", color: "#060C16" }}
//               />

//               <Typography
//                 sx={{
//                   fontSize: "24px",
//                   fontWeight: 600,
//                   textTransform: "uppercase",
//                   letterSpacing: "32px",
//                   color: "#3B3C44",
//                 }}
//               >
//                 2
//               </Typography>
//             </Box>

//             <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//               <FiberManualRecordIcon
//                 sx={{ width: "5px", height: "5px", color: "#060C16" }}
//               />

//               <Typography
//                 sx={{
//                   fontSize: "24px",
//                   fontWeight: 600,
//                   textTransform: "uppercase",
//                   letterSpacing: "32px",
//                   color: "#3B3C44",
//                 }}
//               >
//                 3
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//         {/* 2*/}
//         <Box
//           component={motion.div}
//           initial={{ x: 500, opacity: 0 }}
//           animate={inView ? { x: 0, opacity: 1 } : { x: 500, opacity: 0 }}
//           transition={{ duration: 0.7 }}
//           className="wherefirstParentBox"
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             gap: "50px",
//             mt: 2,
//           }}
//           onMouseEnter={() => {
//             setIsHovered(false);
//             setIsHoveredEffi(true);
//             setIsHoveredSec(false);
//           }}
//           onMouseLeave={() => setIsHoveredEffi(false)}
//         >
//           {isHoveredEffi ? (
//             <Typewriter text="marketing" delay={200} infinite />
//           ) : (
//             <Typography
//               className="whyAndWhereHeading"
//               sx={{
//                 fontSize: { xs: "50px", sm: "100px", lg: "133px" },
//                 fontWeight: 700,
//                 textTransform: "uppercase",
//                 color: "#3B3C44",
//               }}
//             >
//               marketing
//             </Typography>
//           )}

//           <Box
//             className="numbersBox"
//             sx={{
//               display: "none",
//               alignItems: "center",
//               gap: "35px",
//             }}
//           >
//             <Box sx={{ position: "relative" }}>
//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   // className="firstDot"
//                   sx={{ width: "5px", height: "5px", color: "#060C16" }}
//                 />

//                 <Typography
//                   // className="firstNo"
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#3B3C44",
//                   }}
//                 >
//                   1
//                 </Typography>
//               </Box>

//               <Box sx={{ position: "absolute" }}>
//                 {" "}
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="116"
//                   height="12"
//                   viewBox="0 0 116 12"
//                   fill="none"
//                 >
//                   <path
//                     d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
//                     fill="white"
//                   />
//                 </svg>
//               </Box>
//             </Box>

//             <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//               <FiberManualRecordIcon
//                 sx={{ width: "5px", height: "5px", color: "#fff" }}
//               />

//               <Typography
//                 sx={{
//                   fontSize: "24px",
//                   fontWeight: 600,
//                   textTransform: "uppercase",
//                   letterSpacing: "32px",
//                   color: "#fff",
//                 }}
//               >
//                 2
//               </Typography>
//             </Box>

//             <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//               <FiberManualRecordIcon
//                 sx={{ width: "5px", height: "5px", color: "#060C16" }}
//               />

//               <Typography
//                 sx={{
//                   fontSize: "24px",
//                   fontWeight: 600,
//                   textTransform: "uppercase",
//                   letterSpacing: "32px",
//                   color: "#3B3C44",
//                 }}
//               >
//                 3
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//         {/* 3*/}
//         <Box
//           component={motion.div}
//           initial={{ x: 500, opacity: 0 }}
//           animate={inView ? { x: 0, opacity: 1 } : { x: 500, opacity: 0 }}
//           transition={{ duration: 1 }}
//           className="wherefirstParentBox"
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             gap: "50px",
//             mt: 2,
//             mr: "40%",
//             "&:hover": {
//               ml: "40%",
//             },
//           }}
//           onMouseEnter={() => {
//             setIsHovered(false);
//             setIsHoveredEffi(false);
//             setIsHoveredSec(true);
//           }}
//           onMouseLeave={() => setIsHoveredSec(false)}
//         >
//           {isHoveredSec ? (
//             <Typewriter text="Sharing" delay={200} infinite />
//           ) : (
//             <Typography
//               className="whyAndWhereHeading"
//               sx={{
//                 fontSize: { xs: "50px", sm: "100px", lg: "133px" },
//                 fontWeight: 700,
//                 textTransform: "uppercase",
//                 color: "#3B3C44",
//               }}
//             >
//               Sharing
//             </Typography>
//           )}

//           <Box
//             className="numbersBox"
//             sx={{
//               display: "none",
//               alignItems: "center",
//               gap: "35px",
//             }}
//           >
//             <Box sx={{ position: "relative" }}>
//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   sx={{ width: "5px", height: "5px", color: "#060C16" }}
//                 />

//                 <Typography
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#3B3C44",
//                   }}
//                 >
//                   1
//                 </Typography>
//               </Box>

//               <Box sx={{ position: "absolute" }}>
//                 {" "}
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="116"
//                   height="12"
//                   viewBox="0 0 116 12"
//                   fill="none"
//                 >
//                   <path
//                     d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
//                     fill="white"
//                   />
//                 </svg>
//               </Box>
//             </Box>
//             <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//               <FiberManualRecordIcon
//                 sx={{ width: "5px", height: "5px", color: "#060C16" }}
//               />

//               <Typography
//                 sx={{
//                   fontSize: "24px",
//                   fontWeight: 600,
//                   textTransform: "uppercase",
//                   letterSpacing: "32px",
//                   color: "#3B3C44",
//                 }}
//               >
//                 2
//               </Typography>
//             </Box>

//             <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//               <FiberManualRecordIcon
//                 sx={{ width: "5px", height: "5px", color: "#fff" }}
//               />

//               <Typography
//                 sx={{
//                   fontSize: "24px",
//                   fontWeight: 600,
//                   textTransform: "uppercase",
//                   letterSpacing: "32px",
//                   color: "#fff",
//                 }}
//               >
//                 3
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default WhereSection;

import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Typewriter from "./Typewriter";

const WhereSection = ({ handleToggle }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredEffi, setIsHoveredEffi] = useState(false);
  const [isHoveredSec, setIsHoveredSec] = useState(false);
  const headingControls = useAnimation();
  const buttonBoxControls = useAnimation();

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      headingControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.1, duration: 0.5 },
      });
      buttonBoxControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.9, duration: 0.9 },
      });
    } else {
      headingControls.start({ opacity: 0, y: 20 });
      buttonBoxControls.start({ opacity: 0, y: 20 });
    }
  }, [inView, headingControls, buttonBoxControls]);

  return (
    <Box
      width={"100%"}
      ref={ref}
      // sx={{ background: "#060C16" }}
      component={motion.div}
      initial={{ backgroundPosition: "100% 0", x: 300, opacity: 0 }}
      animate={inView ? { backgroundPosition: "0% 0", x: 0, opacity: 1 } : {}}
      transition={{ duration: 0.8, ease: "easeOut" }}
      sx={{
        width: "100%",
        background: "#060C16",
        backgroundSize: "200% 100%",
        overflow: "hidden",
        p: "5% 0px",
        position: "relative",
      }}
    >
      {/* Why and Where text clickble */}
      {/* <motion.div initial={{ opacity: 0, y: 20 }} animate={buttonBoxControls}>
        <Button
          onClick={handleToggle}
          variant="outlined"
          sx={{
            position: "absolute",
            right: { xs: "20px", sm: "20px", lg: "50px" },
            textTransform: "uppercase",
            color: "#fff",
            fontWeight: 600,
            letterSpacing: { xs: "2px", sm: "2px", lg: "5px" },
            cursor: "pointer",
            zIndex: 999,
            transition: "all 0.3s ease",
            border: "1px solid #fff",
            borderRadius: "82px",
            p: { xs: "4px 10px", sm: "5px 12px", md: "6px 16px" },
            "&:hover": {
              color: "#fff",
            },
          }}
        >
          Why ?
        </Button>
      </motion.div> */}

      {/* Heading */}
      <motion.div
        style={{ textAlign: "center" }}
        initial={{ opacity: 0, y: 20 }}
        animate={headingControls}
        whileHover={{
          scale: 1.2,
          transition: { duration: 0.4 },
        }}
      >
        <Typography
          className="where-heading"
          sx={{
            fontSize: { xs: "16px", sm: "28px", lg: "36px" },
            display: "inline-block",
            letterSpacing: { xs: "2px", sm: "9px", md: "12.48px" },
            lineHeight: { xs: "30px", sm: "35px", md: "39px" },
            fontWeight: 500,
          }}
        >
          How To USE This ?
        </Typography>
      </motion.div>

      <Box
        sx={{
          width: "90%",
          margin: "auto",
          mt: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* 1 */}
        <Box
          component={motion.div}
          initial={{ x: 500, opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: 500, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="wherefirstParentBox"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "20px", sm: "40px", lg: "50px" },
            ml: "25%",
            "&:hover": {
              mr: "25%",
            },
          }}
        >
          <div class="hover-text-container1">
            <span class="hover-text1" id="hoverText1">
              <span class="character1">B</span>
              <span class="character1">o</span>
              <span class="character1">o</span>
              <span class="character1">s</span>
              <span class="character1">t</span>
              <span class="character1">i</span>
              <span class="character1">n</span>
              <span class="character1">g</span>
            </span>
          </div>
          <Box
            className="numbersBox"
            sx={{
              display: "none",
              alignItems: "center",
              gap: "35px",
            }}
          >
            <Box sx={{ position: "relative" }}>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  className="firstDot"
                  sx={{ width: "5px", height: "5px", color: "#fff" }}
                /> */}

              <Typography
                className="firstNo"
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#BABABA",
                }}
              >
                1
              </Typography>
              {/* </Box> */}

              <Box sx={{ position: "absolute" }}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="116"
                  height="12"
                  viewBox="0 0 116 12"
                  fill="none"
                >
                  <path
                    d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
                    fill="white"
                  />
                </svg>
              </Box>
            </Box>

            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FiberManualRecordIcon
                sx={{ width: "5px", height: "5px", color: "#060C16" }}
              /> */}

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "32px",
                color: "#3B3C44",
              }}
            >
              2
            </Typography>
            {/* </Box> */}

            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FiberManualRecordIcon
                sx={{ width: "5px", height: "5px", color: "#060C16" }}
              /> */}

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "32px",
                color: "#3B3C44",
              }}
            >
              3
            </Typography>
            {/* </Box> */}
          </Box>
        </Box>
        {/* 2*/}
        <Box
          component={motion.div}
          initial={{ x: 500, opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: 500, opacity: 0 }}
          transition={{ duration: 0.7 }}
          className="wherefirstParentBox"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "20px", sm: "40px", lg: "50px" },
            mt: 2,
          }}
        >
          <div class="hover-text-container1">
            <span class="hover-text1" id="hoverText1">
              <span class="character1">m</span>
              <span class="character1">a</span>
              <span class="character1">r</span>
              <span class="character1">k</span>
              <span class="character1">e</span>
              <span class="character1">t</span>
              <span class="character1">i</span>
              <span class="character1">n</span>
              <span class="character1">g</span>
            </span>
          </div>
          <Box
            className="numbersBox"
            sx={{
              display: "none",
              alignItems: "center",
              gap: "35px",
            }}
          >
            <Box sx={{ position: "relative" }}>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  sx={{ width: "5px", height: "5px", color: "#060C16" }}
                /> */}

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#3B3C44",
                }}
              >
                1
              </Typography>
              {/* </Box> */}

              <Box sx={{ position: "absolute" }}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="116"
                  height="12"
                  viewBox="0 0 116 12"
                  fill="none"
                >
                  <path
                    d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
                    fill="white"
                  />
                </svg>
              </Box>
            </Box>

            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FiberManualRecordIcon
                sx={{ width: "5px", height: "5px", color: "#fff" }}
              /> */}

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "32px",
                color: "#fff",
              }}
            >
              2
            </Typography>
            {/* </Box> */}

            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FiberManualRecordIcon
                sx={{ width: "5px", height: "5px", color: "#060C16" }}
              /> */}

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "32px",
                color: "#3B3C44",
              }}
            >
              3
            </Typography>
            {/* </Box> */}
          </Box>
        </Box>
        {/* 3*/}
        <Box
          component={motion.div}
          initial={{ x: 500, opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: 500, opacity: 0 }}
          transition={{ duration: 1 }}
          className="wherefirstParentBox"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "20px", sm: "40px", lg: "50px" },
            mt: 2,
            mr: "40%",
            "&:hover": {
              ml: "40%",
            },
          }}
        >
          <div class="hover-text-container1">
            <span class="hover-text1" id="hoverText1">
              <span class="character1">S</span>
              <span class="character1">h</span>
              <span class="character1">a</span>
              <span class="character1">r</span>
              <span class="character1">i</span>
              <span class="character1">n</span>
              <span class="character1">g</span>
            </span>
          </div>

          <Box
            className="numbersBox"
            sx={{
              display: "none",
              alignItems: "center",
              gap: "35px",
            }}
          >
            <Box sx={{ position: "relative" }}>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  sx={{ width: "5px", height: "5px", color: "#060C16" }}
                /> */}

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#3B3C44",
                }}
              >
                1
              </Typography>
              {/* </Box> */}

              <Box sx={{ position: "absolute" }}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="116"
                  height="12"
                  viewBox="0 0 116 12"
                  fill="none"
                >
                  <path
                    d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
                    fill="white"
                  />
                </svg>
              </Box>
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FiberManualRecordIcon
                sx={{ width: "5px", height: "5px", color: "#060C16" }}
              /> */}

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "32px",
                color: "#3B3C44",
              }}
            >
              2
            </Typography>
            {/* </Box> */}

            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FiberManualRecordIcon
                sx={{ width: "5px", height: "5px", color: "#fff" }}
              /> */}

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "32px",
                color: "#fff",
              }}
            >
              3
            </Typography>
            {/* </Box> */}
          </Box>
        </Box>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={buttonBoxControls}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <Button
            onClick={handleToggle}
            variant="outlined"
            sx={{
              fontSize: "17px",
              textTransform: "uppercase",
              color: "#BFBFBF",
              fontWeight: 600,
              letterSpacing: { xs: "2px", sm: "2px", lg: "5px" },
              cursor: "pointer",
              transition: "all 0.3s ease",
              border: "1px solid #fff",
              borderRadius: "82px",
              color: "#5D42FF",
              background: "#fff",
              width: "162.207px",
              height: "58px",
              "&:hover": {
                color: "#5D42FF",
                background: "#fff",
                transform: "scale(1.05)",
              },
            }}
          >
            WHY ?
          </Button>
        </motion.div>
      </Box>
    </Box>
  );
};

export default WhereSection;
