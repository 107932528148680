import React from "react";
import { Box } from "@mui/material";
import FeaturesCards from "./FeaturesCards";
import "./FeaturesSection.css";
import MultiLineMarquee from "./MultiLineMarquee";
import SingleLineMarquee from "./SingleLineMarquee";

const FeaturesSection = () => {
  return (
    <Box
      id="features"
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          m: "auto",
          p: "5% 0px",
          // height: { xs: "400px", sm: "844px", md: "844px", lg: "844px" },
          // overflowY: "scroll",
          // overflowX: "hidden",
          // scrollbarWidth: "none",
        }}
      >
        {/* <Box>
          <SingleLineMarquee />
        </Box> */}
        {/* <Box>
          <MultiLineMarquee />
        </Box> */}
        <Box>
          <FeaturesCards />
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturesSection;
