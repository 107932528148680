// import React, { useState } from "react";
// import {
//   Box,
//   RangeSlider,
//   RangeSliderTrack,
//   RangeSliderFilledTrack,
//   RangeSliderThumb,
//   Text,
// } from "@chakra-ui/react";
// import { uid } from "uid";

// function DateRangeSlider({ resetKey, sliderValues, setSliderValues,sliderVal, setSliderVal }) {

//   const [sliderMin] = useState(new Date('2022.01.01').getTime() / 1000);
//   const [sliderMax] = useState(new Date().getTime() / 1000);

//   const updateInputValue = (values) => {
//     const startDate = new Date(values[0] * 1000);
//     const endDate = new Date(values[1] * 1000);
//     let str1 = ((startDate + "").split(" "))
//     let startDateStr = str1[2] + "-" + str1[1] + "-" + str1[3]
//     let str2 = ((endDate + "").split(" "))
//     let lastDateStr = str2[2] + "-" + str2[1] + "-" + str2[3]
//     const inputValue = `${startDateStr} - ${lastDateStr}`;
//     return inputValue;
//   };

//   const handleSliderChange = (values) => {
//     setSliderVal(values)
//     setSliderValues(values);
//   };

//   return (
//     <Box p="4">
//       <Text mb="2" fontWeight="semibold" fontSize="sm">
//         Select a date range:
//       </Text>
//       <RangeSlider
//         key={resetKey}
//         min={sliderMin}
//         max={sliderMax}
//         step={86400}
//         value={sliderVal}
//         onChange={(values) => handleSliderChange(values)}
//         w={"180px"}
//       >
//         <RangeSliderTrack>
//           <RangeSliderFilledTrack />
//         </RangeSliderTrack>
//         <RangeSliderThumb index={0} />
//         <RangeSliderThumb index={1} />
//       </RangeSlider>
//       <Text fontSize={"14px"} mt="2">{updateInputValue(sliderValues)}</Text>
//     </Box>
//   );
// }

// export default DateRangeSlider;

import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function DateRangeSlider({
  resetKey,
  sliderValues,
  setSliderValues,
  sliderVal,
  setSliderVal,
  theme,
}) {
  const [sliderMin] = useState(new Date("2022.01.01").getTime() / 1000);
  const [sliderMax] = useState(new Date().getTime() / 1000);

  // Date range state for the calendar
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date("2021.12.01"),
    endDate: new Date("2022.09.01"),
    key: "selection",
  });

  useEffect(() => {
    if (!sliderVal || sliderVal.length === 0) {
      const defaultStart = new Date("2021.12.01").getTime() / 1000;
      const defaultEnd = new Date("2022.09.01").getTime() / 1000;
      setSliderVal([defaultStart, defaultEnd]);
      setSliderValues([defaultStart, defaultEnd]);
    }
  }, [setSliderVal, setSliderValues, sliderVal]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "short" }; // Format like "Dec 2021"
    return date.toLocaleDateString("en-US", options);
  };

  const updateInputValue = (values) => {
    const startDate = formatDate(values[0]);
    const endDate = formatDate(values[1]);
    return `${startDate} - ${endDate}`;
  };

  const handleCalendarSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const newStart = Math.floor(startDate.getTime() / 1000);
    const newEnd = Math.floor(endDate.getTime() / 1000);
    setSliderVal([newStart, newEnd]);
    setSliderValues([newStart, newEnd]);
  };

  return (
    <Box display="flex" alignItems="center" gap="20px">
      <Text
        fontWeight="500"
        fontSize="14px"
        color={theme === "light" ? "#060C16" : "white"}
      >
        Select a date range :
      </Text>

      {/* Display selected date range */}
      <Text
        fontSize={"14px"}
        display="inline-block"
        color={theme === "light" ? "#9595C0" : "#706FB8"}
      >
        {updateInputValue(sliderValues)}
      </Text>

      {/* Calendar icon trigger */}
      <Popover>
        <PopoverTrigger>
          <IconButton
            isRound={true}
            aria-label="Select date range"
            icon={
              <CalendarIcon
                sx={{
                  width: "17px",
                  height: "17px",
                  color: theme === "light" ? "#9595C0" : "#706FB8",
                }}
              />
            }
            variant="outline"
            size="sm"
            border="none"
            _hover={{ bg: "white" }}
          />
        </PopoverTrigger>
        <PopoverContent sx={{ boxShadow: 1 }}>
          <PopoverBody>
            <DateRange
              editableDateInputs={true}
              onChange={(ranges) => handleCalendarSelect(ranges)}
              moveRangeOnFirstSelection={false}
              ranges={[selectedRange]}
              maxDate={new Date()}
              minDate={new Date("2022.01.01")}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default DateRangeSlider;
