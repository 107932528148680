import React from "react";
import {
  Box,
  Popover,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link } from "react-router-dom";

const menuItems = [
  { label: "Login", xsOnly: true },
  { label: "Register", xsOnly: true },
  { label: "WHY & How to use", xsOnly: false, href: "#why-how" },
  { label: "Interactive Demo", xsOnly: false, href: "#demo" },
  { label: "features", xsOnly: false, href: "#features" },
  { label: "testimonials", xsOnly: false, href: "#testimonials" },
  { label: "faq", xsOnly: false, href: "#faqs" },
];

const MenuPopover = ({ openMenu, handleCloseMenu, openPopover, id }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"));

  const handleMenuItemClick = (href) => {
    document.querySelector(href)?.scrollIntoView({ behavior: "smooth" });
    handleCloseMenu();
  };

  return (
    <Box>
      {openPopover && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
          onClick={handleCloseMenu}
        ></Box>
      )}
      <Popover
        id={id}
        open={openPopover}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: { zIndex: 1100 },
        }}
        sx={{
          borderRadius: "50px",
          mt: "20px",
          "& .MuiPaper-root": {
            borderRadius: "70px",
            p: "25px 40px 25px 25px",
            background: "#FFF",
            width: { xs: "100%", sm: "460px", lg: "460px" },
            background:
              "linear-gradient(148deg, rgba(6, 12, 22, 0.70) 10.05%, rgba(0, 16, 41, 0.70) 101.51%)",
            boxShadow: "-49px 54px 100px 0px rgba(0, 6, 15, 0.25)",
            backdropFilter: "blur(30.149999618530273px)",
          },
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <IconButton
              size="small"
              onClick={handleCloseMenu}
              sx={{
                color: "#fff",
                "&:hover": {
                  color: "#060C16",
                  background: "#AEA9BA",
                },
              }}
            >
              <CloseIcon sx={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Box>

          <Box sx={{ mt: 2 }}>
            {menuItems
              .filter((item) => isXsScreen || !item.xsOnly)
              .map((item, index) => (
                <Box
                  component={
                    item.label === "Login" || item.label === "Register"
                      ? Link
                      : "div"
                  }
                  to={
                    item.label === "Login"
                      ? "/client/login"
                      : "/client/register"
                  }
                  key={index}
                  className="menu-item-box"
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "25px",
                    cursor: "pointer",
                    mb: "20px",
                  }}
                  onClick={() => handleMenuItemClick(item.href)}
                >
                  <FiberManualRecordIcon
                    className="dot"
                    sx={{ width: "12px", height: "12px" }}
                  />

                  <Typography
                    // component="a"
                    // href={item.href}
                    className="menu-item-text"
                    sx={{
                      color: "#B4C0FF",
                      lineHeight: "39px",
                      textTransform: "uppercase",
                      fontSize: "14px",
                      letterSpacing: "5.28px",
                      fontWeight: 550,
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default MenuPopover;
