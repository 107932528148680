// import React, { useEffect, useState } from "react";
// import { Box, Button, Typography } from "@mui/material";
// import { motion, useAnimation } from "framer-motion";
// import { useInView } from "react-intersection-observer";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import Typewriter from "./Typewriter";

// const WhySection = ({ handleToggle }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isHoveredEffi, setIsHoveredEffi] = useState(false);
//   const [isHoveredSec, setIsHoveredSec] = useState(false);
//   const headingControls = useAnimation();
//   const firstBoxControls = useAnimation();
//   const secondBoxControls = useAnimation();
//   const threedBoxControls = useAnimation();

//   const { ref, inView } = useInView({
//     triggerOnce: false,
//     threshold: 0.4,
//   });

//   useEffect(() => {
//     if (inView) {
//       headingControls.start({
//         opacity: 1,
//         y: 0,
//         transition: { delay: 0.1, duration: 0.5 },
//       });
//       firstBoxControls.start({
//         opacity: 1,
//         y: 0,
//         transition: { delay: 0.5, duration: 0.7 },
//       });
//       secondBoxControls.start({
//         opacity: 1,
//         y: 0,
//         transition: { delay: 0.9, duration: 1 },
//       });
//       threedBoxControls.start({
//         opacity: 1,
//         y: 0,
//         transition: { delay: 1, duration: 1.5 },
//       });
//     } else {
//       headingControls.start({ opacity: 0, y: 20 });
//       firstBoxControls.start({ opacity: 0, y: 20 });
//       secondBoxControls.start({ opacity: 0, y: 20 });
//       threedBoxControls.start({ opacity: 0, y: 20 });
//     }
//   }, [
//     inView,
//     headingControls,
//     firstBoxControls,
//     secondBoxControls,
//     threedBoxControls,
//   ]);

//   return (
//     <Box width={"100%"} ref={ref}>
//       <Box
//         sx={{
//           p: "5% 0px",
//           position: "relative",
//         }}
//       >
//         {/* Where buttton*/}
//         {/* <Box
//           sx={{
//             width: "100%",
//               position: "absolute",
//               right: { xs: "20px", sm: "30px", md: "50px" },
//             display: "flex",
//             justifyContent: "flex-end",
//             mr: "50px",
//           }}
//         > */}
//         <Button
//           onClick={handleToggle}
//           variant="outlined"
//           sx={{
//             position: "absolute",
//             right: { xs: "20px", sm: "20px", lg: "50px" },
//             textTransform: "uppercase",
//             color: "#BFBFBF",
//             fontWeight: 600,
//             letterSpacing: { xs: "2px", sm: "2px", md: "8px" },
//             cursor: "pointer",
//             zIndex: 999,
//             transition: "all 0.3s ease",
//             border: "1px solid #B1AFD8",
//             borderRadius: "82px",
//             color: "#2400FF",
//             p: { xs: "4px 10px", sm: "5px 12px", md: "6px 16px" },
//             "&:hover": {
//               color: "#2404e0",
//             },
//           }}
//         >
//           Where ?
//         </Button>
//         {/* </Box> */}

//         {/* Heading */}
//         <motion.div
//           style={{ textAlign: "center" }}
//           initial={{ opacity: 0, y: 20 }}
//           animate={headingControls}
//           whileHover={{
//             scale: 1.2,
//             transition: { duration: 0.4 },
//           }}
//         >
//           <Typography
//             className="why-heading"
//             sx={{
//               fontSize: { xs: "16px", sm: "28px", lg: "36px" },
//               display: "inline-block",
//               letterSpacing: { xs: "2px", sm: "9px", md: "12.48px" },
//               lineHeight: { xs: "30px", sm: "35px", md: "39px" },
//               fontWeight: 500,
//             }}
//           >
//             WHY USE THIS product ?
//           </Typography>
//         </motion.div>

//         <Box
//           sx={{
//             width: "90%",
//             margin: "auto",
//             mt: "5%",
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           {/* 1 */}
//           <Box
//             initial={{ opacity: 0, y: 20 }}
//             animate={firstBoxControls}
//             component={motion.div}
//             className="firstParentBox"
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               gap: "50px",
//             }}
//             onMouseEnter={() => {
//               setIsHovered(true);
//               setIsHoveredEffi(false);
//               setIsHoveredSec(false);
//             }}
//             onMouseLeave={() => setIsHovered(false)}
//           >
//             {isHovered ? (
//               <Typewriter text="Simple" delay={200} infinite />
//             ) : (
//               <Typography
//                 className="whyAndWhereHeading"
//                 sx={{
//                   fontSize: { xs: "50px", sm: "100px", lg: "133px" },
//                   fontWeight: 700,
//                   textTransform: "uppercase",
//                   color: "#DBDBDB",
//                 }}
//               >
//                 Simple
//               </Typography>
//             )}

//             <Box
//               className="numbersBox"
//               sx={{
//                 display: "none",
//                 alignItems: "center",
//                 gap: "35px",
//               }}
//             >
//               <Box sx={{ position: "relative" }}>
//                 <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                   <FiberManualRecordIcon
//                     className="firstDot"
//                     sx={{ width: "5px", height: "5px", color: "#fff" }}
//                   />

//                   <Typography
//                     className="firstNo"
//                     sx={{
//                       fontSize: "24px",
//                       fontWeight: 600,
//                       textTransform: "uppercase",
//                       letterSpacing: "32px",
//                       color: "#BABABA",
//                     }}
//                   >
//                     1
//                   </Typography>
//                 </Box>

//                 <Box sx={{ position: "absolute" }}>
//                   {" "}
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="116"
//                     height="12"
//                     viewBox="0 0 116 12"
//                     fill="none"
//                   >
//                     <path
//                       d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
//                       fill="#060C16"
//                     />
//                   </svg>
//                 </Box>
//               </Box>

//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   sx={{ width: "5px", height: "5px", color: "#fff" }}
//                 />

//                 <Typography
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#BABABA",
//                   }}
//                 >
//                   2
//                 </Typography>
//               </Box>

//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   sx={{ width: "5px", height: "5px", color: "#fff" }}
//                 />

//                 <Typography
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#BABABA",
//                   }}
//                 >
//                   3
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//           {/* 2*/}
//           <Box
//             initial={{ opacity: 0, y: 20 }}
//             animate={secondBoxControls}
//             component={motion.div}
//             className="secondParentBox"
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               gap: "50px",
//               mt: 2,
//               ml: "30%",
//               "&:hover": {
//                 ml: "25%",
//               },
//             }}
//             onMouseEnter={() => {
//               setIsHovered(false);
//               setIsHoveredEffi(true);
//               setIsHoveredSec(false);
//             }}
//             onMouseLeave={() => setIsHoveredEffi(false)}
//           >
//             {isHoveredEffi ? (
//               <Typewriter text="Efficient" delay={200} infinite />
//             ) : (
//               <Typography
//                 className="whyAndWhereHeading"
//                 sx={{
//                   fontSize: { xs: "50px", sm: "100px", lg: "133px" },
//                   fontWeight: 700,
//                   textTransform: "uppercase",
//                   color: "#DBDBDB",
//                 }}
//               >
//                 Efficient
//               </Typography>
//             )}

//             <Box
//               className="numbersBox"
//               sx={{
//                 display: "none",
//                 alignItems: "center",
//                 gap: "35px",
//               }}
//             >
//               <Box sx={{ position: "relative" }}>
//                 <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                   <FiberManualRecordIcon
//                     // className="firstDot"
//                     sx={{ width: "5px", height: "5px", color: "#fff" }}
//                   />

//                   <Typography
//                     // className="firstNo"
//                     sx={{
//                       fontSize: "24px",
//                       fontWeight: 600,
//                       textTransform: "uppercase",
//                       letterSpacing: "32px",
//                       color: "#BABABA",
//                     }}
//                   >
//                     1
//                   </Typography>
//                 </Box>

//                 <Box sx={{ position: "absolute" }}>
//                   {" "}
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="116"
//                     height="12"
//                     viewBox="0 0 116 12"
//                     fill="none"
//                   >
//                     <path
//                       d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
//                       fill="#060C16"
//                     />
//                   </svg>
//                 </Box>
//               </Box>
//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   sx={{ width: "5px", height: "5px", color: "#060c16" }}
//                 />

//                 <Typography
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#060c16",
//                   }}
//                 >
//                   2
//                 </Typography>
//               </Box>
//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   sx={{ width: "5px", height: "5px", color: "#fff" }}
//                 />

//                 <Typography
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#DBDBDB",
//                   }}
//                 >
//                   3
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//           {/* 3*/}
//           <Box
//             initial={{ opacity: 0, y: 20 }}
//             animate={threedBoxControls}
//             component={motion.div}
//             className="firstParentBox"
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               gap: "50px",
//               mt: 2,
//               mr: "30%",
//               "&:hover": {
//                 mr: "10%",
//               },
//             }}
//             onMouseEnter={() => {
//               setIsHovered(false);
//               setIsHoveredEffi(false);
//               setIsHoveredSec(true);
//             }}
//             onMouseLeave={() => setIsHoveredSec(false)}
//           >
//             {isHoveredSec ? (
//               <Typewriter text="Secure" delay={200} infinite />
//             ) : (
//               <Typography
//                 className="whyAndWhereHeading"
//                 sx={{
//                   fontSize: { xs: "50px", sm: "100px", lg: "133px" },
//                   fontWeight: 700,
//                   textTransform: "uppercase",
//                   color: "#DBDBDB",
//                 }}
//               >
//                 Secure
//               </Typography>
//             )}

//             <Box
//               className="numbersBox"
//               sx={{
//                 display: "none",
//                 alignItems: "center",
//                 gap: "35px",
//               }}
//             >
//               <Box sx={{ position: "relative" }}>
//                 <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                   <FiberManualRecordIcon
//                     // className="firstDot"
//                     sx={{ width: "5px", height: "5px", color: "#fff" }}
//                   />

//                   <Typography
//                     // className="firstNo"
//                     sx={{
//                       fontSize: "24px",
//                       fontWeight: 600,
//                       textTransform: "uppercase",
//                       letterSpacing: "32px",
//                       color: "#BABABA",
//                     }}
//                   >
//                     1
//                   </Typography>
//                 </Box>

//                 <Box sx={{ position: "absolute" }}>
//                   {" "}
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="116"
//                     height="12"
//                     viewBox="0 0 116 12"
//                     fill="none"
//                   >
//                     <path
//                       d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
//                       fill="#060C16"
//                     />
//                   </svg>
//                 </Box>
//               </Box>
//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   sx={{ width: "5px", height: "5px", color: "#fff" }}
//                 />

//                 <Typography
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#BABABA",
//                   }}
//                 >
//                   2
//                 </Typography>
//               </Box>
//               <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                 <FiberManualRecordIcon
//                   sx={{ width: "5px", height: "5px", color: "#060c16" }}
//                 />

//                 <Typography
//                   sx={{
//                     fontSize: "24px",
//                     fontWeight: 600,
//                     textTransform: "uppercase",
//                     letterSpacing: "32px",
//                     color: "#060c16",
//                   }}
//                 >
//                   3
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default WhySection;

// Try something new
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Typewriter from "./Typewriter";

const WhySection = ({ handleToggle }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredEffi, setIsHoveredEffi] = useState(false);
  const [isHoveredSec, setIsHoveredSec] = useState(false);
  const headingControls = useAnimation();
  const firstBoxControls = useAnimation();
  const secondBoxControls = useAnimation();
  const threedBoxControls = useAnimation();
  const buttonBoxControls = useAnimation();

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      headingControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.1, duration: 0.5 },
      });
      firstBoxControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.5, duration: 0.7 },
      });
      secondBoxControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.9, duration: 1 },
      });
      threedBoxControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 1, duration: 1.5 },
      });
      buttonBoxControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 1.5, duration: 1 },
      });
    } else {
      headingControls.start({ opacity: 0, y: 20 });
      firstBoxControls.start({ opacity: 0, y: 20 });
      secondBoxControls.start({ opacity: 0, y: 20 });
      threedBoxControls.start({ opacity: 0, y: 20 });
      buttonBoxControls.start({ opacity: 0, y: 20 });
    }
  }, [
    inView,
    headingControls,
    firstBoxControls,
    secondBoxControls,
    threedBoxControls,
    buttonBoxControls,
  ]);

  return (
    <Box width={"100%"} ref={ref}>
      <Box
        sx={{
          p: "5% 0px",
          position: "relative",
        }}
      >
        {/* <motion.div initial={{ opacity: 0, y: 20 }} animate={buttonBoxControls}>
          <Button
            onClick={handleToggle}
            variant="outlined"
            sx={{
              position: "absolute",
              right: { xs: "20px", sm: "20px", lg: "50px" },
              textTransform: "uppercase",
              color: "#BFBFBF",
              fontWeight: 600,
              letterSpacing: { xs: "2px", sm: "2px", lg: "5px" },
              cursor: "pointer",
              zIndex: 999,
              transition: "all 0.3s ease",
              border: "1px solid #B1AFD8",
              borderRadius: "82px",
              color: "#2400FF",
              p: { xs: "4px 10px", sm: "5px 12px", md: "6px 16px" },
              "&:hover": {
                color: "#2404e0",
              },
            }}
          >
            How ?
          </Button>
        </motion.div> */}
        {/* </Box> */}

        {/* Heading */}
        <motion.div
          style={{ textAlign: "center" }}
          initial={{ opacity: 0, y: 20 }}
          animate={headingControls}
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.4 },
          }}
        >
          <Typography
            className="why-heading"
            sx={{
              fontSize: { xs: "16px", sm: "28px", lg: "36px" },
              display: "inline-block",
              letterSpacing: { xs: "2px", sm: "9px", md: "12.48px" },
              lineHeight: { xs: "30px", sm: "35px", md: "39px" },
              fontWeight: 500,
            }}
          >
            WHY USE THIS ?
          </Typography>
        </motion.div>

        <Box
          sx={{
            width: "90%",
            margin: "auto",
            mt: "5%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* 1 */}
          <Box
            initial={{ opacity: 0, y: 20 }}
            animate={firstBoxControls}
            component={motion.div}
            className="firstParentBox"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "20px", sm: "40px", lg: "50px" },
            }}
          >
            <div class="hover-text-container">
              <span class="hover-text" id="hoverText">
                <span class="character">S</span>
                <span class="character">I</span>
                <span class="character">M</span>
                <span class="character">P</span>
                <span class="character">L</span>
                <span class="character">E</span>
              </span>
            </div>
            <Box
              className="numbersBox"
              sx={{
                display: "none",
                alignItems: "center",
                gap: "35px",
              }}
            >
              <Box sx={{ position: "relative" }}>
                {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <FiberManualRecordIcon
                    className="firstDot"
                    sx={{ width: "5px", height: "5px", color: "#fff" }}
                  /> */}

                <Typography
                  className="firstNo"
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    letterSpacing: "32px",
                    color: "#BABABA",
                  }}
                >
                  1
                </Typography>
                {/* </Box> */}

                <Box sx={{ position: "absolute" }}>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="116"
                    height="12"
                    viewBox="0 0 116 12"
                    fill="none"
                  >
                    <path
                      d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
                      fill="#060C16"
                    />
                  </svg>
                </Box>
              </Box>

              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  sx={{ width: "5px", height: "5px", color: "#fff" }}
                /> */}

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#BABABA",
                }}
              >
                2
              </Typography>
              {/* </Box> */}

              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  sx={{ width: "5px", height: "5px", color: "#fff" }}
                /> */}

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#BABABA",
                }}
              >
                3
              </Typography>
              {/* </Box> */}
            </Box>
          </Box>
          {/* 2*/}
          <Box
            initial={{ opacity: 0, y: 20 }}
            animate={secondBoxControls}
            component={motion.div}
            className="secondParentBox"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "20px", sm: "40px", lg: "50px" },
              mt: 2,
              ml: "30%",
              // ml: { xs: "0%", sm: "30%", lg: "30%" },
              "&:hover": {
                ml: "25%",
                // ml: { xs: "0%", sm: "25%", lg: "25%" },
              },
            }}
          >
            <div class="hover-text-container">
              <span class="hover-text" id="hoverText">
                <span class="character">e</span>
                <span class="character">f</span>
                <span class="character">f</span>
                <span class="character">i</span>
                <span class="character">c</span>
                <span class="character">i</span>
                <span class="character">e</span>
                <span class="character">n</span>
                <span class="character">t</span>
              </span>
            </div>
            <Box
              className="numbersBox"
              sx={{
                display: "none",
                alignItems: "center",
                gap: "35px",
              }}
            >
              <Box sx={{ position: "relative" }}>
                {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <FiberManualRecordIcon
                    sx={{ width: "5px", height: "5px", color: "#fff" }}
                  /> */}

                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    letterSpacing: "32px",
                    color: "#BABABA",
                  }}
                >
                  1
                </Typography>
                {/* </Box> */}

                <Box sx={{ position: "absolute" }}>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="116"
                    height="12"
                    viewBox="0 0 116 12"
                    fill="none"
                  >
                    <path
                      d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
                      fill="#060C16"
                    />
                  </svg>
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  sx={{ width: "5px", height: "5px", color: "#060c16" }}
                /> */}

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#060c16",
                }}
              >
                2
              </Typography>
              {/* </Box> */}
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  sx={{ width: "5px", height: "5px", color: "#fff" }}
                /> */}

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#DBDBDB",
                }}
              >
                3
              </Typography>
              {/* </Box> */}
            </Box>
          </Box>
          {/* 3*/}
          <Box
            initial={{ opacity: 0, y: 20 }}
            animate={threedBoxControls}
            component={motion.div}
            className="firstParentBox"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "20px", sm: "40px", lg: "50px" },
              mt: 2,
              mr: "30%",
              // mr: { xs: "0%", sm: "30%", lg: "30%" },
              "&:hover": {
                mr: "10%",
                // mr: { xs: "0%", sm: "10%", lg: "10%" },
              },
            }}
          >
            <div class="hover-text-container">
              <span class="hover-text" id="hoverText">
                <span class="character">S</span>
                <span class="character">e</span>
                <span class="character">c</span>
                <span class="character">u</span>
                <span class="character">r</span>
                <span class="character">e</span>
              </span>
            </div>
            <Box
              className="numbersBox"
              sx={{
                display: "none",
                alignItems: "center",
                gap: "35px",
              }}
            >
              <Box sx={{ position: "relative" }}>
                {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <FiberManualRecordIcon
                    sx={{ width: "5px", height: "5px", color: "#fff" }}
                  /> */}

                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    letterSpacing: "32px",
                    color: "#BABABA",
                  }}
                >
                  1
                </Typography>
                {/* </Box> */}

                <Box sx={{ position: "absolute" }}>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="116"
                    height="12"
                    viewBox="0 0 116 12"
                    fill="none"
                  >
                    <path
                      d="M1 5.3125C0.620304 5.3125 0.3125 5.6203 0.3125 6C0.3125 6.3797 0.620304 6.6875 1 6.6875V5.3125ZM115.486 6.48614C115.755 6.21765 115.755 5.78235 115.486 5.51386L111.111 1.13864C110.842 0.870155 110.407 0.870155 110.139 1.13864C109.87 1.40713 109.87 1.84243 110.139 2.11091L114.028 6L110.139 9.88909C109.87 10.1576 109.87 10.5929 110.139 10.8614C110.407 11.1298 110.842 11.1298 111.111 10.8614L115.486 6.48614ZM1 6.6875H115V5.3125H1V6.6875Z"
                      fill="#060C16"
                    />
                  </svg>
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  sx={{ width: "5px", height: "5px", color: "#fff" }}
                /> */}

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#BABABA",
                }}
              >
                2
              </Typography>
              {/* </Box> */}
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FiberManualRecordIcon
                  sx={{ width: "5px", height: "5px", color: "#060c16" }}
                /> */}

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  letterSpacing: "32px",
                  color: "#060c16",
                }}
              >
                3
              </Typography>
              {/* </Box> */}
            </Box>
          </Box>{" "}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={buttonBoxControls}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <Button
              onClick={handleToggle}
              variant="outlined"
              sx={{
                fontSize: "17px",
                textTransform: "uppercase",
                color: "#BFBFBF",
                fontWeight: 600,
                letterSpacing: { xs: "2px", sm: "2px", lg: "5px" },
                cursor: "pointer",
                transition: "all 0.3s ease",
                border: "1px solid #B1AFD8",
                borderRadius: "82px",
                color: "#fff",
                background: "#5D42FF",
                width: "162.207px",
                height: "58px",
                "&:hover": {
                  color: "#fff",
                  background: "#4A32E4",
                  transform: "scale(1.05)",
                },
              }}
            >
              How ?
            </Button>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};

export default WhySection;
