import React from "react";
import { Box, Typography, Card, Container } from "@mui/material";
import { motion } from "framer-motion";
import { features } from "../../../Constant";

const FeaturesCards = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        px: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Box
        // sx={{ mt: 5 }}
        component={motion.div}
        initial={{ opacity: 0, x: 500 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            letterSpacing: { xs: "8px", sm: "16px", md: "32.4px" },
            fontSize: { xs: "24px", sm: "30px", md: "36px" },
            fontWeight: 500,
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        >
          OUR FEATURES
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          mt: 6,
        }}
      >
        {features.map((feature, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.3 }}
          >
            <Card
              sx={{
                p: { xs: "20px 30px", sm: "30px 40px", md: "40px 60px" },
                borderRadius: { xs: "20px", lg: "58px" },
                backgroundColor: index % 2 === 0 ? "#0C1B30" : "#F6F7F8",
                height: { xs: "auto", sm: "250px", md: "300px", lg: "400px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 700,
                    color: index % 2 == 0 ? "#fff" : "#060C16",
                    fontSize: {
                      xs: "32px",
                      sm: "36px",
                      md: "76px",
                      lg: "96px",
                    },
                  }}
                >
                  {feature.title}
                </Typography>
                {index % 2 == 0 && (
                  <Typography
                    sx={{
                      textAlign: { xs: "center", md: "start" },
                      fontSize: { xs: "14px", sm: "16px", md: "18px" },
                      width: { xs: "100%", sm: "40%" },
                      color: "#fff",
                      lineHeight: "25px",
                      letterSpacing: "0.18px",
                      color: index % 2 == 0 ? "#fff" : "#000",
                    }}
                  >
                    {feature.description}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "20px",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Typography
                  className={
                    index % 2 == 0
                      ? "featureSubheading"
                      : "featureOddSubheading"
                  }
                  variant="h1"
                  sx={{
                    fontWeight: 700,
                    color: index % 2 == 0 ? "#060C16" : "#fff",
                    fontSize: {
                      xs: "32px",
                      sm: "36px",
                      md: "69px",
                      lg: "96px",
                    },
                  }}
                >
                  {feature.subtitle}
                </Typography>
                {index % 2 !== 0 && (
                  <Typography
                    sx={{
                      textAlign: { xs: "center", md: "start" },
                      fontSize: { xs: "14px", sm: "16px", md: "18px" },
                      width: { xs: "100%", sm: "40%" },
                      color: "#fff",
                      lineHeight: "25px",
                      letterSpacing: "0.18px",
                      color: index % 2 == 0 ? "#fff" : "#000",
                    }}
                  >
                    {feature.description}
                  </Typography>
                )}
              </Box>
            </Card>
          </motion.div>
        ))}
      </Box>
    </Container>
  );
};

export default FeaturesCards;
