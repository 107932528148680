import React from "react";
import { Box } from "@mui/material";
import TestimonialCarousel from "./TestimonialCarousel";
import AnimateTextRightToLeft from "./AnimateTextRightToLeft";
import AnimateTextLeftToRight from "./AnimateTextLeftToRight";
import TryItText from "./TryItText";
import "./TestimonialSection.css";

const TestimonialSection = () => {
  return (
    <Box
      id="testimonials"
      sx={{
        width: "100%",
        p: "5% 0px",
        background: "#060C16",
      }}
    >
      <Box sx={{ mb: "10%" }}>
        <AnimateTextRightToLeft />
        <TryItText />
        <AnimateTextLeftToRight />
      </Box>
      <TestimonialCarousel />
    </Box>
  );
};

export default TestimonialSection;
