import React from "react";
import { Modal, Button, Box, IconButton, Typography } from "@mui/material";
import { MdLocationSearching } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

const filteredClicks = [
  {
    id: 1,
    city: "Kolkata",
    country: "IN",
    latitude: "22.5626",
    longitude: "88.363",
    browser: "Chrome 121.0.0.0",
    os: "Windows 10",
    count: "5",
  },
  {
    id: 2,
    city: "Dibrugarh",
    country: "IN",
    latitude: "28.6014",
    longitude: "77.1989",
    browser: "Chrome Mobile 125.0.0.0",
    os: "Android 10",
    count: "10",
  },
  {
    id: 3,
    city: "Maharashtra",
    country: "IN",
    latitude: "28.6014",
    longitude: "77.1989",
    browser: "Chrome Mobile 120.0.0.0",
    os: "Android 10",
    count: "1",
  },
];

const ClicksModal = ({ isShowClickModal, handleCancel, theme, setMapData }) => {
  // const handleMapClick = () => {
  //   setMapData(temp);
  // };

  const modalContentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "970px",
    background: theme === "light" ? "#fff" : "#23213B",
    borderRadius: "47px",
    boxShadow: 24,
    padding: "40px",
    textAlign: "center",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      width: "70%",
      borderBottom: theme === "light" ? "2px solid #6231F7" : "2px solid #fff",
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  return (
    <Modal
      open={isShowClickModal}
      onClose={handleCancel}
      aria-labelledby="delete-confirmation-title"
      aria-describedby="delete-confirmation-description"
    >
      <Box style={modalContentStyle} className="delete-modal-container">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "25px",
          }}
        >
          <Typography
            sx={{
              color: theme === "light" ? "#060C16" : "#fff",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "25.2px",
            }}
          >
            Clicks Information
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "25px" }}>
            <Link to={"/map"}>
              <Button
                startIcon={<MdLocationSearching size={21} />}
                sx={{
                  background: "#6231F7",
                  color: "#fff",
                  borderRadius: "17px",
                  fontSize: "14px",
                  p: "10px 20px",
                  border: "none",
                  "&:hover": {
                    background: "#4f18f5",
                  },
                }}
                // onClick={handleMapClick}
              >
                Map
              </Button>
            </Link>
            <IconButton
              onClick={handleCancel}
              size={"sm"}
              sx={{
                background: "rgba(255, 255, 255, 0.08)",
                color: theme === "light" ? "#060C16" : "#fff",
              }}
            >
              <IoMdClose size={22} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "10px",
            background: theme === "light" ? "#F8F8FA" : "rgba(0, 6, 39, 0.84)",
            overflow: "hidden",
            p: "10px 20px 20px 20px",
          }}
        >
          {/* Header */}
          <Box
            height={"50px"}
            display={"flex"}
            padding={"0px 10px"}
            background={theme === "light" ? "#F8F8FA" : "rgba(0, 6, 39, 0.84)"}
            color={theme === "light" ? "#060C16" : "#fff"}
            borderBottom={
              theme === "light" ? "1px solid #D7DAFF" : "1px solid #292D54"
            }
            gap={"20px"}
          >
            <Box
              cursor={"default"}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                fontSize: "14px",
                textAlign: "start",
              }}
              width={"13%"}
              letterSpacing={"2px"}
            >
              City
            </Box>
            <Box
              cursor={"default"}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                fontSize: "14px",
                textAlign: "start",
              }}
              width={"15%"}
              letterSpacing={"2px"}
            >
              Country
            </Box>
            <Box
              cursor={"default"}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                fontSize: "14px",
                textAlign: "start",
              }}
              width={"13%"}
              letterSpacing={"2px"}
            >
              Latitude
            </Box>
            <Box
              cursor={"default"}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                fontSize: "14px",
                textAlign: "start",
                width: "13%",
                letterSpacing: "2px",
              }}
            >
              Longitude
            </Box>
            <Box
              cursor={"default"}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                fontSize: "14px",
                textAlign: "start",
                width: "25%",
                letterSpacing: "2px",
              }}
            >
              Browser
            </Box>
            <Box
              cursor={"default"}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                fontSize: "14px",
                textAlign: "start",
                width: "15%",
              }}
              letterSpacing={"2px"}
            >
              OS
            </Box>
            <Box
              cursor={"default"}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                fontSize: "14px",
                textAlign: "start",
                width: "10%",
                letterSpacing: "2px",
              }}
            >
              Count
            </Box>
          </Box>

          {/* Data Rows */}
          {filteredClicks?.map((i, index) => (
            <Box
              key={index}
              height={"50px"}
              display={"flex"}
              borderRadius={"10px"}
              gap={"20px"}
              padding={"0px 10px"}
              sx={{
                backgroundColor:
                  index % 2 !== 0
                    ? theme === "light"
                      ? "#fff"
                      : "rgba(255, 255, 255, 0.05)"
                    : theme === "light"
                    ? "#F8F8FA"
                    : "",
              }}
            >
              <Box
                cursor={"default"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  textAlign: "start",
                }}
                width={"13%"}
              >
                {i.city}
              </Box>
              <Box
                cursor={"default"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  textAlign: "start",
                }}
                width={"15%"}
              >
                {i.country}
              </Box>
              <Box
                cursor={"default"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  textAlign: "start",
                }}
                width={"13%"}
              >
                {i.latitude}
              </Box>
              <Box
                cursor={"default"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  textAlign: "start",
                }}
                width={"13%"}
              >
                {i.longitude}
              </Box>
              <Box
                cursor={"default"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  textAlign: "start",
                }}
                width={"25%"}
              >
                {i.browser}
              </Box>
              <Box
                cursor={"default"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  textAlign: "start",
                }}
                width={"15%"}
              >
                {i.os}
              </Box>
              <Box
                cursor={"default"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  fontSize: "14px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  textAlign: "start",
                }}
                width={"10%"}
              >
                {i.count}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ClicksModal;
