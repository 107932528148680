import { Typography, Box } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";

const AnimateTextRightToLeft = () => {
  return (
    <Box>
      <Marquee style={{ overflowY: "hidden" }} speed={60} direction="left">
        <Typography
          className="autoAnimateText"
          sx={{
            fontSize: {
              xs: "30px",
              sm: "50px",
              md: "100px",
              lg: "200px",
            },
            fontWeight: 500,
            whiteSpace: "nowrap",
          }}
        >
          CUSTOMERCUSTOMER
        </Typography>
      </Marquee>
    </Box>
  );
};

export default AnimateTextRightToLeft;

// import React from "react";
// import { Box, Typography } from "@mui/material";
// import { motion } from "framer-motion";

// const AnimateTextRightToLeft = () => {
//   const scrollVariants = {
//     animate: {
//       x: ["0%", "-100%"],
//       transition: {
//         ease: "linear",
//         duration: 15,
//         repeat: Infinity,
//       },
//     },
//   };

//   return (
//     <>
//       <Box width={"100%"}>
//         <motion.div
//           variants={scrollVariants}
//           animate="animate"
//           style={{
//             width: "100%",
//             display: "flex",
//             alignItems: "center",
//             // gap: "50px",
//             whiteSpace: "nowrap",
//           }}
//         >
//           {/* Add as many items as you want to scroll */}
//           <Box>
//             <Typography
//               className="autoAnimateText"
//               sx={{
//                 fontSize: { xs: "50px", md: "100px", lg: "200px" },
//                 fontWeight: 500,
//               }}
//             >
//               CUSTOMER
//             </Typography>
//           </Box>
//           <Box>
//             <Typography
//               className="autoAnimateText"
//               sx={{
//                 fontSize: { xs: "50px", md: "100px", lg: "200px" },
//                 fontWeight: 500,
//               }}
//             >
//               CUSTOMER
//             </Typography>
//           </Box>

//           {/* Repeat the items to ensure smooth scrolling */}
//           <Box>
//             <Typography
//               className="autoAnimateText"
//               sx={{
//                 fontSize: { xs: "50px", md: "100px", lg: "200px" },
//                 fontWeight: 500,
//               }}
//             >
//               CUSTOMER
//             </Typography>
//           </Box>
//           <Box>
//             <Typography
//               className="autoAnimateText"
//               sx={{
//                 fontSize: { xs: "50px", md: "100px", lg: "200px" },
//                 fontWeight: 500,
//               }}
//             >
//               CUSTOMER
//             </Typography>
//           </Box>
//         </motion.div>
//       </Box>
//     </>
//   );
// };

// export default AnimateTextRightToLeft;
