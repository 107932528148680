import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Modal,
  Box,
  InputAdornment,
  Checkbox,
  IconButton,
  TablePagination,
  Typography,
  PaginationItem,
  Pagination,
  Tooltip,
  Popover,
  MenuItem,
  Select,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import "./InteractiveDemo.css";
import AppBar from "./AppBar";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveIcon from "@mui/icons-material/Remove";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ClicksModal from "./ClicksModal";
import { CiEdit } from "react-icons/ci";
import { MdLanguage } from "react-icons/md";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { GrPowerReset } from "react-icons/gr";
import toast from "react-hot-toast";

const modalContentStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "981px",
  borderRadius: "47px",
  boxShadow: 24,
  padding: "35px 40px",
};

const styles = {
  //   evenRow: {
  //     backgroundColor: "#f7fcf5",
  //   },
  evenRowLight: {},
  oddRow: {
    background: "rgba(255, 255, 255, 0.05)",
  },
  oddRowLight: {
    background: "#F6F6FF",
  },

  lightHoverEffect: {
    "&:hover": {
      backgroundColor: "#F1F1FF",
      transition: "backgroundColor 0.3s ease",
    },
  },

  hoverEffect: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.11)",
      transition: "backgroundColor 0.3s ease",
    },
  },
};

const initialData = [
  {
    id: 1,
    created: "03/07/2022",
    longUrl: "https://example.com",
    alias: "chatgpt",
    shortUrl: "https://cbxit/chatgpt",
    clicks: 0,
    remarks: "Description",
  },
  {
    id: 2,
    created: "04/04/2023",
    longUrl: "https://example.com",
    alias: "meeting",
    shortUrl: "https://cbxit/meeting",
    clicks: 0,
    remarks: "Description",
  },
  {
    id: 3,
    created: "02/04/2023",
    longUrl: "https://example.com",
    alias: "stream",
    shortUrl: "https://cbxit/stream",
    clicks: 0,
    remarks: "Description",
  },
  {
    id: 4,
    created: "07/05/2022",
    longUrl: "https://example.com",
    alias: "email",
    shortUrl: "https://cbxit/email",
    clicks: 0,
    remarks: "Description",
  },
  {
    id: 5,
    created: "04/01/2024",
    longUrl: "https://example.com",
    alias: "meeting",
    shortUrl: "https://cbxit/meeting",
    clicks: 0,
    remarks: "Description",
  },
  {
    id: 6,
    created: "10/02/2024",
    longUrl: "https://example.com",
    alias: "stream",
    shortUrl: "https://cbxit/stream",
    clicks: 16,
    remarks: "Description",
  },
];

// Custom styled pagination
const CustomPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "#999", // Default text color for pagination numbers
    fontWeight: 600,
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "transparent", // Remove background on hover
      color: "#fff", // White text on hover
    },
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "transparent", // No background for selected
    color: "#fff", // White text for selected page
    borderBottom: "2px solid #fff", // Add bottom border for selected page
  },
  "& .MuiPaginationItem-ellipsis": {
    color: "#999", // Grey color for ellipsis
  },
  "& .MuiPaginationItem-icon": {
    color: "#fff", // White color for arrows
  },
});

const LinkTable = ({
  openDashboardModal,
  handleCancel,
  theme,
  setTheme,
  setMapData,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShowClickModal, setIsShowClickModal] = useState(false);
  const [links, setLinks] = useState(initialData);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentLink, setCurrentLink] = useState(null);
  const [itemId, setItemId] = useState(0);
  const [domain, setDomain] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date("2021.12.01"),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection]);
  };

  // ?.sort((a, b) => new Date(b.created) - new Date(a.created))

  const handleOpenDialog = (link = null) => {
    setCurrentLink(link);
    setAlias(link?.alias);
    setLongUrl(link?.longUrl);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentLink(null);
  };

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (!isValidURL(longUrl)) {
      toast.error("Please enter a valid URL.");
      return;
    }

    const formData = new FormData(event.currentTarget);
    const newLink = {
      id: currentLink ? currentLink.id : Date.now(),
      created: new Date().toLocaleDateString(),
      longUrl: formData.get("longUrl"),
      alias: formData.get("alias"),
      shortUrl: `https://cbxit.in/${formData.get("alias") || ""}`,
      clicks: 0,
      remarks: formData.get("remarks"),
    };

    if (currentLink) {
      setLinks(
        links.map((link) => (link.id === currentLink.id ? newLink : link))
      );
      toast.success("URL updated successfully!");
    } else {
      setLinks((prev) => {
        return [...prev, newLink];
      });
      // setLinks([...links, newLink]);
      toast.success("New URL added successfully!");
      event.target.reset();
      setAlias("");
      setLongUrl("");
    }

    handleCloseDialog();
  };

  const [alias, setAlias] = React.useState(currentLink?.alias || "");
  const [longUrl, setLongUrl] = React.useState("");

  const handleOpenDeleteModal = (id) => {
    setItemId(id);
    setIsModalVisible(true);
  };

  const handleCloseDeleteModal = () => setIsModalVisible(false);
  const handleOpenClicksModel = () => setIsShowClickModal(true);
  const handleCloseClicksModel = () => setIsShowClickModal(false);

  const handleDelete = (itemId) => {
    setLinks(links.filter((link) => link.id !== itemId));
    setIsModalVisible(false);
    toast.success("Deleted successfully.");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredLinks = links?.filter((link) => {
    const [day, month, year] = link.created.split("/");
    const createdDate = new Date(`${year}-${month}-${day}`);

    const matchesDateRange =
      createdDate >= dateRange[0]?.startDate &&
      createdDate <= dateRange[0]?.endDate;

    const searchField = searchFilter || [
      "alias",
      "longUrl",
      "shortUrl",
      "remarks",
    ];

    const matchesSearch = Array.isArray(searchField)
      ? searchField.some((field) =>
          link[field]?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : link[searchField]?.toLowerCase().includes(searchTerm.toLowerCase());

    return matchesDateRange && matchesSearch;
  });

  console.log("filteredLinks", filteredLinks);

  // Pagination
  // const [page, setPage] = useState(1); // Track the current page
  // const rowsPerPage = 4;

  // Handle page change
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const validateDomain = (domain) => {
    const domainPattern = /^(?!-)([a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,}$/;

    if (domain.length < 3) {
      toast.error("Domain name is too short.");
      return false;
    }

    if (!domainPattern.test(domain)) {
      toast.error("Please enter a valid domain (e.g., example.com).");
      return false;
    }

    const specialCharsPattern = /[^a-zA-Z0-9-.]/;
    if (specialCharsPattern.test(domain)) {
      toast.error(
        "Domain contains invalid characters. Only letters, numbers, hyphens, and periods are allowed."
      );
      return false;
    }
    return true;
  };

  const validateDomainUrl = (domain) => {
    const domainPattern = /^(?!-)([a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,}$/;

    if (domain.length < 3) {
      return false;
    }

    if (!domainPattern.test(domain)) {
      return false;
    }

    const specialCharsPattern = /[^a-zA-Z0-9-.]/;
    if (specialCharsPattern.test(domain)) {
      return false;
    }
    return true;
  };

  const handleSaveDomain = () => {
    if (validateDomain(domain)) {
      setDomain(domain);
      setIsEditable(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Modal
        open={openDashboardModal}
        onClose={handleCancel}
        sx={{
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            background:
              theme === "light"
                ? `url("/lightBg.svg") no-repeat center center`
                : `url("/loginBg.svg") no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          {/* Navbar */}
          <AppBar
            handleCancel={handleCancel}
            theme={theme}
            setTheme={setTheme}
          />

          {/* Add Button Section*/}
          <Box
            sx={{
              borderRadius: "10px",
              background:
                theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.04)",
              p: { xs: "20px 34px", sm: "20px 34px", lg: "0px 34px" },
              width: "90%",
              m: "auto",
              height: { xs: "auto", sm: "auto", lg: "105px" },
              display: { xs: "grid", sm: "grid", lg: "flex" },
              alignItems: "center",
              justifyContent: {
                xs: "center",
                lg: "space-between",
              },
              mt: "22px",
              mb: "10px",
              gap: { xs: "25px", sm: "25px", lg: "" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                width: "457px",
                borderRadius: "17px",
                border: "1px solid transparent",
                transition: "border 0.3s ease",
                "&:hover": {
                  border:
                    theme === "light" ? "1px solid #6231F7" : "1px solid #fff",
                },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ color: theme === "light" ? "#060C16" : "#fff" }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Select
                        value={searchFilter}
                        onChange={(e) => setSearchFilter(e.target.value)}
                        displayEmpty
                        size="small"
                        sx={{
                          color: theme === "light" ? "#6231F7" : "#ffffff",
                          border: "1px solid transparent",
                          "& .MuiSelect-icon": {
                            color: theme === "light" ? "#6231F7" : "#ffffff",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              mt: "20px",
                              // backgroundColor: "#1F243D",
                              background:
                                theme === "light" ? "#fff" : "#1F243D",
                              borderRadius: "12px",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                              maxHeight: 300,
                              width: "187px",
                              "& .MuiMenuItem-root": {
                                fontSize: "14px",
                                fontWeight: 500,
                                color:
                                  theme === "light" ? "#060C16" : "#ffffff",
                                padding: "7px 20px",
                                "&:hover": {
                                  backgroundColor:
                                    theme === "light" ? "#F6F6FF" : "#3a3a57",
                                },
                                "&.Mui-selected": {
                                  backgroundColor:
                                    theme === "light" ? "#F6F6FF" : "#444466",

                                  color:
                                    theme === "light" ? "#6231F7" : "#ffffff",
                                  "&:hover": {
                                    backgroundColor:
                                      theme === "light" ? "#F6F6FF" : "#555577",
                                  },
                                },
                              },
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                      >
                        <MenuItem
                          value=""
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          value="longUrl"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Long URL
                        </MenuItem>
                        <MenuItem
                          value="shortUrl"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Short URL
                        </MenuItem>
                        <MenuItem
                          value="alias"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Alias
                        </MenuItem>
                        <MenuItem
                          value="remarks"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Remarks
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor:
                    theme === "light" ? "#F6F6FF" : "rgba(255, 255, 255, 0.06)",
                  borderRadius: "17px",
                  input: { color: theme === "light" ? "#060C16" : "#fff" },
                  "& input:-webkit-autofill": {
                    WebkitBoxShadow:
                      "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                    borderTopRightRadius: "17px",
                    borderBottomRightRadius: "17px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid transparent",
                      borderRadius: "17px",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                      borderRadius: "17px",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                      borderRadius: "17px",
                    },
                  },
                }}
                required
              />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "25px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "25px",
                }}
              >
                {!isEditable && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      startIcon={domain ? <CiEdit /> : <MdLanguage />}
                      onClick={() => setIsEditable(!isEditable)}
                      sx={{
                        textTransform: "none",
                        fontSize: "16px",
                        height: "52px",
                        padding: "0px 10px",
                        color: theme === "light" ? "#060C16" : "#fff",
                        background: "transparent",
                        fontWeight: 500,
                        boxShadow: "none",
                        "&:hover": {
                          background: "transparent",
                          boxShadow: "none",
                        },
                      }}
                    >
                      {domain ? "Edit Domain" : "Add Domain"}
                    </Button>
                  </Box>
                )}

                {isEditable ? (
                  <Box>
                    <TextField
                      fullWidth
                      variant="standard"
                      placeholder="Ex. cbxit.in"
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                      sx={{
                        backgroundColor: "transparent",
                        input: {
                          color: theme === "light" ? "#060C16" : "#fff",
                          "::placeholder": {
                            color: theme === "light" ? "#6E8BC2" : "#fff",
                            opacity: 1,
                          },
                        },
                        borderBottom:
                          theme === "light" ? "#060C16" : "1px solid #fff",
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#fff",
                            borderBottom: "1px solid #fff",
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              disabled={!validateDomainUrl(domain) || !domain}
                              onClick={handleSaveDomain}
                              sx={{
                                borderRadius: "17px",
                                background: "#6231F7",
                                fontSize: "14px",
                                cursor: "pointer",
                                padding: "5px 20px",
                                color: "#fff",
                                textTransform: "none",
                                mb: "5px",
                                "&:hover": { background: "#6231F7" },
                                "&.Mui-disabled": {
                                  backgroundColor:
                                    theme === "light" ? "#F0F0F0" : "#AEA9BA",
                                  color:
                                    theme === "light" ? "#A3A3A3" : "#060C16",
                                },
                              }}
                            >
                              Save
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                ) : (
                  domain && (
                    <Box
                      sx={{
                        color: theme === "light" ? "#060C16" : "#fff",
                        border:
                          theme === "light"
                            ? "1px solid #060C16"
                            : "1px solid #fff",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "45px",
                        padding: "0px 20px",
                        borderRadius: "82px",
                      }}
                    >
                      {domain}
                    </Box>
                  )
                )}
              </Box>

              <Button
                onClick={() => handleOpenDialog()}
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  height: "52px",
                  padding: "0px 40px",
                  borderRadius: "17px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  border: "1px solid #6231F7",
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  fontWeight: 500,
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #6231F7",
                    border: "1px solid #6231F7",
                    color: theme === "light" ? "#fff" : "#6231F7",
                    backgroundColor: theme === "light" ? "#4A1FDB" : "#fff",
                  },
                }}
              >
                Add New URL
              </Button>
            </Box>
          </Box>

          {/* List of links section */}
          <Box
            sx={{
              width: "90%",
              m: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "10px 0px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              {" "}
              <Typography
                sx={{
                  color: theme === "light" ? "#060C16" : "#fff",
                  fontSize: "24px",
                  fontWeight: 500,
                }}
              >
                List of Links
              </Typography>{" "}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Tooltip title="Reset" arrow>
                <IconButton
                  onClick={() => {
                    setSearchTerm("");
                    setSearchFilter("");
                    setDateRange([
                      {
                        startDate: new Date("2021.12.01"),
                        endDate: new Date(),
                      },
                    ]);
                  }}
                  sx={{
                    background: "gray.700",
                    color: "#fff",
                    border: "1px solid #6231F7",
                    mr: "10px",

                    background: theme === "light" ? "#fff" : "gray.700",
                    color: theme === "light" ? "#6231f7" : "white",
                    border:
                      theme === "light"
                        ? "1px solid #fff"
                        : "1px solid #6231F7",
                    "&:hover": {
                      background: theme === "light" ? "#6231f7" : "white",
                      color: theme === "light" ? "#fff" : "#6231F7",
                      border:
                        theme === "light"
                          ? "1px solid #6231f7"
                          : "1px solid #fff",
                    },
                  }}
                >
                  <GrPowerReset size={18} />{" "}
                </IconButton>
              </Tooltip>
              <Typography
                sx={{
                  color: theme === "light" ? "#060C16" : "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Select Date Range :
              </Typography>{" "}
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography
                  sx={{
                    color: "#706FB8",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  Dec 2021 - Sep 2022
                </Typography>{" "}
                <IconButton
                  onClick={handleClick}
                  aria-describedby={id}
                  sx={{ color: "#706FB8" }}
                >
                  <CalendarTodayIcon sx={{ width: "17px", height: "17px" }} />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <DateRange
                    ranges={dateRange}
                    onChange={handleDateChange}
                    rangeColors={["#6231F7"]}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    // ranges={[dateRange]}
                    // maxDate={new Date()}
                    minDate={new Date("2022.01.01")}
                  />
                </Popover>
              </Box>
            </Box>
          </Box>

          {/* <Paper> */}
          <TableContainer
            sx={{
              width: "90%",
              margin: "auto",
              background:
                theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)",
              borderRadius: "10px",
              p: "0px 20px 20px 20px",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                width: "50%",
                borderBottom:
                  theme === "light" ? "2px solid #5733F7" : "2px solid #fff",
                left: "50%",
                transform: "translateX(-50%)",
              },
            }}
          >
            <Table className="custom-table">
              <TableHead>
                <TableRow>
                  {[
                    "Date Created",
                    "Long URL",
                    "Alias",
                    "Short URL",
                    "Remarks",
                    "Clicks",
                    "Actions",
                  ].map((header) => (
                    <TableCell
                      key={header}
                      className={
                        theme === "light" ? "tableLightHeader" : "tabelHeader"
                      }
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {links
                  ?.sort((a, b) => new Date(b.created) - new Date(a.created))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((link, index) => (
                    <TableRow
                      key={link.id}
                      sx={{
                        ...(theme === "light"
                          ? styles.lightHoverEffect
                          : styles.hoverEffect),
                        ...(index % 2 === 0
                          ? theme === "light"
                            ? styles.evenRowLight
                            : styles.evenRow
                          : theme === "light"
                          ? styles.oddRowLight
                          : styles.oddRow),
                      }}
                    >
                      <TableCell
                        className={
                          theme === "light" ? "tabelLightCell" : "tabelCell"
                        }
                      >
                        {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Checkbox
                          sx={{
                            display: "none",
                            borderColor: theme === "light" ? "#44546F" : "#fff",
                            color: theme === "light" ? "#44546F" : "#fff",
                          }}
                        /> */}
                        {link.created}
                        {/* </Box> */}
                      </TableCell>
                      <TableCell
                        className={
                          theme === "light" ? "tabelLightCell" : "tabelCell"
                        }
                      >
                        {link.longUrl}
                      </TableCell>
                      <TableCell
                        className={
                          theme === "light" ? "tabelLightCell" : "tabelCell"
                        }
                      >
                        {link.alias}
                      </TableCell>
                      <TableCell
                        className={
                          theme === "light" ? "tabelLightCell" : "tabelCell"
                        }
                      >
                        {link.shortUrl}
                      </TableCell>
                      <TableCell
                        className={
                          theme === "light" ? "tabelLightCell" : "tabelCell"
                        }
                      >
                        {link.remarks}
                      </TableCell>
                      <TableCell
                        className={
                          theme === "light" ? "tabelLightCell" : "tabelCell"
                        }
                        style={{
                          cursor: link.clicks > 0 ? "pointer" : "default",
                          textDecoration: "underline",
                        }}
                        onClick={
                          link.clicks > 0 ? handleOpenClicksModel : undefined
                        }
                      >
                        {link.clicks}
                      </TableCell>

                      <TableCell
                        className={
                          theme === "light" ? "tabelLightCell" : "tabelCell"
                        }
                      >
                        <IconButton
                          onClick={() => handleOpenDialog(link)}
                          sx={{ color: theme === "light" ? "#44546F" : "#fff" }}
                        >
                          <Edit
                            sx={{
                              // color: theme === "light" ? "#44546F" : "#fff",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenDeleteModal(link.id)}
                          sx={{ color: theme === "light" ? "#44546F" : "#fff" }}
                        >
                          <Delete
                            sx={{
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={links?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page :"
            rowsPerPageOptions={[4, 10, 25, 100]}
            sx={{
              color: theme === "light" ? "#060C16" : "#fff",
              width: "90%",
              margin: "auto",
              "& .MuiTablePagination-actions": {
                color: "#fff",
                "& .Mui-disabled": {
                  color:
                    theme === "light" ? "#9595C0" : "rgba(255, 255, 255, 0.5)",
                },
              },
              "& .MuiTablePagination-selectLabel": {
                color: theme === "light" ? "#060C16" : "#fff",
              },
              "& .MuiSelect-select": {
                color: theme === "light" ? "#060C16" : "#fff",
              },
              "& .MuiSelect-icon": {
                color: theme === "light" ? "#060C16" : "#fff",
              },
              "& .MuiTablePagination-select": {
                color: theme === "light" ? "#060C16" : "#fff",
              },
              "& .MuiTablePagination-displayedRows": {
                color: theme === "light" ? "#060C16" : "#fff",
              },
              "& .MuiIconButton-root": {
                color: theme === "light" ? "#060C16" : "#fff",
              },
            }}
          />

          {/* <Box
            sx={{
              width: "90%",
              m: "auto",
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px 0px",
            }}
          >
            <CustomPagination
              count={Math.ceil(links.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: ArrowBackIosIcon,
                    next: ArrowForwardIosIcon,
                  }}
                  {...item}
                />
              )}
            />
          </Box> */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "90%",
              m: "auto",
            }}
          >
            <Button
              variant="contained"
              startIcon={<PrivacyTipIcon />}
              sx={{
                color: theme === "light" ? "#060C16" : "#fff",
                background:
                  theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.05)",
                // background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "58px",
                textTransform: "none",
                padding: "15px 25px",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "22px",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.05)",
                },
              }}
            >
              Note
            </Button>
            <Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  fontWeight: 500,
                }}
              >
                Dashboard Contains Demo Content and
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  color: theme === "light" ? "#060C16" : "#fff",
                  fontWeight: 500,
                }}
              >
                changes will not be saved
              </Typography>
            </Box>
          </Box>

          {/*Add and edit dialog   */}
          <Modal open={openDialog} onClose={handleCloseDialog}>
            <Box
              style={modalContentStyle}
              sx={{
                background: theme === "light" ? "#fff" : "#23213B",
                width: {
                  xs: "90%",
                  sm: "500px",
                  lg: "981px",
                },
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: 0,
                  width: "70%",
                  borderBottom:
                    theme === "light" ? "3px solid #6231F7" : "3px solid #fff",
                  left: "50%",
                  transform: "translateX(-50%)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Typography
                  sx={{
                    color: theme === "light" ? "#060C16" : "#fff",
                    fontSize: "20px",
                    fontWeight: 550,
                    lineHeight: "25.2px",
                  }}
                >
                  {currentLink ? "Edit URL" : "Add New URL"}
                </Typography>
                <IconButton
                  // size="small"
                  onClick={handleCloseDialog}
                  sx={{
                    background:
                      theme === "light"
                        ? "#F6F7F8"
                        : "rgba(255, 255, 255, 0.08)",
                    color: theme === "light" ? "#060C16" : "#fff",
                    "&:hover": {
                      background:
                        theme === "light"
                          ? "#f0f5fa"
                          : "rgba(255, 255, 255, 0.22)",
                      color: theme === "light" ? "#060C16" : "#fff",
                    },
                  }}
                >
                  <CloseIcon sx={{ width: "22px", height: "22px" }} />
                </IconButton>
              </Box>
              <Box onSubmit={handleSave} component={"form"}>
                {/* <form onSubmit={handleSave}> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "25px",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="longUrl"
                    placeholder="Long URL*"
                    fullWidth
                    variant="outlined"
                    // defaultValue={currentLink ? currentLink.longUrl : ""}
                    value={longUrl}
                    onChange={(e) => setLongUrl(e.target.value)}
                    sx={{
                      width: { xs: "100%", sm: "90%", lg: "419px" },
                      backgroundColor:
                        theme === "light" ? "#F6F7F8" : "#000627",
                      borderRadius: "10px",
                      input: {
                        color: theme === "light" ? "#060C16" : "#fff",
                        "&::placeholder": {
                          color: theme === "light" ? "#060C16" : "#8A8A8A",
                          opacity: 1,
                        },
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid transparent",
                          borderRadius: "10px",
                        },
                        "&:hover fieldset": {
                          borderColor: theme === "light" ? "#6231F7" : "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme === "light" ? "#6231F7" : "#fff",
                        },
                      },
                    }}
                  />
                  <TextField
                    margin="dense"
                    name="alias"
                    placeholder="Alias"
                    fullWidth
                    variant="outlined"
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                    // defaultValue={currentLink ? currentLink.alias : ""}
                    sx={{
                      width: { xs: "100%", sm: "90%", lg: "419px" },
                      backgroundColor:
                        theme === "light" ? "#F6F7F8" : "#000627",
                      borderRadius: "10px",
                      input: {
                        color: theme === "light" ? "#060C16" : "#fff",
                        "&::placeholder": {
                          color: theme === "light" ? "#060C16" : "#8A8A8A",
                          opacity: 1,
                        },
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "10px",
                          border: "1px solid transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: theme === "light" ? "#6231F7" : "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme === "light" ? "#6231F7" : "#fff",
                        },
                      },
                    }}
                  />
                  <TextField
                    margin="dense"
                    name="shortUrl"
                    placeholder="Short URL"
                    fullWidth
                    variant="outlined"
                    readOnly
                    value={`https://cbxit.in/${alias || ""}`}
                    sx={{
                      width: { xs: "100%", sm: "90%", lg: "419px" },
                      backgroundColor:
                        theme === "light" ? "#F6F7F8" : "#000627",
                      borderRadius: "10px",
                      input: {
                        color: theme === "light" ? "#060C16" : "#fff",
                        "&::placeholder": {
                          color: theme === "light" ? "#060C16" : "#8A8A8A",
                          opacity: 1,
                        },
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid transparent",
                          borderRadius: "10px",
                        },
                        "&:hover fieldset": {
                          borderColor: theme === "light" ? "#6231F7" : "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme === "light" ? "#6231F7" : "#fff",
                        },
                      },
                    }}
                  />
                  <TextField
                    margin="dense"
                    name="remarks"
                    placeholder="Remarks"
                    fullWidth
                    variant="outlined"
                    defaultValue={currentLink ? currentLink.remarks : ""}
                    sx={{
                      width: { xs: "100%", sm: "90%", lg: "419px" },
                      backgroundColor:
                        theme === "light" ? "#F6F7F8" : "#000627",
                      borderRadius: "10px",
                      input: {
                        color: theme === "light" ? "#060C16" : "#fff",
                        "&::placeholder": {
                          color: theme === "light" ? "#060C16" : "#8A8A8A",
                          opacity: 1,
                        },
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid transparent",
                          borderRadius: "10px",
                        },
                        "&:hover fieldset": {
                          borderColor: theme === "light" ? "#6231F7" : "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme === "light" ? "#6231F7" : "#fff",
                        },
                      },
                    }}
                  />
                </Box>
                {/* </form> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    m: "32px 0px 16px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!longUrl}
                    sx={{
                      borderRadius: "17px",
                      textTransform: "none",
                      background: theme === "light" ? "#F6F7F8" : "#6231F7",
                      color: theme === "light" ? "#6231F7" : "#fff",
                      // opacity: 0.4,
                      width: "131px",
                      lineHeight: "17.64px",
                      fontSize: "16px",
                      fontWeight: 550,
                      p: "18px 0px",
                      transition: "transform 0.5s ease-in-out",
                      "&:hover": {
                        background: theme === "light" ? "#F6F7F8" : "#6231F7",
                        color: theme === "light" ? "#6231F7" : "#fff",
                        transform: "scale(1.05)",
                      },
                      "&.Mui-disabled": {
                        backgroundColor:
                          theme === "light" ? "#F0F0F0" : "#AEA9BA",
                        color: theme === "light" ? "#A3A3A3" : "#060C16",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          {/* </Paper> */}
        </Box>
      </Modal>

      {/* DELETE CONFORMATION MODAL */}
      <DeleteConfirmationModal
        handleCancel={handleCloseDeleteModal}
        isModalVisible={isModalVisible}
        handleDelete={handleDelete}
        itemId={itemId}
      />

      {/* Clicks Modal */}
      <ClicksModal
        handleCancel={handleCloseClicksModel}
        isShowClickModal={isShowClickModal}
        theme={theme}
        setMapData={setMapData}
      />
    </Box>
  );
};

export default LinkTable;

// const paginatedData = links?.slice(
//   // (page - 1) * rowsPerPage,
//   // page * rowsPerPage
// );
