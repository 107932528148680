import React, { useState } from "react";
import WhySection from "./WhySection";
import WhereSection from "./WhereSection";
import "./WhyAndWhere.css";

const WhyAndWhereSection = () => {
  const [isToggleSection, setIsToggleSection] = useState(true);

  const handleToggle = () => {
    setIsToggleSection(!isToggleSection);
  };

  return (
    <div id="why-how">
      {isToggleSection ? (
        <WhySection handleToggle={handleToggle} />
      ) : (
        <WhereSection handleToggle={handleToggle} />
      )}
    </div>
  );
};

export default WhyAndWhereSection;
