// import { DeleteIcon } from "@chakra-ui/icons";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   useDisclosure,
//   ChakraProvider,
// } from "@chakra-ui/react";
// import { Button } from "@chakra-ui/react";

// export default function BasicUsage({
//   onClick,
//   selected,
//   funcClose,
//   openModelFunc,
// }) {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   return (
//     <ChakraProvider>
//       <Button
//         _hover={{ background: "#ff6868" }}
//         color={"white"}
//         background={"red"}
//         onClick={() => {
//           if (openModelFunc()) onOpen();
//         }}
//       >
//         {" "}
//         <DeleteIcon sx={{ mr: "10px" }} /> Delete
//       </Button>

//       <Modal isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Confirm</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>Do you want to delete selected entries.</ModalBody>
//           <ModalFooter>
//             <Button
//               colorScheme="blue"
//               mr={3}
//               onClick={() => {
//                 funcClose();
//                 onClose();
//               }}
//             >
//               Close
//             </Button>
//             <Button
//               onClick={() => {
//                 onClick();
//                 onClose();
//               }}
//               _hover={{ background: "#ff6868" }}
//               color={"white"}
//               background={"red"}
//               variant="ghost"
//             >
//               Delete
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </ChakraProvider>
//   );
// }

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  Box,
  Text,
  Image,
  ChakraProvider,
  IconButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { MdDeleteOutline } from "react-icons/md";

export default function BasicUsage({
  onClick,
  funcClose,
  openModelFunc,
  theme,
  selected,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const modalContentStyle = {
    width: "492px",
    backgroundColor: "white",
    borderRadius: "37px",
    boxShadow: "2xl",
    padding: "30px 25px",
    textAlign: "center",
  };

  return (
    <ChakraProvider>
      <Tooltip hasArrow label="Delete">
        <IconButton
          isDisabled={selected.length === 0}
          onClick={() => {
            if (openModelFunc()) onOpen();
          }}
          size={"md"}
          isRound={true}
          icon={<MdDeleteOutline size={18} />}
          aria-label="Edit"
          bg={theme === "light" ? "#fff" : "gray.700"}
          color={theme === "light" ? "#6231f7" : "white"}
          border={theme === "light" ? "1px solid #fff" : "1px solid #6231F7"}
          _hover={{
            bg: "red",
            color: "#fff",
            border: "1px solid red",
            cursor: selected.length === 0 ? "default" : "pointer",
          }}
          _disabled={{
            border: "1px solid #E7EAF0",
            backgroundColor: "#E7EAF0",
            color: "#A5A9C1",
          }}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent {...modalContentStyle}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Image
              src="/DeleteConfirm.svg"
              alt="Delete Confirmation Icon"
              className="delete-icon"
            />
          </Box>

          <Box
            borderBottom="2px solid #6231F71A"
            width="90%"
            margin="20px auto"
            padding="20px 0"
          >
            <Text
              fontSize="22px"
              fontWeight="500"
              lineHeight="27px"
              textAlign="center"
              color="#060C16"
            >
              Are you sure that you want to
            </Text>
            <Text
              fontSize="22px"
              fontWeight="500"
              lineHeight="27px"
              textAlign="center"
              color="#060C16"
            >
              delete this rows?
            </Text>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="25px"
            marginTop="25px"
          >
            <Button
              onClick={() => {
                funcClose();
                onClose();
              }}
              variant="outline"
              color="#060C16"
              border="1px solid #6231F7"
              borderRadius="58px"
              textTransform="none"
              padding="10px 40px"
              fontSize="18px"
              fontWeight="500"
              _hover={{ border: "1px solid #6231F7", color: "#6231F7" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onClick();
                onClose();
              }}
              colorScheme="purple"
              backgroundColor="#6231F7"
              borderRadius="58px"
              textTransform="none"
              padding="10px 30px"
              fontSize="18px"
              fontWeight="500"
            >
              Yes! Delete
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}
