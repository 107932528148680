import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { Context } from "../../../context";
import { Box, Button, IconButton } from "@chakra-ui/react";
import "../../TablePage/Map.css";
import pointerIcon from "./pointer.png";
import { IoMdArrowBack } from "react-icons/io";
import { MdLocationSearching } from "react-icons/md";
import { FaSatelliteDish } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { TryRounded } from "@mui/icons-material";

const mapData = [
  {
    _id: "64ec936fff7597acfca8f4bd",
    city: "Kolkata",
    country: "IN",
    latitude: "22.5626",
    longitude: "88.363",
    browser: "Chrome 116.0.0.0",
    os: "Windows 10",
    userID: "64a7e33e7919b834ac892cb1",
    shortURL: "cbxit.in/6pmmeet",
    __v: 0,
    count: 59,
  },
  {
    _id: "64ede9b1ff7597acfca91879",
    city: "New delhi",
    country: "IN",
    latitude: "20",
    longitude: "77",
    browser: "Chrome Mobile 97.0.4692.98",
    os: "Android 12",
    userID: "64a7e33e7919b834ac892cb1",
    shortURL: "cbxit.in/6pmmeet",
    __v: 0,
    count: 14,
  },
  {
    _id: "651eb1da445a0a2d3db5d57a",
    city: "Dibrugarh",
    country: "IN",
    latitude: "27.4799",
    longitude: "94.9084",
    browser: "Chrome Mobile 97.0.4692.98",
    os: "Android 12",
    userID: "64a7e33e7919b834ac892cb1",
    shortURL: "cbxit.in/6pmmeet",
    __v: 0,
    count: 1,
  },
];
function DemoMap() {
  const { theme, setOpenDashboardModal } = useContext(Context);
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);

  const defaultProps = {
    center: {
      lat: mapData[0]?.latitude === "Unknown" ? 19.076 : mapData[0]?.latitude,
      lng:
        mapData[0]?.longitude === "Unknown" ? 72.877426 : mapData[0]?.longitude,
    },
    zoom: 11,
  };

  useEffect(() => {
    function initMap() {
      const mapOptions = {
        zoom: 7,
        center: {
          lat: Number(mapData[0]?.latitude || 0),
          lng: Number(mapData[0]?.longitude || 0),
        },
        mapTypeId: "roadmap", // Set default map type as 'roadmap'
      };

      if (mapRef.current) {
        const map = new window.google.maps.Map(mapRef.current, mapOptions);
        mapInstanceRef.current = map; // Store map instance in ref

        // Add markers
        mapData.forEach((data) => {
          // <Marker
          //   lat={data.latitude == "Unknown" ? 19.076 : data.latitude}
          //   lng={data.longitude == "Unknown" ? 72.877426 : data.longitude}
          //   text=""
          // />;
          if (data.latitude !== "Unknown" && data.longitude !== "Unknown") {
            const marker = new window.google.maps.Marker({
              position: {
                lat: Number(data.latitude),
                lng: Number(data.longitude),
              },
              map: map,
              icon: {
                url: pointerIcon,
                scaledSize: new window.google.maps.Size(20, 30),
              },
            });

            const infowindow = new window.google.maps.InfoWindow({
              content: "<p> Count: " + data.count + " </p>",
            });

            marker.addListener("click", () => {
              infowindow.open(map, marker);
            });
          }
        });
      }
    }

    if (window.google && window.google.maps) {
      initMap();
    } else {
      console.error("Google Maps API not loaded");
    }
  }, [mapData]);

  // Handler for changing map view
  const handleMapTypeChange = (type) => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.setMapTypeId(type); // Set the map type (either 'roadmap' or 'satellite')
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        paddingBottom: "50px",
        background:
          theme === "light"
            ? `url("/lightBg.svg") no-repeat center center`
            : `url("/loginBg.svg") no-repeat center center`,
        backgroundSize: "cover",
        paddingTop: "10px",
      }}
    >
      <Box
        sx={{
          height: "752px",
          width: "95%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          background: theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "95%",
            margin: "auto",
          }}
        >
          {/* Back Button */}
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Button
              onClick={() => setOpenDashboardModal(true)}
              variant="link"
              leftIcon={<IoMdArrowBack size={23} />}
              color={theme === "light" ? "#060C16" : "#fff"}
            >
              Back
            </Button>
          </Link>

          {/* Map and Satellite Buttons */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Button
              leftIcon={<MdLocationSearching size={21} />}
              sx={{
                background: "#6231F7",
                color: "#fff",
                borderRadius: "17px",
                fontSize: "15px",
                width: "131px",
                height: "55px",
                border: "none",
                _hover: {
                  background: "#4f18f5",
                },
              }}
              onClick={() => handleMapTypeChange("roadmap")}
            >
              Map
            </Button>
            <Button
              variant="outline"
              leftIcon={<FaSatelliteDish size={21} />}
              sx={{
                border: "1px solid #6231F7",
                outline: "1px solid #6231F7",
                color: theme === "light" ? "#060C16" : "#fff",
                borderRadius: "17px",
                fontSize: "15px",
                width: "131px",
                height: "55px",

                border: "none",
                _hover: {
                  border: "1px solid  #6231F7",
                },
              }}
              onClick={() => handleMapTypeChange("hybrid")}
            >
              Satellite
            </Button>
          </Box>
        </Box>

        <Box
          ref={mapRef}
          id="map"
          position="relative"
          height="526px"
          width="95%"
          margin="auto"
          borderRadius="63px"
          overflow="hidden"
        ></Box>
        {/* Zoom Controls */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
          width="90%"
          margin="auto"
        >
          <IconButton
            onClick={() =>
              mapInstanceRef.current.setZoom(
                mapInstanceRef.current.getZoom() + 1
              )
            }
            size={"md"}
            isRound={true}
            icon={<IoMdAdd size={18} />}
            bg={theme === "light" ? "#fff" : "gray.700"}
            color={theme === "light" ? "#060C16" : "#fff"}
            border={theme === "light" ? "1px solid #060C16" : "1px solid #fff"}
            _hover={{
              border:
                theme === "light" ? "1px solid #060C16" : "1px solid #fff",
            }}
          />

          <IconButton
            onClick={() =>
              mapInstanceRef.current.setZoom(
                mapInstanceRef.current.getZoom() - 1
              )
            }
            size={"md"}
            isRound={true}
            icon={<RiSubtractFill size={18} />}
            bg={theme === "light" ? "#fff" : "gray.700"}
            color={theme === "light" ? "#060C16" : "#fff"}
            border={theme === "light" ? "1px solid #060C16" : "1px solid #fff"}
            _hover={{
              border:
                theme === "light" ? "1px solid #060C16" : "1px solid #fff",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default DemoMap;
