import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import { Context } from "../../context";
import { CopyIcon } from "@chakra-ui/icons";
import { FaStar, FaWhatsapp, FaQrcode } from "react-icons/fa";
import { MdEmail, MdLocationSearching } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import toast from "react-hot-toast";
import { FaRegStar } from "react-icons/fa";

function CustomIconQR({ onClick, theme }) {
  return (
    <FaQrcode
      onClick={onClick}
      size={19}
      style={{
        cursor: "pointer",
        color: theme === "light" ? "#44546F" : "#fff",
      }}
    />
  );
}

function CustomIconEmail({ onClick, theme }) {
  return (
    <MdEmail
      onClick={onClick}
      size={19}
      style={{
        cursor: "pointer",
        color: theme === "light" ? "#44546F" : "#fff",
      }}
    />
  );
}

function CustomIconWhatsApp({ onClick, theme }) {
  return (
    <FaWhatsapp
      onClick={onClick}
      size={19}
      style={{
        cursor: "pointer",
        color: theme === "light" ? "#44546F" : "#fff",
      }}
    />
  );
}

function CustomIconStar({ onClick, bg, favourite }) {
  return favourite ? (
    <FaStar
      onClick={onClick}
      size={19}
      color={bg}
      style={{ cursor: "pointer" }}
    />
  ) : (
    <FaRegStar
      onClick={onClick}
      size={19}
      color={bg}
      style={{ cursor: "pointer" }}
    />
  );
}

function areLinksEqual(link1, link2) {
  // Prepend 'http://' if the scheme is missing
  link1 = link1.startsWith("http") ? link1 : "http://" + link1;
  link2 = link2.startsWith("http") ? link2 : "http://" + link2;

  // Create URL objects for the links
  const url1 = new URL(link1);
  const url2 = new URL(link2);

  // Compare the hostname and pathname of the URLs
  return url1.hostname === url2.hostname && url1.pathname === url2.pathname;
}

// Example usage:
const link1 = "localhost:3001/fbbb";
const link2 = "https://cbxit.in/10am";
const result = areLinksEqual(link1, link2);

function ensureHttps(link) {
  // Check if the link starts with "http://" or "https://"
  if (link.startsWith("http://")) {
    // Replace "http://" with "https://"
    return link.replace("http://", "https://");
  } else if (!link.startsWith("https://")) {
    // If it doesn't start with "https://" or "http://", prepend "https://"
    return "https://" + link;
  }

  // If the link already has "https://" or "http://", return it unchanged
  return link;
}

export default function TableRows({ data, userDataArr, setData, date, index }) {
  const {
    axios,
    googleSheetDeployLink,
    setGoogleSheetDeployLink,
    selected,
    setSelected,
    editing,
    domainValue,
    userDetails,
    setFilteredData,
    clickDetails,
    setclickDetails,
    setMapData,
    apiLink,
    setUnselect,
    setPaginativeData,
    theme,
  } = useContext(Context);
  const [check, setCheck] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const { clicks, longURL, alias, remarks, shortURL, _id, favourite } = data;
  const [longURLValue, setLongURLValue] = useState(longURL);
  const [aliasValue, setAliasValue] = useState(alias);
  const [remarksValue, setRemarksValue] = useState(remarks);
  const [shortLinkValue, setShortLinkValue] = useState(shortURL);
  const [count, setCount] = useState(1);
  const [openQR, setOpenQR] = useState(false);
  const [openClicks, setopenClicks] = useState(false);
  const [isGoogleSheetEditable, setIsGoogleSheetEditable] = useState(false);
  const deployLinkRef = useRef(null);

  const filteredClicks = clickDetails.filter((j) => {
    let val1 = ensureHttps(shortLinkValue);
    let val2 = ensureHttps(j.shortURL);
    return areLinksEqual(val1, val2);
    // return shortLinkValue.includes(j.shortURL)
    // return j.shortURL == shortLinkValue
  });

  const allCount = useMemo(() => {
    return (() => {
      let temp = 0;
      for (let item of filteredClicks) {
        temp += +item.count;
      }
      return temp;
    })();
  }, [filteredClicks]);

  useEffect(() => {
    if (count == 1) {
      return setCount((prev) => prev + 1);
    }
    let editedObject = {
      longURL: longURLValue,
      alias: aliasValue,
      shortURL: shortLinkValue,
      remarks: remarksValue,
      clicks: clicks,
      dateCreated: date,
      domain: domainValue || "ceoitbox",
      userID: userDetails._id,
      _id: _id,
    };
    let temp = [...userDataArr];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]._id === _id) {
        temp[i] = editedObject;
        break;
      }
    }
    setData(temp);
    // setFilteredData(temp);
  }, [longURLValue, aliasValue, remarksValue, shortLinkValue]);

  useEffect(() => {
    let temp = [...clickDetails];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].shortURL == shortURL) {
        temp[i].shortURL = shortLinkValue;
      }
    }
    setclickDetails(temp);
  }, [aliasValue]);

  useEffect(() => {
    setUnselect((p) => [
      ...p,
      {
        _id,
        shortLinkValue,
        shortURL,
        isReadOnly,
        setIsReadOnly,
        check,
        setCheck,
      },
    ]);
  }, []);

  return (
    <Box
      display={"flex"}
      gap="10px"
      minW={"100%"}
      background={
        index % 2 === 0
          ? theme === "light"
            ? "#F6F6FF"
            : "rgba(255, 255, 255, 0.05)"
          : theme === "light"
          ? "#FFFFFF"
          : "rgba(0, 0, 0, 0.05)"
      }
      borderRadius={"10px"}
      _hover={{
        background: theme === "light" ? "#F1F1FF" : "rgba(255, 255, 255, 0.11)",
        border: theme === "light" ? "1px solid #C5B2FF" : "1px solid #717295",
      }}
      sx={{
        border:
          index % 2 === 0
            ? "1px solid rgba(255, 255, 255, 0.03)"
            : "1px solid transparent",
      }}
    >
      <Box
        flexShrink={0}
        overflow={"hidden"}
        borderRadius={"none"}
        h={"40px"}
        type={"checkbox"}
        w={"6%"}
        textAlign={"center"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <input
          style={{
            width: "17px",
            height: "17px",
            borderRadius: "3px",
            cursor: "pointer",
          }}
          onChange={(e) => {
            if (editing) {
              return toast.error("Cannot Select or Unselect while Editing");
            }

            let value = !check;
            if (value) {
              setSelected((prev) => [
                ...prev,
                {
                  _id,
                  shortLinkValue,
                  shortURL,
                  isReadOnly,
                  setIsReadOnly,
                  check,
                  setCheck,
                },
              ]);
            } else {
              setSelected(selected.filter((i) => i._id !== _id));
            }
            setCheck(value);
          }}
          type="checkbox"
          checked={check}
        />
      </Box>

      <Input
        background={"none"}
        flexShrink={0}
        w={"13%"}
        cursor={"default"}
        readOnly={true}
        borderRadius={"none"}
        border={"none"}
        h={"40px"}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        color={theme === "light" ? "#44546F" : "#fff"}
        fontSize={"14px"}
        defaultValue={date}
        onChange={() => {}}
      ></Input>

      <Input
        flexShrink={0}
        w={"18%"}
        background={"none"}
        color={
          !isReadOnly
            ? theme === "light"
              ? "rgba(136, 139, 212, 0.85)"
              : "rgba(150, 152, 219, 0.60)"
            : theme === "light"
            ? "#44546F"
            : "#fff"
        }
        borderBottom={
          !isReadOnly
            ? theme === "light"
              ? "1px solid #6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none"
        }
        cursor={"default"}
        borderTop={"none"}
        borderLeft={"none"}
        borderRight={"none"}
        _focus={{
          border: !isReadOnly
            ? theme === "light"
              ? "#6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none",
          borderRadius: !isReadOnly
            ? theme === "light"
              ? "5px"
              : "5px"
            : theme === "light"
            ? "none"
            : "none",
        }}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        fontSize={"14px"}
        readOnly={isReadOnly}
        onChange={(e) => setLongURLValue(e.target.value)}
        borderRadius={"none"}
        h={"40px"}
        value={longURLValue}
      ></Input>

      <Input
        flexShrink={0}
        w={"10%"}
        background={"none"}
        color={
          !isReadOnly
            ? theme === "light"
              ? "rgba(136, 139, 212, 0.85)"
              : "rgba(150, 152, 219, 0.60)"
            : theme === "light"
            ? "#44546F"
            : "#fff"
        }
        borderBottom={
          !isReadOnly
            ? theme === "light"
              ? "1px solid #6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none"
        }
        cursor={"default"}
        borderTop={"none"}
        borderLeft={"none"}
        borderRight={"none"}
        _focus={{
          border: !isReadOnly
            ? theme === "light"
              ? "#6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none",
          borderRadius: !isReadOnly
            ? theme === "light"
              ? "5px"
              : "5px"
            : theme === "light"
            ? "none"
            : "none",
        }}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        fontSize={"14px"}
        readOnly={isReadOnly}
        onChange={(e) => {
          setAliasValue(e.target.value);
          if (!domainValue) {
            setShortLinkValue(`${apiLink}${e.target.value}`);
          } else {
            setShortLinkValue(`http://${domainValue}/${e.target.value}`);
          }
        }}
        borderRadius={"none"}
        h={"40px"}
        value={aliasValue}
      ></Input>

      <Box w={"20%"} position={"relative"}>
        <Input
          position={"absolute"}
          flexShrink={0}
          w={"100%"}
          border={"none"}
          cursor={"default"}
          _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
          sx={{ pr: "25px" }}
          color={theme === "light" ? "#44546F" : "#fff"}
          fontSize={"14px"}
          readOnly={true}
          onChange={(e) => console.log(e.target.value)}
          borderRadius={"none"}
          h={"40px"}
          value={shortLinkValue}
        >
          {}
        </Input>

        <Box position={"absolute"} w={"fit-content"} h={"100%"} right={0}>
          <CopyIcon
            sx={{ color: theme === "light" ? "#44546F" : "#fff" }}
            onClick={async () => {
              await navigator.clipboard.writeText(shortLinkValue);
              toast.success("Copied to Clipboard");
            }}
            position={"absolute"}
            right={"10px"}
            top={"12px"}
            cursor={"pointer"}
          />
        </Box>
      </Box>

      <Input
        flexShrink={0}
        w={"10%"}
        fontSize={"14px"}
        background={"none"}
        color={
          !isReadOnly
            ? theme === "light"
              ? "rgba(136, 139, 212, 0.85)"
              : "rgba(150, 152, 219, 0.60)"
            : theme === "light"
            ? "#44546F"
            : "#fff"
        }
        borderBottom={
          !isReadOnly
            ? theme === "light"
              ? "1px solid #6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none"
        }
        cursor={"default"}
        borderTop={"none"}
        borderLeft={"none"}
        borderRight={"none"}
        _focus={{
          border: !isReadOnly
            ? theme === "light"
              ? "#6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none",
          borderRadius: !isReadOnly
            ? theme === "light"
              ? "5px"
              : "5px"
            : theme === "light"
            ? "none"
            : "none",
        }}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        readOnly={isReadOnly}
        onChange={(e) => setRemarksValue(e.target.value)}
        borderRadius={"none"}
        h={"40px"}
        value={remarksValue}
      ></Input>

      <Box
        onClick={() => setopenClicks(true)}
        flexShrink={0}
        w={"8%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#44546F" : "#fff"}
        fontSize={"14px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <p
          style={{
            textDecoration: "underline",
            color: theme === "light" ? "#44546F" : "#FFF",
          }}
        >
          {data.clicks || allCount}
        </p>
      </Box>

      <Box
        flexShrink={0}
        w={"15%"}
        cursor={"default"}
        onChange={(e) => e.target.value}
        borderRadius={"none"}
        h={"40px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"20px"}
      >
        <CustomIconQR onClick={() => setOpenQR(true)} theme={theme} />
        <CustomIconEmail
          theme={theme}
          onClick={() => {
            window.open(
              `https://mail.google.com/mail/u/0/?fs=1&tf=cm&body=${shortLinkValue}&su=CEOITBOX%20SHORT%20URL`,
              "_black"
            );
          }}
        />
        <CustomIconWhatsApp
          theme={theme}
          onClick={() => {
            window.open(
              `https://api.whatsapp.com/send?phone=&text=${shortLinkValue}`,
              "_black"
            );
          }}
        />

        <CustomIconStar
          theme={theme}
          bg={
            favourite
              ? theme === "light"
                ? "#6231F7"
                : "#F8BE00"
              : theme === "light"
              ? "#44546F"
              : "#fff"
          }
          favourite={favourite}
          // bg={favourite ? "yellow" : theme === "light" ? "#44546F" : "#fff"}
          onClick={async () => {
            await axios.patch(`${apiLink}shorten/Alldata/${_id}`, {
              favourite: !favourite,
            });
            let temp = [...userDataArr];
            for (let i = 0; i < temp.length; i++) {
              if (temp[i]._id == _id) {
                temp[i].favourite = !favourite;
                setPaginativeData(
                  temp.sort((a, b) => Number(b.favourite) - Number(a.favourite))
                );
                return setData(
                  temp.sort((a, b) => Number(b.favourite) - Number(a.favourite))
                );
              }
            }
          }}
        />
      </Box>

      {/* Click modal */}
      <Modal
        isOpen={openClicks}
        onClose={() => setopenClicks(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <Box
            w={"950px"}
            minH={"70vh"}
            overflowY={"scroll"}
            display={"flex"}
            flexDirection={"column"}
            scrollbarWidth={"thin"}
            sx={{
              borderRadius: "47px",
              background: theme === "light" ? "#fff" : "#23213B",
              p: "40px",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                width: "70%",
                borderBottom:
                  theme === "light" ? "2px solid #6231F7" : "2px solid #fff",
                left: "50%",
                transform: "translateX(-50%)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "25px",
              }}
            >
              <Text
                sx={{
                  color: theme === "light" ? "#060C16" : "#fff",
                  fontSize: "24px",
                  fontWeight: 600,
                  lineHeight: "25.2px",
                }}
              >
                Clicks Information
              </Text>
              <Box sx={{ display: "flex", alignItems: "center", gap: "25px" }}>
                <NavLink to={"/client/map"}>
                  <Button
                    leftIcon={<MdLocationSearching size={21} />}
                    sx={{
                      background: "#6231F7",
                      color: "#fff",
                      borderRadius: "17px",
                      fontSize: "14px",
                      p: "10px 20px",
                      border: "none",
                      _hover: {
                        background: "#4f18f5",
                      },
                    }}
                    onClick={() => {
                      let temp = clickDetails.filter((i) => {
                        return areLinksEqual(i.shortURL, shortLinkValue);
                      });
                      console.log("temp", temp);
                      setMapData(temp);
                    }}
                  >
                    Map
                  </Button>
                </NavLink>
                <IconButton
                  onClick={() => setopenClicks(false)}
                  isRound={true}
                  size={"sm"}
                  style={{
                    background: "rgba(255, 255, 255, 0.08)",
                    color: theme === "light" ? "#060C16" : "#fff",
                  }}
                  aria-label="Close"
                  icon={<IoMdClose size={22} />}
                />
              </Box>
            </Box>

            <Box
              sx={{
                borderRadius: "10px",
                background:
                  theme === "light" ? "#F8F8FA" : "rgba(0, 6, 39, 0.84)",
                overflow: "hidden",
                p: "10px 20px 20px 20px",
              }}
            >
              {/* Header */}
              <Box
                h={"50px"}
                display={"flex"}
                p={"0px 10px"}
                background={
                  theme === "light" ? "#F8F8FA" : "rgba(0, 6, 39, 0.84)"
                }
                color={theme === "light" ? "#060C16" : "#fff"}
                borderBottom={
                  theme === "light" ? "1px solid #D7DAFF" : "1px solid #292D54"
                }
                gap={"20px"}
              >
                <Box
                  cursor={"default"}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    fontSize: "14px",
                    textAlign: "start",
                  }}
                  w={"13%"}
                  letterSpacing={"2px"}
                >
                  City
                </Box>
                <Box
                  cursor={"default"}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    fontSize: "14px",
                    textAlign: "start",
                  }}
                  w={"15%"}
                  letterSpacing={"2px"}
                >
                  Country
                </Box>
                <Box
                  cursor={"default"}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    fontSize: "14px",
                    textAlign: "start",
                  }}
                  w={"13%"}
                  letterSpacing={"2px"}
                >
                  Latitude
                </Box>
                <Box
                  cursor={"default"}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    fontSize: "14px",
                    textAlign: "start",
                  }}
                  w={"13%"}
                  letterSpacing={"2px"}
                >
                  Longitude
                </Box>
                <Box
                  cursor={"default"}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    fontSize: "14px",
                    textAlign: "start",
                  }}
                  w={"25%"}
                  letterSpacing={"2px"}
                >
                  Browser
                </Box>
                <Box
                  cursor={"default"}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    fontSize: "14px",
                    textAlign: "start",
                  }}
                  w={"15%"}
                  letterSpacing={"2px"}
                >
                  OS
                </Box>
                <Box
                  cursor={"default"}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    fontSize: "14px",
                    textAlign: "start",
                  }}
                  w={"10%"}
                  letterSpacing={"2px"}
                >
                  Count
                </Box>
              </Box>

              {/* Data Rows */}
              {filteredClicks?.map((i, index) => (
                <Box
                  key={i._id}
                  h={"50px"}
                  display={"flex"}
                  borderRadius={"10px"}
                  gap={"20px"}
                  p={"0px 10px"}
                  sx={{
                    backgroundColor:
                      index % 2 !== 0
                        ? theme === "light"
                          ? "#fff"
                          : "rgba(255, 255, 255, 0.05)"
                        : theme === "light"
                        ? "#F8F8FA"
                        : "",
                  }}
                >
                  <Box
                    cursor={"default"}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "14px",
                      color: theme === "light" ? "#060C16" : "#fff",
                      textAlign: "start",
                    }}
                    w={"13%"}
                  >
                    {i.city}
                  </Box>
                  <Box
                    cursor={"default"}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "14px",
                      color: theme === "light" ? "#060C16" : "#fff",
                      textAlign: "start",
                    }}
                    w={"15%"}
                  >
                    {i.country}
                  </Box>
                  <Box
                    cursor={"default"}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "14px",
                      color: theme === "light" ? "#060C16" : "#fff",
                      textAlign: "start",
                    }}
                    w={"13%"}
                  >
                    {i.latitude}
                  </Box>
                  <Box
                    cursor={"default"}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "14px",
                      color: theme === "light" ? "#060C16" : "#fff",
                      textAlign: "start",
                    }}
                    w={"13%"}
                  >
                    {i.longitude}
                  </Box>
                  <Box
                    cursor={"default"}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "14px",
                      color: theme === "light" ? "#060C16" : "#fff",
                      textAlign: "start",
                    }}
                    w={"25%"}
                  >
                    {i.browser}
                  </Box>
                  <Box
                    cursor={"default"}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "14px",
                      color: theme === "light" ? "#060C16" : "#fff",
                      textAlign: "start",
                    }}
                    w={"15%"}
                  >
                    {i.os}
                  </Box>
                  <Box
                    cursor={"default"}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "14px",
                      color: theme === "light" ? "#060C16" : "#fff",
                      textAlign: "start",
                    }}
                    w={"10%"}
                  >
                    {i.count}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </ModalContent>
      </Modal>

      {/* Qr code modal  */}
      {/* <Modal
        isOpen={openQR}
        onClose={() => setOpenQR(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          sx={{
            p: "35px",
            borderRadius: "37px",
            background: " #fff",
            boxShadow: "0px 88px 40.1px 0px rgba(17, 20, 50, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Text
              sx={{
                fontSize: "24px",
                fontWeight: 500,
                color: theme === "light" ? "#060C16" : "#fff",
              }}
            >
              QR Code
            </Text>
            <IconButton
              onClick={() => setOpenQR(false)}
              isRound={true}
              size={"sm"}
              style={{
                background: "rgba(255, 255, 255, 0.08)",
                color: theme === "light" ? "#060C16" : "#fff",
              }}
              aria-label="Close"
              icon={<IoMdClose size={22} />}
            />
          </Box>
          <Box padding="10px 20px">
            <img
              style={{ width: "100%", margin: "auto" }}
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${shortLinkValue}`}
            />
          </Box>
        </ModalContent>
      </Modal> */}
      <Modal
        isOpen={openQR}
        onClose={() => setOpenQR(false)}
        isCentered
        motionPreset="scale"
        size="xs"
      >
        <ModalOverlay />
        <ModalContent
          sx={{
            p: "40px",
            borderRadius: "37px",
            background: "#fff",
            boxShadow: "0px 88px 40.1px 0px rgba(17, 20, 50, 0.25)",
            width: "377px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
              paddingBottom: "16px",
              borderBottom: "2px solid rgba(98, 49, 247, 0.10)",
            }}
          >
            <Text
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#060C16",
              }}
            >
              QR Code
            </Text>
            <IconButton
              onClick={() => setOpenQR(false)}
              isRound
              size="sm"
              style={{
                background: "rgba(255, 255, 255, 0.08)",
                color: "#060C16",
              }}
              aria-label="Close"
              icon={<IoMdClose size={22} />}
            />
          </Box>

          <Box
            padding="10px 20px"
            position="relative"
            display="flex"
            justifyContent="center"
          >
            {/* QR Code Image */}
            <img
              style={{ width: "100%", maxWidth: "241px" }}
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${shortLinkValue}`}
              alt="QR Code"
            />

            {/* Corner Lines */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "20px",
                height: "20px",
                borderTop: "3px solid #6231F7",
                borderLeft: "3px solid #6231F7",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "20px",
                height: "20px",
                borderTop: "3px solid #6231F7",
                borderRight: "3px solid #6231F7",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "20px",
                height: "20px",
                borderBottom: "3px solid #6231F7",
                borderLeft: "3px solid #6231F7",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "20px",
                height: "20px",
                borderBottom: "3px solid #6231F7",
                borderRight: "3px solid #6231F7",
              }}
            />
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
}
