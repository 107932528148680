import { Box, Button, Input, Switch } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import toast from "react-hot-toast";

export default function TableRows({ data, userDataArr, setData, index }) {
  const { selected, setSelected, editing, axios, apiLink, theme } =
    useContext(Context);
  const [check, setCheck] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const { firstName, lastName, email, password, domain, _id, isAdmin } = data;
  const [firstNameValue, setFirstNameValue] = useState(firstName);
  const [lastNameValue, setLastNameValue] = useState(lastName);
  const [emailValue, setEmailValue] = useState(email);
  const [passwordValue, setPasswordValue] = useState(password);
  const [domainValue] = useState(domain);
  const [count, setCount] = useState(1);
  const [isAdminValue, setIsAdminValue] = useState(isAdmin);
  const [totalCredits, setTotalCredits] = useState(0);
  const [usedCredits, setUsedCredits] = useState(0);

  useEffect(() => {
    if (count == 1) {
      return setCount((prev) => prev + 1);
    }
    let editedObject = {
      firstName: firstNameValue,
      lastName: lastNameValue,
      email: emailValue,
      password: passwordValue,
      domain: domainValue,
      isAdmin: isAdminValue,
      _id,
    };
    let temp = [...userDataArr];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]._id === _id) {
        temp[i] = editedObject;
        break;
      }
    }
    setData(temp);
  }, [
    firstNameValue,
    lastNameValue,
    emailValue,
    passwordValue,
    domainValue,
    isAdminValue,
  ]);

  useEffect(() => {
    axios
      .post(`${apiLink}shorten/licenceCheck/`, {
        email,
      })
      .then((res) => setTotalCredits(res.data.limit));
    axios.get(`${apiLink}shorten/AllData?userID=${_id}`).then((res) => {
      setUsedCredits(res.data.length);
    });
  }, []);

  function ExpressiveDateString(dateString) {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Convert to IST (UTC+5:30)
    const istOffset = 5 * 60 + 30; // IST offset in minutes
    const istDate = new Date(date.getTime() + istOffset * 60 * 1000);

    // Define the month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the day of the month and determine the suffix (st, nd, rd, th)
    const day = istDate.getUTCDate();
    const daySuffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";

    // Get the month name
    const month = months[istDate.getUTCMonth()];

    // Get the year
    const year = istDate.getUTCFullYear();

    // Get the hours and minutes, and convert to 12-hour format
    let hours = istDate.getUTCHours();
    const minutes = istDate.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM/PM

    // Format the minutes with leading zero if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Construct the formatted date string
    const formattedDate = `${day}${daySuffix} ${month} ${year}, ${hours}:${formattedMinutes} ${ampm}`;

    return formattedDate;
  }

  return (
    <Box
      display={"flex"}
      minW={"100%"}
      gap={"20px"}
      background={
        index % 2 === 0
          ? theme === "light"
            ? "#F6F6FF"
            : "rgba(255, 255, 255, 0.05)"
          : theme === "light"
          ? "#FFFFFF"
          : "rgba(0, 0, 0, 0.05)"
      }
      borderRadius={"10px"}
      _hover={{
        background: theme === "light" ? "#F1F1FF" : "rgba(255, 255, 255, 0.11)",
        border: theme === "light" ? "1px solid #C5B2FF" : "1px solid #717295",
      }}
      sx={{
        border:
          index % 2 === 0
            ? "1px solid rgba(255, 255, 255, 0.03)"
            : "1px solid transparent",
      }}
    >
      {/* <Box
        flexShrink={0}
        overflow={"hidden"}
        borderRadius={"none"}
        h={"40px"}
        type={"checkbox"}
        w={"6%"}
        border={"1px solid #dee2e6"}
        textAlign={"center"}
      >
        <Button
          display={"flex"}
          justifyContent={"center"}
          className="select"
          h={"40px"}
          w={"100%"}
          onClick={(e) => {
            if (editing) {
              return toast({
                title: `Cannot Select or Unselect while Editing`,
                status: "error",
                isClosable: true,
                position: "top",
              });
            }
            let value = !check;
            if (value) {
              setSelected((prev) => [
                ...prev,
                { _id, isReadOnly, setIsReadOnly, check, setCheck },
              ]);
            } else {
              setSelected(selected.filter((i) => i._id !== _id));
            }
            setCheck(value);
          }}
        >
          <input 
          
            onChange={(e) => console.log()}
            type={"checkbox"}
            checked={check}
          />
        </Button>
      </Box> */}
      <Box
        // flexShrink={0}
        overflow={"hidden"}
        borderRadius={"none"}
        h={"40px"}
        type={"checkbox"}
        w={"6%"}
        textAlign={"center"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <input
          onClick={(e) => {
            if (editing) {
              return toast.error("Cannot Select or Unselect while Editing");
            }

            let value = !check;
            if (value) {
              setSelected((prev) => [
                ...prev,
                { _id, isReadOnly, setIsReadOnly, check, setCheck },
              ]);
            } else {
              setSelected(selected.filter((i) => i._id !== _id));
            }
            setCheck(value);
          }}
          style={{
            width: "17px",
            height: "17px",
            borderRadius: "3px",
            cursor: "pointer",
          }}
          type="checkbox"
          checked={check}
        />
      </Box>

      <Input
        // flexShrink={0}
        w={"12%"}
        background={"none"}
        color={
          !isReadOnly
            ? theme === "light"
              ? "rgba(136, 139, 212, 0.85)"
              : "rgba(150, 152, 219, 0.60)"
            : theme === "light"
            ? "#44546F"
            : "#fff"
        }
        borderBottom={
          !isReadOnly
            ? theme === "light"
              ? "1px solid #6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none"
        }
        cursor={"default"}
        borderTop={"none"}
        borderLeft={"none"}
        borderRight={"none"}
        _focus={{
          border: !isReadOnly
            ? theme === "light"
              ? "#6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none",
          borderRadius: !isReadOnly
            ? theme === "light"
              ? "5px"
              : "5px"
            : theme === "light"
            ? "none"
            : "none",
        }}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        fontSize={"14px"}
        readOnly={isReadOnly}
        borderRadius={"none"}
        h={"40px"}
        onChange={(e) => setFirstNameValue(e.target.value)}
        value={firstNameValue}
      ></Input>

      <Input
        // flexShrink={0}
        w={"6%"}
        background={"none"}
        color={
          !isReadOnly
            ? theme === "light"
              ? "rgba(136, 139, 212, 0.85)"
              : "rgba(150, 152, 219, 0.60)"
            : theme === "light"
            ? "#44546F"
            : "#fff"
        }
        borderBottom={
          !isReadOnly
            ? theme === "light"
              ? "1px solid #6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none"
        }
        cursor={"default"}
        borderTop={"none"}
        borderLeft={"none"}
        borderRight={"none"}
        _focus={{
          border: !isReadOnly
            ? theme === "light"
              ? "#6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none",
          borderRadius: !isReadOnly
            ? theme === "light"
              ? "5px"
              : "5px"
            : theme === "light"
            ? "none"
            : "none",
        }}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        fontSize={"14px"}
        readOnly={isReadOnly}
        onChange={(e) => setLastNameValue(e.target.value)}
        borderRadius={"none"}
        h={"40px"}
        value={lastNameValue}
      ></Input>
      <Input
        // flexShrink={0}
        w={"20%"}
        background={"none"}
        color={
          !isReadOnly
            ? theme === "light"
              ? "rgba(136, 139, 212, 0.85)"
              : "rgba(150, 152, 219, 0.60)"
            : theme === "light"
            ? "#44546F"
            : "#fff"
        }
        borderBottom={
          !isReadOnly
            ? theme === "light"
              ? "1px solid #6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none"
        }
        cursor={"default"}
        borderTop={"none"}
        borderLeft={"none"}
        borderRight={"none"}
        _focus={{
          border: !isReadOnly
            ? theme === "light"
              ? "#6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none",
          borderRadius: !isReadOnly
            ? theme === "light"
              ? "5px"
              : "5px"
            : theme === "light"
            ? "none"
            : "none",
        }}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        fontSize={"14px"}
        readOnly={isReadOnly}
        onChange={(e) => {
          setEmailValue(e.target.value);
        }}
        borderRadius={"none"}
        h={"40px"}
        value={emailValue}
      ></Input>
      <Input
        // flexShrink={0}
        w={"10%"}
        background={"none"}
        cursor={"default"}
        border={"none"}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        color={theme === "light" ? "#44546F" : "#fff"}
        fontSize={"14px"}
        readOnly={true}
        onChange={(e) => setPasswordValue(e.target.value)}
        borderRadius={"none"}
        h={"40px"}
        value={passwordValue}
      >
        {}
      </Input>
      <Box
        w={"10%"}
        cursor={"default"}
        borderRadius={"none"}
        h={"40px"}
        border={"none"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"20px"}
        fontSize={"14px"}
        whiteSpace={"nowrap"}
        color={theme === "light" ? "#44546F" : "#fff"}
      >
        {ExpressiveDateString(data.time)}
      </Box>
      <Input
        // flexShrink={0}
        w={"10%"}
        background={"none"}
        color={
          !isReadOnly
            ? theme === "light"
              ? "rgba(136, 139, 212, 0.85)"
              : "rgba(150, 152, 219, 0.60)"
            : theme === "light"
            ? "#44546F"
            : "#fff"
        }
        borderBottom={
          !isReadOnly
            ? theme === "light"
              ? "1px solid #6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none"
        }
        cursor={"default"}
        borderTop={"none"}
        borderLeft={"none"}
        borderRight={"none"}
        _focus={{
          border: !isReadOnly
            ? theme === "light"
              ? "#6231F7"
              : "1px solid #fff"
            : theme === "light"
            ? "none"
            : "none",
          borderRadius: !isReadOnly
            ? theme === "light"
              ? "5px"
              : "5px"
            : theme === "light"
            ? "none"
            : "none",
        }}
        _placeholder={{ color: theme === "light" ? "#44546F" : "#fff" }}
        fontSize={"14px"}
        readOnly={isReadOnly}
        onChange={(e) => setPasswordValue(e.target.value)}
        borderRadius={"none"}
        h={"40px"}
        value={domainValue}
      ></Input>
      {/* <Button
        disabled={!isReadOnly}
        color={!isReadOnly ? "white" : "black"}
        background={!isReadOnly ? "#888888" : "none"}
        flexShrink={0}
        w={"8%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        onClick={(e) => {
          if (isReadOnly) return;
          setIsAdminValue((prev) => !prev);
        }}
      >
        <input value={isAdminValue} type="checkbox" checked={isAdminValue} />
      </Button> */}

      <Button
        disabled={!isReadOnly}
        sx={{
          //   color: !isReadOnly ? "white" : "black",
          //   background: !isReadOnly ? "#888888" : "none",
          background: "none",
          // flexShrink: 0,
          width: "8%",
          borderRadius: "none",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: !isReadOnly ? "pointer" : "not-allowed",
          _hover: {
            background: "none",
          },
        }}
        onClick={(e) => {
          if (isReadOnly) return;
          setIsAdminValue((prev) => !prev);
        }}
      >
        {" "}
        <Switch
          isChecked={isAdminValue}
          isDisabled={!isReadOnly}
          cursor={!isReadOnly ? "pointer" : "not-allowed"}
        />
      </Button>

      <Box
        // flexShrink={0}
        w={"11%"}
        cursor={"default"}
        onChange={(e) => e.target.value}
        borderRadius={"none"}
        h={"40px"}
        border={"none"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // gap={"20px"}
        fontSize={"14px"}
        color={theme === "light" ? "#44546F" : "#fff"}
      >
        {totalCredits}
      </Box>
      <Box
        // flexShrink={0}
        w={"11%"}
        cursor={"default"}
        onChange={(e) => e.target.value}
        borderRadius={"none"}
        h={"40px"}
        border={"none"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // gap={"20px"}
        fontSize={"14px"}
        color={theme === "light" ? "#44546F" : "#fff"}
      >
        {totalCredits - usedCredits}
      </Box>
    </Box>
  );
}
