export const faqs = [
  {
    id: 1,
    title: "How do I shorten a link?",
    description:
      "Yes! With our custom domain feature, you can personalize your links to match your brand’s identity.",
  },
  {
    id: 2,
    title: "Can I customize my links?",
    description:
      "Yes! With our custom domain feature, you can personalize your links to match your brand’s identity.",
  },
  {
    id: 3,
    title: "How do I track clicks?",
    description:
      "Yes! With our custom domain feature, you can personalize your links to match your brand’s identity.",
  },
  {
    id: 4,
    title: "Is there a free link limit?",
    description:
      "Yes! With our custom domain feature, you can personalize your links to match your brand’s identity.",
  },
  {
    id: 5,
    title: "Can I generate QR codes?",
    description:
      "Yes! With our custom domain feature, you can personalize your links to match your brand’s identity.",
  },
  {
    id: 6,
    title: "Can I save important links?",
    description:
      "Yes! With our custom domain feature, you can personalize your links to match your brand’s identity.",
  },
];

export const features = [
  {
    title: "fAVOURITE",
    subtitle: "YOUR LINKS",
    description:
      "Save and quickly access frequently used shortened links by marking them as favorites",
  },
  {
    title: "SEE CLICK",
    subtitle: "ANALYSIS",
    description:
      "Track detailed click analytics, including device type, OS, and geographic location",
  },

  {
    title: "GENERATE",
    subtitle: "QR CODE",
    description:
      "Create QR codes that direct users to your shortened URLs for easy mobile access.",
  },
  {
    title: "Add Custom",
    subtitle: "DOMAIN",
    description:
      "Allow users to set up personalized domains for their shortened links to match their branding.",
  },
  {
    title: "500 FREE",
    subtitle: "LINKS",
    description:
      "Enjoy the ability free of cost to create up to 500 shortened links at no cost and in simplified way.",
  },
];

export const users = [
  {
    name: "Paresh Singh",
    location: "Amritsar, Punjab",
    image:
      "https://t4.ftcdn.net/jpg/07/01/43/31/240_F_701433144_HwdtQjA41FBeRGPJzY5gLRdCQlwWiKIu.jpg",
    review: "Helpful Tool!",
    details:
      "This tool has been immensely helpful in managing my business links and data. I recommend it to all business owners.",
    rating: 5,
  },
  {
    name: "Sakshi Verma",
    location: "Mumbai, Maharashtra",
    image:
      "https://t3.ftcdn.net/jpg/06/70/00/42/240_F_670004228_4EFH6zZn8kjVhc5q7FyXuAHn3PtSwpwy.jpg",
    review: "Highly Recommended!",
    details:
      "The tool makes it easy to organize and monitor my links. Great for digital entrepreneurs.",
    rating: 4,
  },
  {
    name: "Rohit Sharma",
    location: "Delhi",
    image:
      "https://t4.ftcdn.net/jpg/07/43/03/95/240_F_743039592_dvDOWN8vdrdlnECDSlS34g66rjH7b9Ua.jpg",
    review: "Time Saver!",
    details:
      "Managing multiple links used to be a hassle. Now, it's super easy and efficient.",
    rating: 4,
  },
  {
    name: "Anita Nair",
    location: "Kochi, Kerala",
    image:
      "https://t4.ftcdn.net/jpg/06/70/00/41/240_F_670004168_VWpwmoceHQivgDkesVSTYM1dOyYFAVZE.jpg",
    review: "Incredibly Useful!",
    details:
      "I’ve been using it for a few months, and it has simplified my work life a lot.",
    rating: 5,
  },
  {
    name: "Kunal Mehta",
    location: "Ahmedabad, Gujarat",
    image:
      "https://t3.ftcdn.net/jpg/07/18/08/72/240_F_718087220_uPM1rhEhEdn3kCp6xhdOlkwQbREY3qzH.jpg",
    review: "Perfect for Startups!",
    details:
      "As a startup owner, this tool is exactly what I needed to streamline my operations.",
    rating: 5,
  },
  {
    name: "Tanya Roy",
    location: "Kolkata, West Bengal",
    image:
      "https://t4.ftcdn.net/jpg/06/61/34/89/240_F_661348968_sTOJebIpES5wwAwI3f7P8IJLTz8zN7zR.jpg",
    review: "Fantastic Tool!",
    details:
      "This tool saves me hours every week. The analytics feature is especially helpful.",
    rating: 4,
  },
  {
    name: "Rajiv Malhotra",
    location: "Chandigarh",
    image:
      "https://t4.ftcdn.net/jpg/07/26/33/03/240_F_726330398_iHY6uIWDTu2H3AD4gB0xW0q11x5xkcPi.jpg",
    review: "Highly Efficient!",
    details:
      "I love how easy it is to track my links. The performance analytics are a game-changer.",
    rating: 5,
  },
  {
    name: "Pooja Iyer",
    location: "Bengaluru, Karnataka",
    image:
      "https://t4.ftcdn.net/jpg/06/58/55/39/240_F_658553987_IL97dohcx8hUZcgRzOxiKrXnShzDo1nT.jpg",
    review: "User-Friendly Design!",
    details:
      "The interface is very intuitive. I recommend it to anyone managing digital assets.",
    rating: 5,
  },
  {
    name: "Manoj Reddy",
    location: "Hyderabad, Telangana",
    image:
      "https://t4.ftcdn.net/jpg/06/94/07/25/240_F_694072556_hGTmQipM6cdKyUPQVQfslLOJXv1dCPVh.jpg",
    review: "Reliable Tool!",
    details:
      "The platform is stable and easy to use. It’s now an essential part of my workflow.",
    rating: 4,
  },
  {
    name: "Aarti Deshmukh",
    location: "Pune, Maharashtra",
    image:
      "https://t4.ftcdn.net/jpg/09/69/34/27/240_F_969342778_BCPcWUTyPG7RsXUUPaJ2jDNiiCzrtyOd.jpg",
    review: "Very Convenient!",
    details:
      "This tool has made managing my personal and business links so simple. A must-have!",
    rating: 5,
  },
];
