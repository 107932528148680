import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import ContextProvider from "./context";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContextProvider>
    <BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} duration={6000} />
      <App />
    </BrowserRouter>
  </ContextProvider>
);
