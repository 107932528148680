import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./FaqSection.css";
import { faqs } from "../../../Constant";

const FaqSection = () => {
  const headingControls = useAnimation();
  const faqControls = useAnimation();

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      headingControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.1, duration: 0.5 },
      });

      faqs.forEach((_, index) => {
        faqControls.start((i) => ({
          opacity: 1,
          y: 0,
          transition: { delay: 0.5 + i * 0.5, duration: 0.5 },
        }));
      });
    } else {
      headingControls.start({ opacity: 0, y: 20 });
      faqControls.start((i) => ({
        opacity: 0,
        y: 20,
      }));
    }
  }, [inView, headingControls, faqControls]);

  return (
    <Box id="faqs" width={"100%"} ref={ref}>
      <Box
        className="faq-container"
        sx={{
          width: "100%",
          margin: "auto",
          p: "5% 20px",
        }}
      >
        {/* Heading */}
        <motion.div
          style={{ textAlign: "center" }}
          initial={{ opacity: 0, y: 20 }}
          animate={headingControls}
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.4 },
          }}
        >
          <Typography
            className="faq-heading"
            sx={{
              fontSize: "36px",
              display: "inline-block",
              letterSpacing: "32.4px",
            }}
          >
            ANY QUESTIONS?
          </Typography>
        </motion.div>

        {/* Render fawqs */}
        <Box
          sx={{
            width: "100%",
            mt: "50px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {faqs?.map((item, i) => (
            <Box
              as={motion.div}
              custom={i}
              initial={{ opacity: 0, y: 20 }}
              animate={faqControls}
              key={i}
              className="faq-box"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "435px",
                minHeight: "204px",
                padding: "38px 50px",
                borderRadius: "40px",
                transition: "background-color 0.4s, box-shadow 0.4s",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Box
                  className="index"
                  sx={{
                    background: "rgba(6, 12, 22, 0.07)",
                    fontWeight: 550,
                    fontSize: "16px",
                    color: "#060C16",
                  }}
                >
                  {i + 1}
                </Box>

                <Typography
                  className="title-text"
                  sx={{ fontSize: "20px", color: "#9594A2", fontWeight: 550 }}
                >
                  {item.title}
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="description-text"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 550,
                    color: "#fff",
                    mt: 1,
                  }}
                >
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FaqSection;
