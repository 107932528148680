import { Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { FaSort } from "react-icons/fa";

const TableHeader = ({ sortAsc, sortDes, sortAscDate, sortDesDate }) => {
  const {
    selected,
    setSelected,
    data,
    setData,
    unselect,
    setPaginativeData,
    theme,
  } = useContext(Context);
  var arr;
  let [selectAll, setSelectAll] = useState(false);

  let [sortLongURL, setSortLongURL] = useState(false);
  let [sortDate, setSortDate] = useState(false);
  let [sortAlias, setSortAlias] = useState(false);
  let [sortShortURL, setSortShortURL] = useState(false);
  let [sortRemarks, setSortRemarks] = useState(false);
  let [sortClicks, setSortClicks] = useState(false);
  useEffect(() => {
    arr = document.querySelectorAll(".select");
  });
  return (
    <Box
      display={"flex"}
      minW={"100%"}
      position={"relative"}
      overflowY={"scroll"}
      sx={{
        borderBottom:
          theme === "light" ? "rgba(255, 255, 255, 0.06)" : "1px solid #fff",
        paddingBottom: "10px",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        cursor={"pointer"}
        borderRadius={"none"}
        color={theme === "light" ? "#060C16" : "#fff"}
        h={"40px"}
        w={"6%"}
        fontSize={"14px"}
        fontWeight={"500"}
        onClick={() => {
          let value = !selectAll;
          if (selected.length == 0) {
            for (let i of arr) {
              i.click();
            }
          } else {
            for (let i of unselect) {
              i.setCheck(false);
              i.setIsReadOnly(true);
            }
            setSelected([]);
          }
          setSelectAll(value);
        }}
      >
        Checkbox
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        w={"13%"}
        cursor={"pointer"}
        borderRadius={"none"}
        color={theme === "light" ? "#060C16" : "#fff"}
        h={"40px"}
        fontSize={"14px"}
        fontWeight={"500"}
        onClick={() => {
          let value = !sortDate;
          if (value) {
            sortAscDate("dateCreated");
          } else sortDesDate("dateCreated");
          setSortDate(value);
        }}
      >
        Date Created
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        w={"18%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        fontSize={"14px"}
        fontWeight={"500"}
        color={theme === "light" ? "#060C16" : "#fff"}
        onClick={() => {
          let value = !sortLongURL;
          if (value) {
            sortAsc("longURL");
          } else sortDes("longURL");
          setSortLongURL(value);
        }}
      >
        Long URL
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        w={"10%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
        // paddingLeft={"16px"}
        onClick={() => {
          let value = !sortAlias;
          if (value) {
            sortAsc("alias");
          } else sortDes("alias");
          setSortAlias(value);
        }}
      >
        Alias
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        w={"20%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
        onClick={() => {
          let value = !sortShortURL;
          if (value) {
            sortAsc("shortURL");
          } else sortDes("shortURL");
          setSortShortURL(value);
        }}
      >
        Short URL
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        w={"10%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
        // paddingRight={"15px"}
        // border="1px solid #fff"
        onClick={() => {
          let value = !sortRemarks;
          if (value) {
            sortAsc("remarks");
          } else sortDes("remarks");
          setSortRemarks(value);
        }}
      >
        Remarks
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        w={"8%"}
        cursor={"pointer"}
        borderRadius={"none"}
        color={theme === "light" ? "#060C16" : "#fff"}
        h={"40px"}
        fontSize={"14px"}
        fontWeight={"500"}
        onClick={() => {
          let value = !sortClicks;
          if (value) {
            const temp = [...data];
            const sortedData = [...temp].sort((a, b) => {
              return a.clicks - b.clicks;
            });
            setPaginativeData(
              sortedData.sort(
                (a, b) => Number(b.favourite) - Number(a.favourite)
              )
            );
          } else {
            const temp = [...data];
            const sortedData = [...temp].sort((a, b) => {
              return b.clicks - a.clicks;
            });
            setPaginativeData(
              sortedData.sort(
                (a, b) => Number(b.favourite) - Number(a.favourite)
              )
            );
          }
          setSortClicks(value);
        }}
      >
        Clicks
        <FaSort
          size={14}
          color={theme === "light" ? "#060C16" : "#fff"}
          style={{ marginLeft: "5px" }}
        />
      </Box>

      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        w={"15%"}
        cursor={"pointer"}
        borderRadius={"none"}
        h={"40px"}
        color={theme === "light" ? "#060C16" : "#fff"}
        fontSize={"14px"}
        fontWeight={"500"}
      >
        Other Options
      </Box>
    </Box>
  );
};

export default TableHeader;
