import { Box, IconButton, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Note } from "@mui/icons-material";
import NoteModal from "./NoteModal";

const InteractiveDemo = () => {
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const headingControls = useAnimation();
  const imgControls = useAnimation();

  const handleCancel = () => setOpenNoteModal(!openNoteModal);

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      headingControls.start({
        opacity: 1,
        x: 0,
        transition: { delay: 0.1, duration: 0.5 },
      });

      imgControls.start({
        opacity: 1,
        x: 0,
        transition: { delay: 0.5, duration: 0.7 },
      });
    } else {
      headingControls.start({ opacity: 0, x: -400 });
      imgControls.start({ opacity: 0, x: 400 });
    }
  }, [inView, headingControls, imgControls]);

  return (
    <Box
      id="demo"
      ref={ref}
      sx={{
        minHeight: "100vh",
        width: "100%",
        background: "#060C16",
        p: {
          xs: "50px 0px 0px 0px",
          sm: "50px 0px 50px 0px",
          lg: "50px 0px 50px 0px",
        },
      }}
    >
      <Box
        sx={{
          p: { xs: "0px 20px", sm: "0px 50px" },
        }}
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={headingControls}
      >
        <Box>
          <Typography
            sx={{
              color: "rgba(255, 255, 255, 0.30)",
              fontStyle: "normal",
              fontSize: { xs: "50px", sm: "100px", lg: "120px" },
              fontWeight: 700,
              lineHeight: { xs: "50px", sm: "100px", lg: "139px" },
              textTransform: "uppercase",
              textAlign: { xs: "center", md: "center", lg: "left" },
            }}
          >
            Try It by
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "20px", sm: "25px", lg: "50px" },
            flexDirection: { xs: "column", sm: "column", lg: "row" },
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.30)",
                fontStyle: "normal",
                fontSize: { xs: "50px", sm: "100px", lg: "120px" },
                fontWeight: 700,
                lineHeight: { xs: "50px", sm: "100px", lg: "139px" },
                textTransform: "uppercase",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Yourself
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "10px", md: "16px" },
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            <Button
              onClick={() => setOpenNoteModal(true)}
              variant="contained"
              sx={{
                color: "#060C16",
                fontWeight: 500,
                borderRadius: "100px",
                background: "#fff",
                fontSize: { xs: "14px", md: "16px" },
                padding: { xs: "0px 25px", md: "0px 40px" },
                height: { xs: "50px", md: "62px" },
                "&:hover": {
                  color: "#060C16",
                  background: "#AEA9BA",
                },
              }}
            >
              Interactive Demo
            </Button>

            {/* <IconButton
              onClick={() => setOpenNoteModal(true)}
              sx={{
                color: "#000000",
                background: "#fff",
                padding: { xs: "10px", md: "16px" },
                "&:hover": {
                  color: "#060C16",
                  background: "#AEA9BA",
                },
              }}
            >
              <ChevronRightIcon sx={{ width: "26px", height: "26px" }} />
            </IconButton> */}
          </Box>
        </Box>
      </Box>

      {/* Animated Image section */}
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={imgControls}
        sx={{
          width: "100%",
          mt: "25px",
          display: "flex",
          justifyContent: "center",
          "& img": {
            width: "100%",
            maxWidth: { xs: "90%", md: "90%" },
            transition: "transform 0.5s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          },
        }}
      >
        <img src="./demoImg4.svg" alt="Image" />
      </Box>

      {/* Note modal */}
      <NoteModal handleCancel={handleCancel} openNoteModal={openNoteModal} />
    </Box>
  );
};

export default InteractiveDemo;
