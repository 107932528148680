import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  InputAdornment,
  Link,
  Container,
  Slider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { NavLink, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Context } from "../../../context";
import NavigationBar from "../../Nav/NavigationBar";
import toast from "react-hot-toast";
import { MdOutlineMail } from "react-icons/md";
import { CiLock } from "react-icons/ci";

const LoginForm = () => {
  const {
    axios,
    userDetails,
    setUserDetails,
    setDomainValue,
    apiLink,
    token,
    setToken,
    theme,
  } = useContext(Context);
  const [showPassword, setShowPassword] = useState(false);
  const [progress, setProgress] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  // Load saved email and password if "Remember Me" is checked
  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (savedRememberMe) {
      setEmail(savedEmail || "");
      setPassword(savedPassword || "");
      setRememberMe(true);
      setProgress(savedPassword ? 100 : savedEmail ? 50 : 0);
    }
  }, []);

  // Update the progress based on email and password validity
  useEffect(() => {
    let progressValue = 0;

    if (isValidEmail(email)) {
      progressValue = 50;
    }

    if (email && password && password.length >= 7) {
      progressValue = 100;
    }

    setProgress(progressValue);
  }, [email, password]);

  const handleRememberMeChange = (e) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);

    if (isChecked) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
      localStorage.setItem("rememberMe", "true");
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberMe");
    }
  };

  // Handle function
  const handleLogin = async () => {
    setShowError("");
    const emailError = !isValidEmail();
    const passwordError = !password;

    if (emailError) {
      return toast.error("Please enter a valid email.");
    }

    // if (password && password.length < 7) {
    //   return toast.error("Password must be at least 7 characters.");
    // }

    if (emailError || passwordError) {
      setShowError({ email: emailError, password: passwordError });
      return;
    }

    try {
      let { data: res } = await axios.post(`${apiLink}api/signin`, {
        email,
        password,
      });

      if (res.error) {
        setShowError(res.error);
        return toast.error(res.error);
      }

      setToken(res.token);
      Cookies.set("token", JSON.stringify(res.token));

      let userDatails = {
        email,
        _id: res.body._id,
      };

      setDomainValue(res.body.domain);
      setUserDetails(res.body);
      Cookies.set("user", JSON.stringify(userDatails));
      Cookies.set("admin", JSON.stringify({}));

      if (rememberMe) {
        localStorage.setItem("email", email);
      }
    } catch (error) {
      setShowError("Login failed. Please try again.");
    }
  };

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  if (userDetails._id) return <Navigate to={"/client/links"} />;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          theme === "light"
            ? `url("/lightBg.svg") no-repeat center center`
            : `url("/loginBg.svg") no-repeat center center`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        padding: 2,
      }}
    >
      {/* Top Navigation Bar */}
      <NavigationBar />

      {/* Progress bar with icon */}
      <Box
        sx={{
          width: "90%",
          maxWidth: 550,
          textAlign: "center",
          marginBottom: { xs: 5, sm: "80px", lg: "80px" },
          mt: "120px",
          // mt: { sm: "150px", lg: "120px" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {progress == 100 ? (
            <Box
              sx={{
                position: "absolute",
                left: `${95}%`,
                top: { xs: "-50px", sm: "-75px", lg: "-100px" },
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100px", sm: "150px", lg: "180px" },
                  height: { xs: "50px", sm: "75px", lg: "90px" },
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={
                    theme === "light" ? "/happyLight.svg" : "/happyEmoji.svg"
                  }
                  alt="Icon"
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                left: `${progress}%`,
                top: "-85px",
                top: { xs: "-45px", sm: "-75px", lg: "-85px" },
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "70px", sm: "95px", lg: "111px" },
                  height: { xs: "50px", sm: "75px", lg: "94px" },
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={
                    theme === "light" ? "/lightEmojiIcon.svg" : "/emojiIcon.svg"
                  }
                  alt="Icon"
                />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              position: "absolute",
              left: `${progress}%`,
              top: "35px",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: theme === "light" ? "#080808" : "#fff",
                textTransform: "uppercase",
                fontWeight: 550,
              }}
            >
              {progress}%
            </Typography>
          </Box>

          <Slider
            value={progress}
            sx={{
              width: "100%",
              "& .MuiSlider-thumb": {
                backgroundColor: "#2400FF",
                width: 20,
                height: 20,
              },
              "& .MuiSlider-track": {
                border: "none",
                backgroundColor: theme === "light" ? "#9D9DF0" : "#fff",
                height: 6,
              },
              "& .MuiSlider-rail": {
                backgroundColor:
                  theme === "light" ? "rgba(28, 25, 47, 0.08)" : "#1C192F",
                height: 6,
                transition: "all 0.5s ease",
              },
              "& .MuiSlider-thumb:hover, & .MuiSlider-thumb.Mui-focusVisible": {
                boxShadow: "0px 0px 0px 8px rgba(255, 64, 129, 0.16)",
              },
            }}
          />
        </Box>
      </Box>

      {/* Login Form */}
      <Container
        sx={{
          width: { xs: "100%", sm: "500px", lg: "500px" },
          mb: 5,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={showError.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdOutlineMail
                  size={24}
                  color={theme === "light" ? "#2D2C56" : "#fff"}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: !email
              ? theme === "light"
                ? "rgba(156, 159, 205, 0.13)"
                : "rgba(255, 255, 255, 0.06)"
              : "transparent",
            borderRadius: "30px",
            marginBottom: 3,
            input: {
              color: theme === "light" ? "#2D2C56" : "#fff",
              "&::placeholder": {
                color: theme === "light" ? "#2D2C56" : "#fff",
                opacity: 1,
              },
            },
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: email
                  ? theme === "light"
                    ? "1px solid #2400FF"
                    : "1px solid #fff"
                  : "transparent",
                borderRadius: "30px",
              },
              "&:hover fieldset": {
                border:
                  theme === "light" ? "1px solid #2400FF" : "1px solid #fff",
                // borderColor: theme === "light" ? "#2400FF" : "#fff",
                borderRadius: "30px",
              },
              "&.Mui-focused fieldset": {
                border:
                  theme === "light" ? "2px solid #2400FF" : "2px solid #fff",
                // borderColor: theme === "light" ? "#2400FF" : "#fff",
                borderRadius: "30px",
              },
            },
          }}
          required
        />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={showError.password}
          type={showPassword ? "text" : "password"}
          onKeyPress={handleKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CiLock
                  size={24}
                  color={theme === "light" ? "#2D2C56" : "#fff"}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePassword}
                  sx={{ color: "#fff" }}
                >
                  {showPassword ? (
                    <Visibility
                      sx={{ color: theme === "light" ? "#2D2C56" : "#fff" }}
                    />
                  ) : (
                    <VisibilityOff
                      sx={{ color: theme === "light" ? "#2D2C56" : "#fff" }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor:
              password.length === 0
                ? theme === "light"
                  ? "rgba(156, 159, 205, 0.13)"
                  : "rgba(255, 255, 255, 0.06)"
                : "transparent",
            borderRadius: "30px",
            marginBottom: 3,
            input: {
              color: theme === "light" ? "#2D2C56" : "#fff",
              "&::placeholder": {
                color: theme === "light" ? "#2D2C56" : "#fff",
                opacity: 1,
              },
            },
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 30px rgba(255, 255, 255, 0.15) inset",
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border:
                  password.length !== 0
                    ? theme === "light"
                      ? "1px solid #2400FF"
                      : "1px solid #fff"
                    : "transparent",
                borderRadius: "30px",
              },
              "&:hover fieldset": {
                border:
                  theme === "light" ? "1px solid #2400FF" : "1px solid #fff",
                // borderColor: theme === "light" ? "#2400FF" : "#fff",
                borderRadius: "30px",
              },
              "&.Mui-focused fieldset": {
                border:
                  theme === "light" ? "2px solid #2400FF" : "2px solid #fff",
                // borderColor: theme === "light" ? "#2400FF" : "#fff",
                borderRadius: "30px",
              },
            },
          }}
          required
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 24,
                    borderRadius: "6px",
                    color: theme === "light" ? "#214AFF" : "#fff",
                  },
                  "&.Mui-checked": {
                    borderRadius: "6px",
                  },
                }}
                checked={rememberMe}
                onClick={handleRememberMeChange}
              />
            }
            label={
              <Typography
                sx={{ color: theme === "light" ? "#060C16" : "#fff" }}
              >
                Remember Me
              </Typography>
            }
          />
          <Link
            component={NavLink}
            to="/client/forgotPassword"
            underline="none"
            color={theme === "light" ? "#214AFF" : "#fff"}
            sx={{
              fontWeight: 500,
              transition: "all 0.3s ease",
              "&:hover": { color: "#5733F7", textDecoration: "underline" },
            }}
          >
            Forgot Password?
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: { xs: "10px", md: "16px" },
            mt: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "10px", md: "16px" },
            }}
          >
            <Button
              variant="contained"
              disabled={!email || !password || !password}
              onClick={handleLogin}
              sx={{
                backgroundColor: theme === "light" ? "#2100EB" : "#fff",
                color: theme === "light" ? "#fff" : "#000000",
                fontWeight: 500,
                borderRadius: "100px",
                fontSize: { xs: "14px", md: "16px" },
                padding: { xs: "0px 25px", sm: "0px 50px", lg: "0px 50px" },
                height: { xs: "50px", md: "55px" },
                "&:hover": {
                  color: theme === "light" ? "#fff" : "#060C16",
                  background: theme === "light" ? "#1A00B8" : "#AEA9BA",
                },
                "&.Mui-disabled": {
                  backgroundColor: theme === "light" ? "#DEDDEB" : "#413D4F",
                  color: theme === "light" ? "#848484" : "#060C16",
                },
              }}
            >
              Login now
            </Button>
            {/* <IconButton
              onClick={handleLogin}
              disabled={!email || !password || !password}
              sx={{
                color: "#000000",
                background: "#fff",
                padding: { xs: "10px", md: "14px" },
                "&:hover": {
                  color: "#060C16",
                  background: "#AEA9BA",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#413D4F",
                  color: "#060C16",
                },
              }}
            >
              <ChevronRightIcon sx={{ width: "26px", height: "26px" }} />
            </IconButton> */}
          </Box>
          <Box>
            <Link
              component={NavLink}
              to="/client/register"
              sx={{
                color: "#5733F7",
                fontSize: { xs: "14px", sm: "16px", lg: "16px" },
              }}
            >
              New here? Sign up!
            </Link>
          </Box>
        </Box>
        {/* <Typography
          sx={{
            fontSize: "16px",
            color: "#fff",
            p: "5px 0px  5px 20%",
          }}
        >
          or
        </Typography>
        <Button
          disabled
          variant="contained"
          startIcon={<img src="/googleIcon.svg" alt="Google" />}
          sx={{
            color: "#000000",
            fontWeight: 500,
            borderRadius: "100px",
            background: "#fff",
            fontSize: { xs: "14px", md: "14px" },
            padding: { xs: "0px 25px", sm: "0px 25px", lg: "0px 25px" },
            height: { xs: "50px", md: "55px" },
            "&:hover": {
              color: "#060C16",
              background: "#AEA9BA",
            },
            "&.Mui-disabled": {
              backgroundColor: "#413D4F",
              color: "#060C16",
            },
          }}
        >
          Google Sign In
        </Button> */}
      </Container>
    </Box>
  );
};

export default LoginForm;
